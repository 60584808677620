import React, { useState, useEffect } from 'react'
import Box from '@mui/material/Box';
import Button from '@material-ui/core/Button';
import { useHistory } from "react-router-dom"
import Text from '../other/Text'
import { Typography } from '@material-ui/core';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';


function PostsSection({
  posts,
}) {
  
  const history = useHistory();

  return (
    <Box
      style={{
        display: "flex",
        justifyContent: "center"
      }}
      marginBottom={6}
    >
      <Box 
        gap={6}
        width={"800px"}
        style={{
          display: "flex",
          flexDirection: "column"
        }}
      >
        {
          posts.map((post, index) => {
            return (
              <Card key={index}>
                <CardMedia
                  component="img"
                  alt="featured image"
                  height="140"
                  image={post.featured_image_url}
                  style={{
                    cursor: "pointer"
                  }}
                  onClick={() => { history.push('/blog/' + post.slug) }}
                />
                <CardContent>
                  <Typography 
                    gutterBottom 
                    variant="h5" 
                    component="div"
                    className='cardLink'
                    onClick={() => { history.push('/blog/' + post.slug) }}
                  >
                    {post.title}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    {post.snippet}
                  </Typography>
                </CardContent>
                <CardActions>
                  <Button
                    size="small"
                    onClick={() => { history.push('/blog/' + post.slug) }}
                  >
                    View post
                    </Button>
                </CardActions>
              </Card>
            )
          })
        }
      </Box>
    </Box>
  )
}

export default PostsSection