import React from 'react'
import ChevronRight from '@material-ui/icons/ChevronRight';
import Text from '../other/Text'
import Icon from '@mdi/react'
import { mdiCircle } from '@mdi/js'
import colors from '../../config/colors';


function MenuItem ({ 
  title, 
  IconComponent, 
  onClick,
  noChevron,
  iconWrapperClassName,
  withDot
}) {
  return (
      <div className={"menuItemContainer"} onClick={onClick}>
        <div className={iconWrapperClassName}>
          {IconComponent}
        </div>
        {!withDot && (
          <div className={"menuDetailsContainer"}>
            <Text>{title}</Text>
          </div>
        )}
        {withDot && (
          <div className={"menuDetailsContainer"} style={{flexDirection: 'row', gap: 5}}>
            <Text>{title}</Text>
            <Icon path={mdiCircle} size={0.5} color={colors.primary} />
          </div>
        )}

        {!noChevron && (
          <ChevronRight fontSize="large" className={"chevronRight"} />
        )}
      </div>
  )
}

export default MenuItem
