import React, { useEffect, useState } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Stack from '@mui/material/Stack';
import {useReviewSlider} from "./useReviewSlider"
import {staticReviews} from "./StaticReviews"
import { Box } from '@material-ui/core';

const ReviewSlider = () => {
  const [reviews, setReviews] = useState([]);
  const {fetchReviews} = useReviewSlider()

  const fetchMyAPI = async () => {
    // const fetchedReviews = await fetchReviews()
    const fetchedReviews = staticReviews
    setReviews(fetchedReviews)
  };

  useEffect(() => {
    fetchMyAPI()
  }, []);

  const settings = {
    dots: true,
    infinite: false,
    className: "reviewSliderInnerDiv"
  };

  return (
    <Slider {...settings}>
      {reviews.map((review, index) => (
        <Box key={index}>
          <p style={{marginTop: "10px"}}>
            {review.text}
          </p>
          <Stack direction={"row"} alignItems={"center"} gap={"20px"}>
            <p>
              To {review.receiver.isHost ?  "host" : "traveller"}  <a href={review.receiver.link} target="_blank">{review.receiver.title}</a> from {review.sender.isHost ?  "host" : "traveller"} <a href={review.sender.link} target="_blank">{review.sender.title}</a>
            </p>
          </Stack>
        </Box>
      ))}
    </Slider>
);
};

export default ReviewSlider;
