import { create } from 'apisauce'
import sources from '../config/sources'

export default class ApiClient {
  constructor(...args) {
    this.client = create({ 
      baseURL: sources.baseURL
    })
    this.authToken = args[args.length - 1]

    this.client.addAsyncRequestTransform(async (request) => {
      request.headers.Authorization = 'Bearer ' + this.authToken
    })
  }

  setAdminPasswordHeader(adminPassword) {
    this.client.addAsyncRequestTransform(async (request) => {
      request.headers['admin-password'] = adminPassword
    })
  }

  get = function (url) {
    return this.client.get(url)
  }

  post = function (url, data) {
    if (data) {
      return this.client.post(url, JSON.stringify(data))
    } else {
      return this.client.post(url)
    }
  }

  patch = function (url, data) {
    if (data) {
      return this.client.patch(url, JSON.stringify(data))
    } else {
      return this.client.patch(url)
    }
  }
  

  upload = function (url, data, onUploadProgress, timeout = 60000) {
    const authToken = this.authToken
    const controller = new AbortController();
    const signal = controller.signal;
    const headers = new Headers();
    headers.append("Authorization", `Bearer ${authToken}`);
  
    const config = {
      method: 'POST',
      headers: headers,
      body: data,
      signal: signal
    };
  
    const timeoutId = setTimeout(() => {
      controller.abort();
    }, timeout);
  
    return fetch(`${sources.baseURL}${url}`, config)
      .then(response => {
        clearTimeout(timeoutId);
        if (!response.ok) {
          return response.json().then(body => {
            if (body?.hippoError?.publicType === 'MaximumImagesError') {
              return {
                ok: false,
                publicType: body.hippoError.publicType
              }
            } else {
              return {
                ok: false,
                publicType: "UploadImageError"
              }
            }
          });
        }
        return response.json();
      })
      .then(json => {
        return json;
      })
      .catch(error => {
        if (error.name === 'AbortError') {
          throw new Error('Request timed out');
        } else {
          throw error;
        }
      });
  }
  

  put = function (url, data) {
    if (data) {
      return this.client.put(url, JSON.stringify(data))
    } else {
      return this.client.put(url)
    }
  }

  delete = function (url) {
    return this.client.delete(url)
  }
}