import React, { useState, useEffect } from 'react'
import useApi from '../hooks/useApi'
import userApi from '../api/userApi'
import { Util } from '../utils/util';
import { useHistory } from "react-router-dom"
import FriendItem from '../components/lists/FavouriteItem';
import markerApi from "../api/markerApi";

function FriendsScreen () {
  const history = useHistory();
  const getRelationsApi = useApi(userApi.getRelations)
  const getHostMarkerDetailsAnyApprovalApi = useApi(markerApi.getHostMarkerDetailsAnyApproval)
  const [dataLoaded, setDataLoaded] = useState(false)
  const [followsUser, setFollowsUser] = useState(false)
  const [userFollows, setUserFollows] = useState(false)
  const [friends, setFriends] = useState(false)
  const [error, setError] = useState(false)

  useEffect(() => {
    async function fetchMyAPI() {
      const fetch = await getRelationsApi.request()
      if (!fetch) { return }

      const fetchedData = fetch.data
      if (fetchedData.hippoError) { return setError(fetchedData.hippoError.publicMessage) }
      setFollowsUser(fetchedData.followsUser.filter(relation => relation.is_friends === 0))
      setFriends(fetchedData.userFollows.filter(relation => relation.is_friends === 1))
      setUserFollows(fetchedData.userFollows.filter(relation => relation.is_friends === 0))
      setDataLoaded(true)
    }
    fetchMyAPI()
  }, [])

  const handleRelationClick = async (userData) => {
    const nextPage = await Util.getDetailsRoute(userData, getHostMarkerDetailsAnyApprovalApi)
    if (!nextPage) { return }
    
    history.push(nextPage)
  }

  return (
    <div className="col-10 col-lg-6 mx-auto mt-4 screen">
      {error && ( <p>{error}</p> )}
      {dataLoaded && followsUser.length === 0 && userFollows.length === 0 && friends.length === 0 && ( <p>No friend requests here!</p> )}
      {dataLoaded && userFollows.length !== 0 && (
        <>
          <div className="row mb-4">
            <div className="contentHeader">
              <h1>Sent friend requests</h1>
            </div>
          </div>
          <div className="row mb-4">
            {
              userFollows.map(function (relationItem, index) {
                return (
                <FriendItem
                  key={index}
                  userData={relationItem.userFollowsOwner}
                  title={Util.generateTitle(relationItem.userFollowsOwner)}
                  imageSrc={ Util.getFirstImageSource(relationItem.userFollowsOwner.image) }
                  onClick={handleRelationClick}
                />)
              })
            }
          </div>
        </>
      )}
      {dataLoaded && followsUser.length !== 0 && (
        <>
          <div className="row mb-4">
            <div className="contentHeader">
              <h1>Received friend requests</h1>
            </div>
          </div>
          <div className="row mb-4">
            {
              followsUser.map(function (relationItem, index) {
                return (
                <FriendItem
                  key={index}
                  userData={relationItem.followsUserOwner}
                  title={Util.generateTitle(relationItem.followsUserOwner)}
                  imageSrc={ Util.getFirstImageSource(relationItem.followsUserOwner.image) }
                  onClick={handleRelationClick}
                />)
              })
            }
          </div>
        </>
      )}
      {dataLoaded && friends.length !== 0 && (
        <>
          <div className="row mb-4">
            <div className="contentHeader">
              <h1>Friends</h1>
            </div>
          </div>
          <div className="row mb-4">
            {
              friends.map(function (relationItem, index) {
                return (
                <FriendItem
                  key={index}
                  userData={relationItem.userFollowsOwner}
                  title={Util.generateTitle(relationItem.userFollowsOwner)}
                  imageSrc={ Util.getFirstImageSource(relationItem.userFollowsOwner.image) }
                  onClick={handleRelationClick}
                />)
              })
            }
          </div>
        </>
      )}
    </div>
  )
}

export default FriendsScreen
