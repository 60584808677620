import React, {useState, useEffect} from 'react';
import Icon from '@mdi/react'
import { mdiFrequentlyAskedQuestions, mdiAccountCancel, mdiShieldCheck, mdiInformation, mdiText, mdiAccountDetails, mdiBell} from '@mdi/js'
import colors from '../config/colors'
import MenuItem from '../components/lists/MenuItem';
import HTMLDialog from '../components/dialogs/HTMLDialog';
import useApi from '../hooks/useApi'
import userApi from '../api/userApi'
import { useAuth0 } from "@auth0/auth0-react";
import { Util } from '../utils/util';
import Spinner from '../components/other/Spinner';
import routes from '../navigation/routes';
import { useHistory } from "react-router-dom"

function MoreScreen() {
  const history = useHistory(); 
  const [error, setError] = useState(false)
  const [safetyDialogOpen, setSafetyDialogOpen] = useState(false)
  const [termsAndConditionsDialogOpen, setTermsAndConditionsDialogOpen] = useState(false)
  const [privacyPolicyDialogOpen, setPrivacyPolicyDialogOpen] = useState(false)
  const [licensesDialogOpen, setLicensesDialogOpen] = useState(false)
  const [hostInfoDialogOpen, setHostInfoDialogOpen] = useState(false)
  const [travellerInfoDialogOpen, setTravellerInfoDialogOpen] = useState(false)
  const [faqDialogOpen, setFaqDialogOpen] = useState(false)
  const [deleteAccountDialogOpen, setDeleteAccountDialogOpen] = useState(false)
  const [resetAccountDialogOpen, setResetAccountDialogOpen] = useState(false)
  const [access, setAccess] = useState(false)
  const [loading, setLoading] = useState(false);
  const deleteUserApi = useApi(userApi.deleteUser)
  const resetUserAndMarkerDataApi = useApi(userApi.resetUserAndMarkerData)
  const getProfileDetailsApi = useApi(userApi.getProfileDetails)
  const getOrCreateRevenuecatCustomerInfoApi = useApi(userApi.getOrCreateRevenuecatSubscriber)
  const createStripeCustomerAndSubscriptionApi = useApi(userApi.createStripeCustomerAndSubscription)
  const { logout } = useAuth0();

  const fetchMyAPI = async () => {
    const profileFetch = await getProfileDetailsApi.request()
    if (!profileFetch) { return }
    setAccess(await Util.getAccessType(profileFetch.data, getOrCreateRevenuecatCustomerInfoApi, createStripeCustomerAndSubscriptionApi))
  }

  useEffect(() => {
    fetchMyAPI()
  }, [])


  const menuItems = [
    {
      title: "Message Notifications",
      icon: {
        iconComponent: <Icon path={mdiBell} size={1} color={colors.white} />,
        iconWrapperClassName: "menuIconWrapper"
      },
      onClick: () => { history.replace(routes.NOTIFICATIONS) },
    },
    {
      title: "Reset Public Details",
      icon: {
        iconComponent: <Icon path={mdiAccountDetails} size={1} color={colors.white} />,
        iconWrapperClassName: "menuIconWrapper"
      },
      onClick: () => { setResetAccountDialogOpen(true) },
    },
    {
      title: "Personal Safety Guide",
      icon: {
        iconComponent: <Icon path={mdiShieldCheck} size={1} color={colors.white} />,
        iconWrapperClassName: "menuIconWrapper"
      },
      onClick: () => { setSafetyDialogOpen(true) }
    },
    {
      title: "Information For Hippohosts",
      icon: {
        iconComponent: <Icon path={mdiInformation} size={1} color={colors.white} />,
        iconWrapperClassName: "menuIconWrapper"
      },
      onClick: () => { setHostInfoDialogOpen(true) }
    },
    {
      title: "Information For Hippohelpers",
      icon: {
        iconComponent: <Icon path={mdiInformation} size={1} color={colors.white} />,
        iconWrapperClassName: "menuIconWrapper"
      },
      onClick: () => { setTravellerInfoDialogOpen(true) }
    },
    {
      title: "Support",
      icon: {
        iconComponent: <Icon path={mdiFrequentlyAskedQuestions} size={1} color={colors.white} />,
        iconWrapperClassName: "menuIconWrapper"
      },
      onClick: () => { setFaqDialogOpen(true) }
    },
    {
      title: "Terms And Conditions",
      icon: {
        iconComponent: <Icon path={mdiText} size={1} color={colors.white} />,
        iconWrapperClassName: "menuIconWrapper"
      },
      onClick: () => { setTermsAndConditionsDialogOpen(true) }
    },
    {
      title: "Privacy Policy",
      icon: {
        iconComponent: <Icon path={mdiText} size={1} color={colors.white} />,
        iconWrapperClassName: "menuIconWrapper"
      },
      onClick: () => { setPrivacyPolicyDialogOpen(true) }
    },
    {
      title: "Licenses",
      icon: {
        iconComponent: <Icon path={mdiText} size={1} color={colors.white} />,
        iconWrapperClassName: "menuIconWrapper"
      },
      onClick: () => { setLicensesDialogOpen(true) }
    },
    {
      title: "Delete Account",
      icon: {
        iconComponent: <Icon path={mdiAccountCancel} size={1} color={colors.white} />,
        iconWrapperClassName: "menuIconWrapper"
      },
      onClick: () => { 
        setDeleteAccountDialogOpen(true)
       },
    }
  ]
  
  return (
    <div className="col-10 col-lg-6 mx-auto mt-4 screen">
      {access && (
        <>
          {loading === true ? (
            <Spinner visible={loading} />
          ) : (
            <>
              {error && ( <p>{error}</p> )}
              <HTMLDialog 
                title="Personal Safety Guide"
                content="safety"
                passedOpen={safetyDialogOpen} 
                onClose={() => {setSafetyDialogOpen(false)}}
              />
              <HTMLDialog 
                title="Terms And Conditions"
                content="termsAndConditions"
                passedOpen={termsAndConditionsDialogOpen} 
                onClose={() => {setTermsAndConditionsDialogOpen(false)}}
              />
              <HTMLDialog 
                title="Privacy Policy"
                content="privacyPolicy"
                passedOpen={privacyPolicyDialogOpen} 
                onClose={() => {setPrivacyPolicyDialogOpen(false)}}
              />
              <HTMLDialog 
                title="Licenses"
                content="licenses"
                passedOpen={licensesDialogOpen} 
                onClose={() => {setLicensesDialogOpen(false)}}
              />
              <HTMLDialog 
                title="Information For Hippohosts"
                content="hostInfo"
                passedOpen={hostInfoDialogOpen} 
                onClose={() => {setHostInfoDialogOpen(false)}}
              />
              <HTMLDialog 
                title="Information For Hippohelpers"
                content="travellerInfo"
                passedOpen={travellerInfoDialogOpen} 
                onClose={() => {setTravellerInfoDialogOpen(false)}}
              />
              <HTMLDialog 
                title="Frequently Asked Questions"
                content="faq"
                passedOpen={faqDialogOpen} 
                onClose={() => {setFaqDialogOpen(false)}}
              />
              {access.type === 'paidAccess' ? (
                <HTMLDialog 
                  title="Delete Account"
                  content="deleteAccountCancelFirst"
                  passedOpen={deleteAccountDialogOpen} 
                  onClose={() => {setDeleteAccountDialogOpen(false)}}
                  action={() => {
                    window.location = '/premium'
                  }}
                />
              ) : (
                <HTMLDialog 
                  title="Delete Account"
                  content="deleteAccount"
                  passedOpen={deleteAccountDialogOpen} 
                  onClose={() => {setDeleteAccountDialogOpen(false)}}
                  action={async () => {
                    setLoading(true)
                    const fetch = await deleteUserApi.request()
                    if (!fetch) { return }
                    logout({ returnTo: window.location.origin })}
                  }
                />
              )}
              <HTMLDialog 
                title="Reset Public Details"
                content="resetAccount"
                passedOpen={resetAccountDialogOpen} 
                onClose={() => {setResetAccountDialogOpen(false)}}
                action={async () => {
                  const fetch = await resetUserAndMarkerDataApi.request()
                  if (!fetch) { return }
                  window.location.href = '/'
                }}
              />
              <div>
                {
                  menuItems.map(function (menuItem, index) {
                    return (
                      <MenuItem
                        key={index + '-menuItem'}
                        title={menuItem.title}
                        IconComponent={ menuItem.icon.iconComponent }
                        onClick={() => { menuItem.onClick() }}
                        iconWrapperClassName={menuItem.icon.iconWrapperClassName}
                      />
                    )
                  })
                }             
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
}

export default MoreScreen;