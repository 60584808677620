import React, { useState, useEffect } from 'react'
import Text from '../other/Text'
import useApi from '../../hooks/useApi'
import userApi from '../../api/userApi'
import Button from '@material-ui/core/Button';
import Icon from '@mdi/react'
import { mdiStar} from '@mdi/js'
import colors from '../../config/colors';
import routes from '../../navigation/routes';
import { useHistory } from "react-router-dom"
import { useAuth0 } from "@auth0/auth0-react";

function ReviewsComponent ({ userID, authStatus, isAdmin = false }) {
  const history = useHistory();
  const [reviewsText, setReviewsText] = useState()
  const [reviews, setReviews] = useState(false)
  const [contentVisible, setContentVisible] = useState("hidden")
  const getReviewsApi = useApi(userApi.getReviews)
  const { loginWithRedirect } = useAuth0();

  const handeWriteReviewClick = function () {
    history.push(routes.WRITE_REVIEW + '/' + userID)
  }

  const handeShowReviewsClick = function () {
    if (authStatus === 'noAuth') {
      loginWithRedirect({ screen_hint: 'signup' })
    } else {
      history.push(routes.SHOW_REVIEWS + '/' + userID)
    }
  }

  const fetchMyAPI = async () => {
    const fetch = await getReviewsApi.request(userID)
    if (!fetch) { return }

    const reviews = fetch.data.references
    setReviews(reviews)
    if (reviews.length > 0) {
      let reviewsCount = 0
      let positiveReviews = 0
      let positivePercentage
      for (let i = 0; i < reviews.length; i++) { 
        reviewsCount = reviewsCount + reviews[i].counter
        if (reviews[i].positive_reference === 1) { 
          positiveReviews = positiveReviews + reviews[i].counter
        } 
      }
      positivePercentage = Math.round((positiveReviews / reviewsCount) * 100)
      const reviewWord = (reviewsCount === 1) ? 'review':'reviews'
      setReviewsText(`${positivePercentage}% positive based on ${reviewsCount} ${reviewWord}`)
    } else {
      setReviewsText('No feedback here yet!')
    }
    setContentVisible('block')
  }

  useEffect(() =>  {
    fetchMyAPI()
  }, [userID])

  return (
    <div className="reviewsComponentContainer">
      <div className={contentVisible}>
        <div className="summaryWrapper" >
          <Icon path={mdiStar} size={1} color={colors.dark} />
          <Text>{reviewsText}</Text>
        </div>
        <div className="dualButtonWrapper">
          {reviews.length > 0 && (
            <Button className={"genericButton secondaryColor genericButtonDoubleRow"} onClick={() => handeShowReviewsClick()} >Show feedback</Button>
          )}
          {authStatus === 'authOthersListing' && isAdmin === false && (
            <Button className={"genericButton secondaryColor genericButtonDoubleRow"} onClick={() => handeWriteReviewClick()} >Write feedback</Button>
          )}
        </div>
      </div>
    </div>
  )
}

export default ReviewsComponent
