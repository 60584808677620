import * as React from 'react';
import { Button } from '@mui/material';

export default function AsyncSearchBox({searchResults = [], onClear, onSearchClick}) {
  
  return (
    <div className='adminSearchItemSection'>
      {
        searchResults.length > 0 && (
          <div>
            <Button 
              className={"genericButton"} 
              onClick={onClear}
              >
                Hide Search
            </Button>
          </div>
        )
      }
      {
        searchResults.length > 0 && (
          searchResults.map((searchItem, index) => {
            return (
                <div className='adminReviewItem' key={index} onClick={() => onSearchClick(searchItem)}>
                  <p>{searchItem.name ? searchItem.name : searchItem.username}</p>
                </div>
            )
          })
        )
      }
    </div>
  );
}

