import React from 'react';
import { mdiLoading} from '@mdi/js'
import Icon from '@mdi/react'
import colors from '../../config/colors';

function Spinner({visible, withMessage = true, withPaymentMessage = false}) {
  return (
    <>
      {visible && (
        <>
          <div className='spinner'>
            <div>
              <Icon spin={true} path={mdiLoading} size={5} color={colors.primary} />
            </div>
            <div> 
              {withMessage && (
                <p>Please wait ...</p>
              )}
              {withPaymentMessage && (
                <p>Making payment ...</p>

              )}
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default Spinner;