import React, { useRef } from 'react'
import Icon from '@mdi/react'
import colors from '../../config/colors'
import { mdiArrowRightCircle } from '@mdi/js';
import { Util } from '../../utils/util';
import ImageGallery from 'react-image-gallery';

// friendAndFavouriteStatus here will always be fresh, because card will be hidden on tab-switch, and the only way to show it is by pressing a marker, and then data will be fetched from MapScreen and passed to Card before Card is shown.
function HippomatchCard ({ title, images, onClick, showThumbnails = false, showNav = true, showFullScreenButton = false }) {
  const imageComponent = useRef(null);
  if (title.length > 34) {
    title = title.substring(0, 34) + ' ...'
  }

  return (
    <div className="listingCard hippomatchCard">
        <div className="listingCardImageWrapper hippomatchListingCardImageWrapper">
          <ImageGallery 
            showThumbnails={showThumbnails}
            showNav={showNav}
            showFullscreenButton={showFullScreenButton}
            ref={imageComponent} 
            onClick={onClick} 
            showPlayButton={false} 
            items={Util.setGalleryImages(images)}
          />
        </div>
        <div className="doubleContainer" onClick={onClick}>
          <p className="subArea1">
            {title}
          </p>
          <div className="subArea2">
            <Icon path={mdiArrowRightCircle} color={colors.primary} size={'30px'} />
          </div>
        </div>
    </div>
  )
}

export default HippomatchCard
