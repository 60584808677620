// Dev:
const baseURL = process.env.REACT_APP_GATEWAY_URL
const frontendUrl = process.env.REACT_APP_BASE_URL

// Live (staging):
// const baseURL = 'https://hippohelp-logic-staging.shimmershot.com/gateway/'
// const frontendUrl = 'https://hippohelp.com'

export default {
  DUMMY_IMAGE: {name: "dummyImage", uri: '/images/dummy-image.png', isSocialImage: false},
  baseURL: baseURL,
  frontendUrl: frontendUrl,
  IMAGE_STORE_URL: process.env.REACT_APP_IMAGE_STORE_URL,
  ADMIN_EMAIL: process.env.REACT_APP_ADMIN_EMAIL,
  MINI_ADMIN_EMAIL: process.env.REACT_APP_MINI_ADMIN_EMAIL
}
