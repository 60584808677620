import React from 'react'
import Icon from '@mdi/react'
import colors from '../../config/colors';
import { mdiEyeOffOutline } from '@mdi/js';

function TouchableImage ({uri, onClick, socialHidden}) {
  // socialHidden means that the facebook-image is currently hidden from other users.
  return (
      <div onClick={onClick} className="touchableImageContainer">
        <>
          {socialHidden && (
            <div className="hiddenIconContainer">
              <Icon path={mdiEyeOffOutline} size={1} color={colors.light} />
              <p className="lightColor">(Hidden)</p>
            </div>
          )}
          <img src={uri} className="touchableImage"/>
        </>
      </div>
  )
}
export default TouchableImage
