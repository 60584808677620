import { useState } from 'react'
import { useAuth0 } from "@auth0/auth0-react";
import messages from '../config/messages'
import { useHistory } from "react-router-dom";
import routes from '../navigation/routes';

const useApi = (apiFunction) => {
  const history = useHistory();
  const { isAuthenticated, getAccessTokenSilently } = useAuth0();

  const getAccessToken = async () => {
    const fetchedAccessToken = await getAccessTokenSilently({
      audience: process.env.REACT_APP_AUTH0_AUDIENCE,
      scope: "read:current_user",
    });

    return fetchedAccessToken
  };

  const request = async (...args) => {
    try {
      let accessToken
      if (isAuthenticated) { accessToken = await getAccessToken() }
      const result = await apiFunction(...args, accessToken)
  
      if (!result || !result?.ok || result?.hippoError) {  // If something went wrong
        if (result.data?.hippoError?.publicType === "MessageLimitError") {
          return {
            ok: false,
            publicType: result.data.hippoError.publicType
          }
        } else if (result?.message === 'Success!') { 
          return result 
        } else if (result?.publicType === "MaximumImagesError") {
          return {
            ok: false,
            publicType: result.publicType
          }
        }
        
        history.push({ pathname: routes.ERROR, state: {  // Any errors not related to auth
          type: 'fetchError',
          data: {
            ok: result.ok
          }
        }})
      } else {
        return result // If there is no errors
      }
    } catch (error) { // Uncaught errors
      history.push({ pathname: routes.ERROR, state: {
        type: 'uncaught',
        data: error
      }})
    }
  }

  return { request }
}

export default useApi
