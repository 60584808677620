import React from 'react'
import Modal from '@mui/material/Modal';
import Icon from '@mdi/react'
import { mdiLoading } from '@mdi/js'
import colors from '../config/colors'

function ResizeScreen ({ open = false }) {
  return (
    <Modal open={open}>
      <div className="uploadContainer">
      <Icon path={mdiLoading} size={7} color={colors.primary} spin />
        <p className="uploadText">Resizing...</p>
      </div>
    </Modal>
  )
}

export default ResizeScreen
