import React, {useState} from 'react'
import { GoogleMap, useJsApiLoader, Marker } from '@react-google-maps/api';
import { useHistory } from "react-router-dom"

import Button from '@material-ui/core/Button';
import routes from '../navigation/routes'
import { Util } from '../utils/util';
import mapConfig from '../config/map'

function SetHostMarkerScreen () {
  const {isLoaded, loadError} = useJsApiLoader({ 
    id: 'google-map-script', 
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_KEY,
    libraries: mapConfig.libraries
  })
  const history = useHistory();
  const userData = history.location.state

  const [coordinates, setCoordinates] = useState(Util.setInitialHostMarkerCoordinates(userData))
  const [center, setCenter] = useState(Util.getInitialPosition(coordinates))
    
  return (
    <div className="col-10 col-lg-6 mx-auto mapContainer">
      {isLoaded && (
        <div className="placeMarkerContainer">
        <div className="centeredContainer placeMarkerPrompt lightFontColor ">
          <p>Click on the map </p>
          <p>to place your hostmarker.</p>
        </div>
        <GoogleMap
          mapContainerStyle={mapConfig.containerStyle}
          zoom={4}
          center={center}
          options={mapConfig.mapOptions}
          onClick={(e) => {
            setCoordinates(e.latLng.toJSON())
          }}
        >
        {
          coordinates && (
            <Marker
              position={{lat: coordinates.lat, lng: coordinates.lng}}
              icon={'/images/host-marker.png'}
              draggable
              onDragEnd={e => setCoordinates(e.latLng.toJSON())}
            />
          )
        }
        </GoogleMap>
        {
          coordinates && (
            <div className="singleButtonContainer continueButtonContainer">
              <Button 
                variant="contained" 
                className="genericButton primaryColor" 
                onClick={ () =>
                  history.push({
                    pathname: routes.SET_HOST_MARKER_DETAILS,
                    state: {
                      userData: userData,
                      coordinates: coordinates
                    }
                  })
                }
              >
                Continue
              </Button>
            </div>
          )
        }
      </div>
      )}
      {loadError && (<p>Map cannot be loaded right now, sorry.</p>)}
    </div>
  )
}

export default SetHostMarkerScreen
