import React, { useState, useEffect } from 'react'
import Icon from '@mdi/react'
import { mdiHeart, mdiHeartOutline, mdiLoading} from '@mdi/js'
import colors from '../../config/colors'
import useApi from '../../hooks/useApi'
import userApi from '../../api/userApi'
import markerApi from '../../api/markerApi'
import { useAuth0 } from "@auth0/auth0-react";

function FavouriteIconButton({isFavourite, isHostMarker, userID, markerID}) {
  const { isAuthenticated, loginWithRedirect } = useAuth0();

  const [iconPath, setIconPath] = useState()
  const [spin, setSpin] = useState(false)
  const [onClick, setOnClick] = useState()

  const addUserToFavouritesApi = useApi(userApi.addUserToFavourites)
  const addMarkerToFavouritesApi = useApi(markerApi.addMarkerToFavourites)
  const removeMarkerFromFavouritesApi = useApi(markerApi.removeMarkerFromFavourites)
  const removeUserFromFavouritesApi = useApi(userApi.removeUserFromFavourites)

  const conditionalAddFavourite = async function () {
    if (isHostMarker) {
      return await addMarkerToFavouritesApi.request(markerID)
    } else {
      return await addUserToFavouritesApi.request(userID)
    }
  }

  const conditionalRemoveFavourite = async function () {
    if (isHostMarker) {
      return await removeMarkerFromFavouritesApi.request(markerID)
    } else {
      return await removeUserFromFavouritesApi.request(userID)
    }
  }

  const addFavourite = async function () {
    // Before
    setIconPath(mdiLoading)
    setSpin(true)
    setOnClick(() => function () { return })

    // Api call
    const fetch = await conditionalAddFavourite()
    if (!fetch) { return }
    
    // After
    setIconPath(mdiHeart)
    setSpin(false)
    setOnClick(() => removeFavourite)
  }

  const removeFavourite = async function () {
    // Before
    setIconPath(mdiLoading)
    setSpin(true)
    setOnClick(() => function () { return })

    // Api call
    const fetch = await conditionalRemoveFavourite()
    if (!fetch) { return }
    
    // After
    setIconPath(mdiHeartOutline)
    setSpin(false)
    setOnClick(() => addFavourite)
  }

  useEffect(() => {
    if (isFavourite) {
      setIconPath (mdiHeart)
      setOnClick(() => removeFavourite)
    } else {
      setIconPath (mdiHeartOutline)
      setOnClick(() => addFavourite)
    }
  }, [])

  return (
    <div>
      {
        (isAuthenticated === false) ? (
          <div onClick={() => loginWithRedirect({ screen_hint: 'signup' })} className="iconWrapper iconButton">
            <Icon spin={spin} path={iconPath} size={1} color={colors.white} />
          </div>
        ) : (
          <>
            {
              onClick && (
                <div onClick={onClick} className="iconWrapper iconButton">
                  <Icon spin={spin} path={iconPath} size={1} color={colors.white} />
                </div>
              )
            }
          </>
        )
      }
    </div>
  );
}

export default FavouriteIconButton