import React from 'react'
import Text from '../other/Text'
import { Util } from '../../utils/util';
import Icon from '@mdi/react'
import colors from '../../config/colors'
import { mdiThumbUp, mdiThumbDown } from '@mdi/js'

function ReviewItem ({ review }) {

  const generateReferenceIcon = function (review) {
    if (review.positive_reference === 1) {
      return <Icon path={mdiThumbUp} size={1} color={colors.green} />
    } else {
      return <Icon path={mdiThumbDown} size={1} color={colors.red} />
    }
  }

  const renderReviewText = function (review) {
    if (review.reference_text === "external_reference") {
      let referenceType
      if (review.positive_reference === 1) {
        referenceType = "positive"
      } else {
        referenceType = "negative"
      }

      return (
        <>
        <Text>{review.counter} external {referenceType} references.</Text>
        <Text onClick={() => { alert('You are now leaving Hippohelp will show reviews left on another service.') }}><a href={review.external_link} target='_blank' rel='noopener noreferrer'>Open in a new tab</a></Text>
        </>
      )
    }
    return (
      <Text>{review.reference_text}</Text>
    )
  }
  
  return (
    <div className={"reviewItemContainer"}>
      <div className={"reviewImageContainer"}>
        <img src={Util.getFirstImageSource(review.owner.image)} />
      </div>
      <div className={"reviewContentContainer"}>
        <div>
          <Text className="reviewItemHeader">{Util.generateTitle(review.owner)} {generateReferenceIcon(review)}</Text>
        </div>
        <div>
          {renderReviewText(review)}
        </div>
        <div>
          <Text>{review.updated_at.substring(0, 10)}</Text>
        </div>
      </div>
    </div>
  )
}

export default ReviewItem
