import React, { useState } from 'react'
import Button from '@material-ui/core/Button';
import GenericModal from '../../components/other/GenericModal'
import Text from '../other/Text'
import { useAuth0 } from "@auth0/auth0-react";

function LinkPart ({link}) {
  const urlPattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name and extension
    '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
    '(\\:\\d+)?'+ // port
    '(\\/[-a-z\\d%_.~+]*)*'+ // path
    '(\\?[;&amp;a-z\\d%_.~+=-]*)?'+ // query string
    '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
  const isUrl = urlPattern.test(link);

  return (
    <div className={"linkItemContainer"}>
      {isUrl && (
        <Text onClick={() => { alert('You are now leaving Hippohelp.') }}>
          <a href={link.startsWith('http') ? link : `https://${link}`} target='_blank' rel='noopener noreferrer'>
            {link}
          </a>
        </Text>
      )}
      {!isUrl && (
        <Text>{link}</Text>
      )}
    </div>
  )
}

function LinkComponent ({ displayName, links, containerStyle, access = { hasAccess: true } }) {
  const { isAuthenticated, loginWithRedirect } = useAuth0();
  const [open, setOpen] = useState(false)

  const generateLinks = () => {
    return links.map((element, index) => {
      return (
        <LinkPart key={index} link={element.link} />
      )
    })
  }

  return (
    <div className="linkComponentContainer" style={containerStyle}>
      <GenericModal 
        open={open} 
        title={`${displayName}'s social profiles / links`}
        onHide={() => {
          setOpen(false)
        }}
        content={generateLinks()}
      />
      {isAuthenticated === false ? (
        <Button className={"genericButton secondaryColor genericButtonDoubleRow"} onClick={() => loginWithRedirect({ screen_hint: 'signup' })} >
        Social Profiles / Links
      </Button>
      ) : (
        <>
          {access.hasAccess === true && (
            <Button className={"genericButton secondaryColor genericButtonDoubleRow"} onClick={() => setOpen(true)} >
              Social Profiles / Links
            </Button>
          )}
          {access.hasAccess !== true && (
            <>
              {access.type === "hostmarkerAwaitingApproval" && (
                <Button className={"genericButton secondaryColor genericButtonDoubleRow"} onClick={() => alert('Once your hostmarker is approved you will have premium access for free, come back here then!')} >
                  Social Profiles / Links
                </Button>
              )}
              {access.type !== "hostmarkerAwaitingApproval" && (
                <Button className={"genericButton secondaryColor genericButtonDoubleRow"} onClick={() => alert('Purchase premium membership to view social profiles or links.')} >
                  Social Profiles / Links
                </Button>
              )}
            </>

          )}
        </>
      )}

    </div>
  )
}

export default LinkComponent
