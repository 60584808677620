import React, { useState } from 'react'
import useApi from '../hooks/useApi'
import userApi from '../api/userApi';
import { Util } from '../utils/util';
import Form from '../forms/Form'
import TextInputField from '../forms/TextInputField'
import SubmitButton from '../forms/SubmitButton';
import { useAuth0 } from "@auth0/auth0-react";

function HippohelpFeedbackScreen() {
  const { isLoading } = useAuth0();
  const sendFeedbackMessageApi = useApi(userApi.sendHippohelpFeeback)
  const [feedbackStatus, setFeedbackStatus] = useState(false)

  const handleSubmit = async ({ feedback }) => {
    const emptyFields = []
    if (!feedback) { emptyFields.push('feedback') }

    if (Util.checkEmptyFields(emptyFields)) {
      const sendData = {
        hippohelpFeedback: feedback
      }

      const result = await sendFeedbackMessageApi.request(sendData)
      if (!result) { return }

      setFeedbackStatus("Thanks for your feedback!")
    }
  }

  return (
    <div className="col-10 col-lg-6 mx-auto mt-4 screen">
      <>
        {!isLoading && (
            <>
              {!feedbackStatus && (
                <Form onSubmit={handleSubmit} initialValues={{}}>
                  <div className="textBox">
                    <TextInputField name='feedback' label='Feedback' inputProps={{ maxLength: 1000 }} multiline />
                  </div>
                  <div>
                    <SubmitButton />
                  </div>
                </Form>
              )}
              {feedbackStatus && ( 
                <>
                  <p>{feedbackStatus}</p>
                  <a href='/'>Home</a>
                </>
              )}
            </>
        )}
      </>
    </div>
  );
}

export default HippohelpFeedbackScreen;