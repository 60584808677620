import React, {useState, useEffect} from 'react'
import useApi from '../hooks/useApi'
import userApi from '../api/userApi';
import Button from '@material-ui/core/Button';
import { Util } from '../utils/util';
import ReviewItem from '../components/lists/ReviewItem';
import { useHistory } from "react-router-dom"
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import Paywall from '../components/premium/Paywall';
import markerApi from "../api/markerApi";
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY)

function ConversationScreen(props) {
  const history = useHistory();
  const [data, setData] = useState(false)
  const [title, setTitle] = useState(false)
  const [error, setError] = useState(false)
  const [nextPage, setNextPage] = useState(false)
  const getReviewsApi = useApi(userApi.getReviews)
  const getHostMarkerDetailsAnyApprovalApi = useApi(markerApi.getHostMarkerDetailsAnyApproval)
  const getProfileDetailsApi = useApi(userApi.getProfileDetails)
  const getOrCreateRevenuecatCustomerInfoApi = useApi(userApi.getOrCreateRevenuecatSubscriber)
  const createStripeCustomerAndSubscriptionApi = useApi(userApi.createStripeCustomerAndSubscription)
  const [access, setAccess] = useState(false)

  const fetchMyAPI = async () => {
    const fetch = await getReviewsApi.request(props.match.params.id)
    if (!fetch) { return }

    const nextPage = await Util.getDetailsRoute(fetch.data.userData, getHostMarkerDetailsAnyApprovalApi)
    if (!nextPage) { return }
    
    setNextPage(nextPage)
    setData(fetch.data)
    setTitle("Feedback")

    const profileFetch = await getProfileDetailsApi.request()
    if (!profileFetch) { return }
    setAccess(await Util.getAccessType(profileFetch.data, getOrCreateRevenuecatCustomerInfoApi, createStripeCustomerAndSubscriptionApi))
  }

  useEffect(() => {
    fetchMyAPI()
  }, [])
  
  return (
    <>
      {access.hasAccess === false && (
        <>
          {access.type === 'hostmarkerAwaitingApproval' && (
            <div style={{textAlign: 'center'}}>
              <p>Once your hostmarker is approved you will have premium access for free, come back here then!</p>
              <a href='/'>Home</a>
            </div>
          )}
          {access.type !== 'hostmarkerAwaitingApproval' && (
            <Elements options={{ clientSecret: access.clientSecret, appearance: { theme: 'stripe' } }} stripe={stripePromise}>
              <Paywall title="Premium Feature Detected!" />
            </Elements>
          )}
        </>
      )}
      {access.hasAccess === true && (
        <div className="col-10 col-lg-6 mx-auto mt-4 screen">
          {error && ( <p>{error}</p> )}
          {data && (
            <>
              <div className="row">
                <div className="contentHeader">
                <h1>{title}</h1>
                  <Button className={"genericButton"} onClick={() => history.push(nextPage)}>Profile</Button>
                </div>
              </div>
              <div className="row mt-4">
                {
                  data.references.map(function (reviewItem, index) {
                    return (
                    <ReviewItem
                      key={index}
                      review={reviewItem}
                    />)
                  })
                }
              </div>
            </>
          )}
        </div>
)     }
    </>
  );
}

export default ConversationScreen;