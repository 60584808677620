import React, {useState, useEffect, useRef} from 'react'
import useApi from '../hooks/useApi'
import userApi from '../api/userApi';
import Button from '@material-ui/core/Button';
import { Util } from '../utils/util';
import Form from '../forms/Form'
import TextInputField from '../forms/TextInputField'
import SubmitButton from '../forms/SubmitButton';
import ErrorMessage from '../forms/ErrorMessage';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { useHistory } from "react-router-dom"
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import Paywall from '../components/premium/Paywall';
import markerApi from "../api/markerApi";
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY)

function ConversationScreen(props) {
  const history = useHistory();
  const [data, setData] = useState(false)
  const [title, setTitle] = useState(false)
  const saveReviewApi = useApi(userApi.saveReview)
  const getReviewApi = useApi(userApi.getReview)
  const [userApiMethod, setUserApiMethod] = useState(false)
  const [toHost, setToHost] = useState(false)
  const [nextPage, setNextPage] = useState(false)
  const [reviewType, setReviewType] = useState(false)
  const [fieldValues, setFieldValues] = useState(false)
  const [error, setError] = useState(false)
  const [access, setAccess] = useState(false)
  const getOrCreateRevenuecatCustomerInfoApi = useApi(userApi.getOrCreateRevenuecatSubscriber)
  const createStripeCustomerAndSubscriptionApi = useApi(userApi.createStripeCustomerAndSubscription)
  const getHostMarkerDetailsAnyApprovalApi = useApi(markerApi.getHostMarkerDetailsAnyApproval)
  const getProfileDetailsApi = useApi(userApi.getProfileDetails)

  const fetchMyAPI = async () => {
    const fetch = await getReviewApi.request(props.match.params.id)
    if (!fetch) { return }

    const nextPage = await Util.getDetailsRoute(fetch.data.userData, getHostMarkerDetailsAnyApprovalApi)
    if (!nextPage) { return }
    
    if (fetch.data.userData.host_event_count > 0) { // Hostmarker
      setToHost(1)
    } else if (fetch.data.userData.user_account_type_id === 1 || fetch.data.userData.user_account_type_id === 2) { // Travel-user
      setToHost(0)
    } else { // New user, or host-user without hostmarker.
      setToHost(0)
    }

    if (fetch.data?.reference?.reference_text) {
      setReviewType(fetch.data.reference.positive_reference)
      setUserApiMethod('put')
      setFieldValues({ review: fetch.data.reference.reference_text })
    } else {
      setReviewType(1)
      setUserApiMethod('post')
      setFieldValues({})      
    }

    setNextPage(nextPage)
    setData(fetch.data)
    setTitle("Write Feedback")

    const profileFetch = await getProfileDetailsApi.request()
    if (!profileFetch) { return }
    setAccess(await Util.getAccessType(profileFetch.data, getOrCreateRevenuecatCustomerInfoApi, createStripeCustomerAndSubscriptionApi))
  }

  const handleSubmit = async ({ review }) => {
    const emptyFields = []
    if (reviewType !== 0 && reviewType !== 1) { emptyFields.push('Review type') }
    if (!review) { emptyFields.push('Review') }

    if (Util.checkEmptyFields(emptyFields)) {
      const saveData = {
        review: review,
        reviewType: reviewType,
        toHost: toHost,
        userID: data.userData.id,
        userApiMethod: userApiMethod
      }
      const result = await saveReviewApi.request(saveData)
      if (!result) { return }
      setError(false)
      
      history.push(nextPage)
    }
  }

  const handleChange = (event) => {
    setReviewType(event.target.value);
  };


  useEffect(() => {
    fetchMyAPI()
  }, [])

  return (
    <>
      {access.hasAccess === false && (
        <>
          {access.type === 'hostmarkerAwaitingApproval' && (
            <div style={{textAlign: 'center'}}>
              <p>Once your hostmarker is approved you will have premium access for free, come back here then!</p>
              <a href='/'>Home</a>
            </div>
          )}
          {access.type !== 'hostmarkerAwaitingApproval' && (
            <Elements options={{ clientSecret: access.clientSecret, appearance: { theme: 'stripe' } }} stripe={stripePromise}>
              <Paywall title="Premium feature detected!" />
            </Elements>
          )}
        </>
      )}
      {access.hasAccess === true && (
        <div className="col-10 col-lg-6 mx-auto mt-4 screen">
          {!data && error && ( <ErrorMessage error={error} /> )}
          {data && (
            <>
              <div className="row mb-4">
                <div className="contentHeader">
                <h1>{title}</h1>
                  <Button className={"genericButton"} onClick={() => history.push(nextPage)}>Profile</Button>
                </div>
              </div>
              {fieldValues && (
                <Form onSubmit={handleSubmit} initialValues={fieldValues}>
                  <ErrorMessage error={error} />
                  <div className="textBox">
                    <TextInputField name='review' label='Feedback' multiline inputProps={{ maxLength: 5000 }} />
                  </div>
                  <div className="selectInput">
                    <Select
                      labelId="select-label"
                      id="select-label"
                      value={reviewType}
                      onChange={handleChange}
                    >
                      <MenuItem value={1}>Positive</MenuItem>
                      <MenuItem value={0}>Negative</MenuItem>
                    </Select>
                  </div>
                  <div>
                    <SubmitButton />
                  </div>
                </Form>
              )}
            </>
          )}
        </div>
      )}
    </>
  );
}

export default ConversationScreen;