import React, { useState, useEffect } from 'react'
import useApi from '../hooks/useApi'
import userApi from '../api/userApi';
import { Util } from '../utils/util';
import Form from '../forms/Form'
import TextInputField from '../forms/TextInputField'
import SubmitButton from '../forms/SubmitButton';
import markerApi from "../api/markerApi";
import Button from '@material-ui/core/Button';
import { useHistory } from "react-router-dom"

function ReportScreen(props) {
  const history = useHistory();
  const saveUserReportApi = useApi(userApi.saveUserReport)
  const getUserReportApi = useApi(userApi.getUserReport)
  const [userApiMethod, setUserApiMethod] = useState(false)
  const [fieldValues, setFieldValues] = useState(false)
  const [title, setTitle] = useState(false)
  const [nextPage, setNextPage] = useState(false)
  const getHostMarkerDetailsAnyApprovalApi = useApi(markerApi.getHostMarkerDetailsAnyApproval)

  const handleSubmit = async ({ report }) => {
    const emptyFields = []
    if (!report) { emptyFields.push('Report') }

    if (Util.checkEmptyFields(emptyFields)) {
      const saveData = {
        report: report,
        userID: props.match.params.id,
        userApiMethod: userApiMethod
      }

      const saveUserReportApiFetch = await saveUserReportApi.request(saveData)
      if (!saveUserReportApiFetch) { return }

      alert("Thanks for your report!")
      history.push(nextPage)
    }
  }

  const fetchMyAPI = async () => {
    const fetch = await getUserReportApi.request(props.match.params.id)
    if (!fetch) { return }

    const nextPage = await Util.getDetailsRoute(fetch.data.userData, getHostMarkerDetailsAnyApprovalApi)
    if (!nextPage) { return }

    if (fetch.data?.userReport?.report_text) {
      setUserApiMethod('put')
      setFieldValues({ report: fetch.data.userReport.report_text })
    } else {
      setUserApiMethod('post')
      setFieldValues({})      
    }

    setNextPage(nextPage)
    setTitle(Util.generateTitle(fetch.data.userData))
  }

  useEffect(() =>  {
    fetchMyAPI()
  }, [])

  return (
    <div className="col-10 col-lg-6 mx-auto mt-4 screen">
      {fieldValues && (
        <>
          <div className="row mb-4">
            <div className="contentHeader">
            <h1>{title}</h1>
              <Button className={"genericButton"} onClick={() => history.push(nextPage)}>Profile</Button>
            </div>
          </div>
          <Form onSubmit={handleSubmit} initialValues={fieldValues}>
            <div className="textBox">
              <TextInputField 
                name='report' 
                label='Report' 
                inputProps={{ maxLength: 1000 }} 
                multiline
              />
            </div>
            <div>
              <SubmitButton />
            </div>
          </Form>
        </>
      )}
    </div>
  );
}

export default ReportScreen;