import React from 'react'
import { useFormikContext } from 'formik'
import colors from '../config/colors'
import Icon from '@mdi/react'
import { mdiSendCircle  } from '@mdi/js'

function SubmitIcon () {
  const { handleSubmit } = useFormikContext()

  return (
    <div className={"submitIcon"} onClick={handleSubmit}>
      <Icon path={mdiSendCircle} size={1} color={colors.primary} />
    </div>
  )
}

export default SubmitIcon
