export default Object.freeze({
  HIPPOMATCH: '/hippomatch',
  MAP: '/map',
  ACCOUNT: '/you',
  IMAGES: '/images',
  MESSAGES: '/messages',
  FAVOURITES: '/favourites',
  FRIENDS: '/friends',
  LOG_OUT: '/log-out',
  LOG_OUT_AUTH_ERROR: '/log-out-auth-error',
  HOST_MARKER_DETAILS: '/host',
  UNSET_USER_DETAILS: '/user',
  TRAVEL_USER_DETAILS: '/traveller',
  CONVERSATION: '/conversation',
  CONVERSATIONS: '/conversations',
  WRITE_REVIEW: '/write-review',
  SHOW_REVIEWS: '/show-reviews',
  ROOT: '/',
  SINGLE_IMAGE_SCREEN: '/single-image-screen',
  SET_HOST_MARKER: '/set-host-marker',
  SET_TRAVEL_MARKER: '/set-travel-marker',
  SET_TRAVEL_USER_DETAILS:'/set-travel-user-details',
  SET_HOST_MARKER_DETAILS:'/set-host-marker-details',
  CHOOSE_ACCOUNT_TYPE: '/choose-account-type',
  HOSTS: '/hosts',
  TRAVELLERS: '/travellers',
  MORE: '/more',
  PRIVACY_POLICY: '/privacy-policy',
  TERMS_AND_CONDITIONS: '/terms-and-conditions',
  INFORMATION_FOR_HIPPOHOSTS: '/information-for-hippohosts',
  INFORMATION_FOR_HIPPOHELPERS: '/information-for-hippohelpers',
  FAQ: '/support',
  SAFETY: '/safety',
  ERROR: '/error',
  PREMIUM: '/premium',
  LICENSES: '/licenses',
  LOGIN: '/login',
  LOGOUT: '/logout',
  CONTACT: '/contact',
  ACCOUNT_DELETION: '/account-deletion',
  REPORT: '/report',
  HIPPOHELP_FEEDBACK: '/hippohelp-feedback',
  BLOCK: '/block',
  GET_APP: '/get-app',
  HIPPOCHAT: '/hippochat',
  AI_CONVERSATION: '/ai-conversation',
  REPORT_AI_MESSAGE: '/report-ai-message',
  NOTIFICATIONS: '/notifications',
  BLOG: '/blog',
  CATEGORY: '/category',
  POST: '/post',
  PAYMENT_SUCCESSFUL: '/payment-successful',
  PAYMENT_PROCESSING: '/payment-processing',
  ATTRIBUTION: '/attribution',
})
