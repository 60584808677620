import React, { useState } from 'react'
import useApi from '../hooks/useApi'
import userApi from '../api/userApi';
import { Util } from '../utils/util';
import Form from '../forms/Form'
import TextInputField from '../forms/TextInputField'
import SubmitButton from '../forms/SubmitButton';
import { useAuth0 } from "@auth0/auth0-react";

function ContactMessageScreen() {
  const { isAuthenticated, isLoading } = useAuth0();
  const sendContactMessageApi = useApi(userApi.sendContactMessage)
  const sendContactMessagePublicApi = useApi(userApi.sendContactMessagePublic)
  const [messageStatus, setMessageStatus] = useState(false)

  const handleSubmit = async ({ message, subject, email }) => {
    const emptyFields = []
    if (!message) { emptyFields.push('message') }
    if (!subject) { emptyFields.push('subject') }
    if (!isAuthenticated && !email) { emptyFields.push('email') }

    if (Util.checkEmptyFields(emptyFields)) {
      const sendData = {
        message: message,
        subject: subject,
        email: email
      }

      if (isAuthenticated) {
        const result = await sendContactMessageApi.request(sendData)
        if (!result) { return }
      } else {
        const result = await sendContactMessagePublicApi.request(sendData)
        if (!result) { return }
      }

      setMessageStatus("Thanks for your message!")
    }
  }

  return (
    <div className="col-10 col-lg-6 mx-auto mt-4 screen">
      <>
        {!isLoading && (
            <>
              {!messageStatus && (
                <Form onSubmit={handleSubmit} initialValues={{}}>
                  <div className="textBox">
                    <TextInputField name='subject' label='Subject' inputProps={{ maxLength: 255 }} />
                  </div>
                  <div className="textBox">
                    <TextInputField name='message' label='Message' inputProps={{ maxLength: 1000 }} multiline />
                  </div>
                  {!isAuthenticated && ( 
                    <div>
                      <TextInputField name='email' label='Email' type='email' /> 
                    </div>
                  )}
                  <div>
                    <SubmitButton />
                  </div>
                </Form>
              )}
              {messageStatus && ( 
                <>
                  <p>{messageStatus}</p>
                  <a href='/'>Home</a>
                </>
              )}
            </>
        )}
      </>
    </div>
  );
}

export default ContactMessageScreen;