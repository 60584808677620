import React, { useState } from 'react'
import useApi from '../hooks/useApi'
import userApi from '../api/userApi';
import { Util } from '../utils/util';
import Form from '../forms/Form'
import TextInputField from '../forms/TextInputField'
import SubmitButton from '../forms/SubmitButton';
import { useAuth0 } from "@auth0/auth0-react";

function ContactMessageScreenAccountDeletion() {
  const { isAuthenticated, isLoading } = useAuth0();
  const sendContactMessageApi = useApi(userApi.sendContactMessage)
  const sendContactMessagePublicApi = useApi(userApi.sendContactMessagePublic)
  const [messageStatus, setMessageStatus] = useState(false)

  const handleSubmit = async ({ message, subject, email }) => {
    const emptyFields = []
    if (!message) { emptyFields.push('message') }
    if (!subject) { emptyFields.push('subject') }
    if (!isAuthenticated && !email) { emptyFields.push('email') }

    if (Util.checkEmptyFields(emptyFields)) {
      const sendData = {
        message: message,
        subject: subject,
        email: email
      }

      if (isAuthenticated) {
        const result = await sendContactMessageApi.request(sendData)
        if (!result) { return }
      } else {
        const result = await sendContactMessagePublicApi.request(sendData)
        if (!result) { return }
      }

      setMessageStatus("Thanks for your message!")
    }
  }

  return (
    <div className="col-10 col-lg-6 mx-auto mt-4 screen">
      <>
        {!isLoading && (
            <>
              {!messageStatus && (
                <>
                  <h1>How to delete your Hippohelp Account:</h1>
                  <p>To delete your account, first log in, then click on "You" in the navigation bar. From there, click on "More", followed by "Delete Account".</p>
                  <p>These steps apply to our web application, as well as our Android and iOS apps.</p>
                  <p>When deleting your account we will remove all your created data from our servers, including any pictures uploaded.</p>
                  <p>Even if you delete your account we may in some cases retain some information if we are required by law, for legitimate business purposes, or to protect the safety of other members.</p>
                  <p>Please don't hesitate to contact us using the form below in case you are not sure about how to delete your account, or if you have any other questions! You are also welcome to contact us if you need help with deleting your account or part of it.</p>
                  <p>Click <a href='https://hippohelp.com'>here</a> to access the web application, where you can log in and delete your account.</p>
                  <Form onSubmit={handleSubmit} initialValues={{}}>
                    <div className="textBox">
                      <TextInputField name='subject' label='Subject' inputProps={{ maxLength: 255 }} />
                    </div>
                    <div className="textBox">
                      <TextInputField name='message' label='Message' inputProps={{ maxLength: 1000 }} multiline />
                    </div>
                    {!isAuthenticated && ( 
                      <div>
                        <TextInputField name='email' label='Email' type='email' /> 
                      </div>
                    )}
                    <div>
                      <SubmitButton />
                    </div>
                  </Form>
                </>
              )}
              {messageStatus && ( 
                <>
                  <p>{messageStatus}</p>
                  <a href='/'>Home</a>
                </>
              )}
            </>
        )}
      </>
    </div>
  );
}

export default ContactMessageScreenAccountDeletion;