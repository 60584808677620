import React from 'react'
import { Util } from '../../utils/util'
import { useHistory } from "react-router-dom"
import { Button } from '@material-ui/core';
import MapIcon from '@mui/icons-material/Map';
import { useAuth0 } from "@auth0/auth0-react";
import routes from '../../navigation/routes';

function HostDetailsContent ({listingData}) {
  const history = useHistory();
  const { isAuthenticated, loginWithRedirect } = useAuth0();

  const panToMarker = function () {
    if (isAuthenticated) {
      history.push({
        pathname: routes.MAP,
        state: {latLng: {lat: parseFloat(listingData.latitude), lng: parseFloat(listingData.longitude)}}
      })
    } else {
      loginWithRedirect({ screen_hint: 'signup' })
    }
  }

  return (
    <div className="detailsContent">
      <div className="row">
        <Button 
          startIcon={<MapIcon />} 
          className={"genericButton primaryColor genericButtonWidth"}
          onClick={() => panToMarker()}
        >
          View on map
        </Button>
      </div>
      <div className="row">
        <h2>Description</h2>
        <p>{listingData.event_description}</p>
      </div>
      <div className="row">
        <h2>Host's preferences</h2>
        <h5>Duration of stay</h5>
        <p>{listingData.stay_duration_preferences}</p>
        <h5>Maximum number of helpers</h5>
        <p>{listingData.guest_max_number_preferences}</p>
        <h5>Last minute requests</h5>
        <p>{listingData.last_minute_preferences}</p>
      </div>
      <div className="row">
        <h2>Host's home</h2>
        <h5>Location</h5>
        <p>{listingData.address}</p>
        <h5>Language</h5>
        <p>{listingData.owner.language.language}</p>
        <h5>Has pets?</h5>
        <p>{listingData.pets_home}</p>
        <h5>Housing</h5>
        <p>{listingData.sleeping_home}</p>
      </div>
      <div className="row">
        <h2>Work</h2>
        <h5>Type of work</h5>
        {listingData.work.work_type.arrayFormat !== 0 && (
          <ul className="workTypeList">
            {
              listingData.work.work_type.arrayFormat.map (element => {
                return <li key={element}>{Util.parseWorkType(element)}</li>
              })
            }
          </ul>
        )}
        {listingData.work.work_type.arrayFormat == 0 && (
          <p>{listingData.work.work_type.stringFormat}</p>
        )}
        <h5>Hours per day</h5>
        <p>{listingData.work_hours}</p>
        <h5>Work requirements</h5>
        <p>{listingData.work_requirements}</p>
      </div>
      <div className="row">
        <h2>Additional info</h2>
        {listingData.additional_info && ( <p>{listingData.additional_info}</p> )}
        {!listingData.additional_info && ( <p>No additional info.</p> )}
      </div>
    </div>
  )
}

export default HostDetailsContent
