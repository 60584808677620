import React, { useState, useEffect } from 'react'
import useApi from '../hooks/useApi'
import userApi from '../api/userApi'
import sources from '../config/sources';
import routes from '../navigation/routes';
import TouchableImage from '../components/other/TouchableImage';
import { useHistory } from "react-router-dom"
import { mdiPlus } from '@mdi/js'
import InputSquare from '../components/other/InputSquare';
import colors from '../config/colors';
import Snackbar from '@mui/material/Snackbar';
import Slide from '@mui/material/Slide';
import MuiAlert from '@mui/material/Alert';
import Text from '../components/other/Text'
import Button from '@material-ui/core/Button';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function TransitionDown(props) {
  return <Slide {...props} direction="down" />;
}

function ImagesScreen() {
  const params = new URLSearchParams(window.location.search);
  const paramError = params.get('error');
  const history = useHistory();
  const [data, setData] = useState(false)
  const getProfileDetailsApi = useApi(userApi.getProfileDetails)
  const [open, setOpen] = React.useState(false);
  

  const openSnackbar = () => () => {
    setOpen(true);
    setTimeout(() => {
      setOpen(false);
    }, 10000);
  };

  useEffect(() => {
    if (paramError) {
      if (paramError === 'maximumImageError') {
        openSnackbar(TransitionDown)();
      }
    }

    async function fetchMyAPI() {
      const fetch = await getProfileDetailsApi.request()
      if (!fetch) { return }
      
      const data = fetch.data

      if (!data.image[0]) { // If the user has no facebook image or other images
        data.image[0] = sources.DUMMY_IMAGE
      } else if (data.image[0].name === 'facebookImageHidden') { // If the user has a hidden facebook image and no main image
        // If data.image[1] does not exist, then create a dummy-image placeholder and set it to data.image[1].
        if (!data.image[1]) {
          data.image[1] = sources.DUMMY_IMAGE
        }
        // Swap the data.image[0] and data.image[1] indexes so that the Facebook image is the second image in the image array.
        [data.image[0], data.image[1]] = [data.image[1], data.image[0]];
      }
      setData(data)
    }
    fetchMyAPI()
  }, [])

  const openImage = async (subImage) => {
    const data = {
      uri: subImage.uri, 
      name: subImage.name, 
      isSocialImage: subImage.isSocialImage, 
      deleteButton: true, 
      closeButton: true, 
      mainImageButton: true 
    }
    history.push({
      pathname: routes.SINGLE_IMAGE_SCREEN,
      state: data
    })
  }

  const handleChange = () => {
    const file = document.getElementById('fileItem').files[0];

    const data = {
      file: file,
      submitButton: true, 
      closeButton: true,
      rotateButton: true,
    }
    history.push({
      pathname: routes.SINGLE_IMAGE_SCREEN,
      state: data
    })
  }

  return (
    <div className="col-10 col-lg-6 mx-auto mt-4 screen">
      <div>
        <Snackbar
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          open={open}
          TransitionComponent={TransitionDown}
        >
          <Alert severity="error" sx={{ width: '100%' }}>
            You can only upload a maximum of 20 images.
          </Alert>
        </Snackbar>
      </div>
      {data && (
        <>
          <div className="row">
            {
              data.user_account_type_id !== 4 && data.image[0].name === sources.DUMMY_IMAGE.name ? (
                <div className="addImagesBox">
                  <Text>Please add one or more photos to complete the profile setup.</Text>
                </div>
              ) : (
                <div className="toAccountScreenBox">
                  <Button 
                    className="genericButton primaryColor" 
                    onClick={() => history.push(routes.ACCOUNT)}
                  >
                    Account
                  </Button>
                </div>
              )
            }
            <div>
              <h2>Cover Photo</h2>
              <div className="coverPhotoWrapper">
                <TouchableImage 
                  onClick={() => openImage(data.image[0])}
                  uri={data.image[0].uri}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="contentHeader photosHeader">
                <h2>Photos</h2>
            </div>
            <div className="subImagesContainer">
              <>
              <label className="inputSquare">
              <InputSquare icon={mdiPlus} title='Add photo' onClick={() => console.log('add image method')} color={colors.primary} />
                <input 
                    id="fileItem"
                    type="file" 
                    name="inputProfileImage" 
                    className="inputProfileImage d-none" 
                    onChange={handleChange}
                    accept=".tif,.tiff,.bmp,.jpg,.jpeg,.gif,.png"
                  />
              </label>
                
              </>
              {
                data.image.map(function (subImage, index) {
                  if (index !== 0) { // Skip the first image since it will be placed at the cover photo position.
                    let socialHidden = false
                    if (subImage.name === 'facebookImageHidden') { socialHidden = true }
                    return (
                      <TouchableImage 
                        onClick={() => openImage(subImage)}
                        uri={subImage.uri}
                        key={index} 
                        socialHidden={socialHidden}
                      />
                    )
                  }
                })
              }
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default ImagesScreen;