import React from 'react'
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { useHistory, useLocation } from "react-router-dom"

function Footer() {
  const history = useHistory();
  const location = useLocation();

  const isRootPage = location.pathname === '/';

  return (
    <Box className="footer">
      <Grid container>
        <Grid item lg={1}></Grid>
        <Grid item lg={2} xs={12}>
          <img 
            src="../images/logo.png"
            className="hippoLogo" 
            onClick={() => { history.push('/') }}
          />
        </Grid>
        <Grid item lg={6} xs={12}>
        </Grid>
        <Grid item lg={2} xs={12}>
          <ul style={{listStyleType: 'none', padding: 0}}>
            <li className={"hippoLink"} onClick={() => { history.push('/blog') }}>Blog</li>
            <li className={"hippoLink"} onClick={() => { window.location.href = '/hosts' }}>Hosts</li>
            <li className={"hippoLink"} onClick={() => { window.location.href = '/travellers' }}>Travellers</li>
            <li className={"hippoLink"} onClick={() => { history.push('/information-for-hippohosts') }}>Information For Hippohosts</li>
            <li className={"hippoLink"} onClick={() => { history.push('/information-for-hippohelpers') }}>Information For Hippohelpers</li>
            <li className={"hippoLink"} onClick={() => { history.push('/safety') }}>Safety</li>
            <li className={"hippoLink"} onClick={() => { history.push('/support') }}>Support</li>
            <li className={"hippoLink"} onClick={() => { history.push('/privacy-policy') }}>Privacy Policy</li>
            <li className={"hippoLink"} onClick={() => { history.push('/terms-and-conditions') }}>Terms And Conditions</li>
            <li className={"hippoLink"} onClick={() => { history.push('/attribution') }}>Attribution</li>
          </ul>
        </Grid>
        <Grid item lg={1}></Grid>
      </Grid>
      {isRootPage && (
        <a style={{
          textAlign: "center",
          display: "block",
          width: "100%",
          color: "black"
        }} className="hippoLink" target="_blank" href='https://dvu.se'>
          Made by DVU
        </a>
      )}
  </Box>
  )
}

export default Footer