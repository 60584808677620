import React from 'react'
import colors from '../../config/colors'
import Icon from '@mdi/react'

function InputSquare ({onClick = () => {}, icon, title, color = colors.medium}) {
  return (
    <div onClick={onClick}>
      <Icon path={icon} size={1.5} color={color} />
      <p className="mediumColor">{title}</p>
    </div>
  )
}

export default InputSquare
