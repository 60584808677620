import React from 'react'
import Box from '@mui/material/Box';
import { Typography } from '@material-ui/core';
import Divider from '../components/formatting/Divider';
import Footer from '../components/formatting/Footer';
import HTMLContent from '../variables/HTMLContent';
import { Helmet, HelmetProvider } from 'react-helmet-async';

function AttributionScreen() {
  return (
    <>   
      <HelmetProvider>
        <Helmet>
          <title>Hippohelp | Attribution </title>
          <meta name="description" content="Explore Hippohelp's Attribution." />
        </Helmet>
      </HelmetProvider>
      <Box className='contentBox'>
        <Typography component="h1" variant="h4">Attribution</Typography>
        <Divider />
        <div className='leftText'>
          <HTMLContent content="licenses" />
        </div>
      </Box>
      <Footer />
    </>
  )
}

export default AttributionScreen