import React, { useState, useEffect  } from 'react'
import useApi from '../hooks/useApi'
import imageApi from '../api/imageApi'
import userApi from '../api/userApi'
import Icon from '@mdi/react'
import { mdiEyeOutline, mdiEyeOffOutline, mdiTrashCanOutline, mdiArrowLeft, mdiRotateRight } from '@mdi/js'
import Button from '@material-ui/core/Button';
import colors from '../config/colors'
import { useHistory } from "react-router-dom"
import Resizer from "react-image-file-resizer";
import UploadScreen from './UploadScreen'
import ResizeScreen from './ResizeScreen'
import routes from '../navigation/routes'

function SingleImageScreen () {
  const history = useHistory();
  const data = history.location.state
  const file = data.file

  const uploadImageApi = useApi(imageApi.uploadImage)
  const deleteImageApi = useApi(imageApi.deleteImage)
  const hideSocialImageApi = useApi(userApi.hideSocialImage)
  const showSocialImageApi = useApi(userApi.showSocialImage)
  const setMainImageApi = useApi(imageApi.setMainImage)
  const setSocialMainImageApi = useApi(imageApi.setSocialMainImage)
  const [resizeVisible, setResizeVisible] = useState(false);
  const [uploadVisible, setUploadVisible] = useState(false);
  const [uri, setUri] = useState(false)
  const [progress, setProgress] = useState(0);
  const [rotation, setRotation] = useState(0);

  const rotateImage = function () {
    if (rotation === 270) {
      setRotation(0)
    } else {
      setRotation(rotation + 90); 
    }
  }

  const readFileAsync = function (file) {
    return new Promise((resolve, reject) => {
      let reader = new FileReader();
      reader.onload = () => { resolve(reader.result) };
      reader.onerror = reject;
      reader.readAsDataURL(file);
    })
  }

  const loadImageAsync = function (contentBuffer) {
    return new Promise((resolve, reject) => {
      const img = new Image;
      img.onload = () => { resolve(img) }
      img.onerror = reject;
      img.src = contentBuffer;
    })
  }

  const resizeFileAsync = (file, newWidth, newHeight) => (
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        newWidth,
        newHeight,
        "JPEG",
        100,
        0,
        (uri) => {
          resolve(uri);
        },
        "file"
      );
    })
  )

  const getImageDimensions = async (file) => {
    const contentBuffer = await readFileAsync(file);
    const image = await loadImageAsync(contentBuffer)
    return {height: image.height, width: image.width}
  }

  const uploadImage = async (file) => {
    let size = file.size
    let dimensions = await getImageDimensions(file)

    const maxSize = 1500000
    if (size > maxSize) {
      setResizeVisible(true)
      while (size > maxSize) {
        dimensions.height = dimensions.height * 0.8
        dimensions.width = dimensions.width * 0.8
        file = await resizeFileAsync(file, dimensions.width, dimensions.height)
        size = file.size
      }
    }

    // rotate the image based on the current rotation angle
    const rotatedFile = await new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        dimensions.width,
        dimensions.height,
        'JPEG',
        100,
        rotation,
        (uri) => {
          resolve(uri)
        },
        'file'
      )
    })

    setUploadVisible(true)
    const fetch = await uploadImageApi.request(rotatedFile);
    if (!fetch) { return }
    if (fetch?.publicType === "MaximumImagesError") {
      window.location = '/images?error=maximumImageError'
    } else {
      window.location = '/images'
    }
  }

  const deleteImage = async function () {
    const fetch = await deleteImageApi.request(data.name)
    if (!fetch) { return }
    // history.goBack()
    window.location.href = routes.IMAGES
  }

  const hideSocialImage = async function () {
    const fetch = await hideSocialImageApi.request()
    if (!fetch) { return }
    // history.goBack()
    window.location.href = routes.IMAGES
  }

  const showSocialImage = async function () {
    const fetch = await showSocialImageApi.request()
    if (!fetch) { return }
    // history.goBack()
    window.location.href = routes.IMAGES
  }

  const setMainImage = async function () {
    let fetch
    if (data.isSocialImage === true) {
      fetch = await setSocialMainImageApi.request()
    } else {
      fetch = await setMainImageApi.request(data.name)
    }
    if (!fetch) { return }
    // history.goBack()
    window.location.href = routes.IMAGES
  }

  useEffect(() => {
    const setUriFromUploadedFile = async function (file) {
      const contentBuffer = await readFileAsync(file);
      const image = await loadImageAsync(contentBuffer)
      setUri(image.src)
    }
    
    if (data.uri) {
      setUri(data.uri)
    } else {
      setUriFromUploadedFile(data.file)
    }
  }, [])

  let hiddenIcon
  let deleteButton
  if (data.isSocialImage === true) {
    if (data.name === 'facebookImageHidden') {
      deleteButton = (
        <div onClick={() => showSocialImage()}>
          <div>
            <Icon path={mdiEyeOutline} size={1.5} color={colors.dark} />
          </div>
        </div>
      )
      hiddenIcon = (
        <div>
          <Icon path={mdiEyeOffOutline} size={1.5} color={colors.light} />
        </div>
      )
    } else {
      deleteButton = (
        <div onClick={() => hideSocialImage()}>
          <div>
            <Icon path={mdiEyeOffOutline} size={1.5} color={colors.dark} />
          </div>
        </div>
        )
    }
  } else {
    deleteButton = (
      <div onClick={() => deleteImage()}>
        <div>
          <Icon path={mdiTrashCanOutline} size={1.5} color={colors.dark} />
        </div>
      </div>
      )
  }

  let buttonContainer
  if (data.name === 'dummyImage') {
    buttonContainer = (
      <div className="buttonContainer">
        {data.closeButton && (
          <div>
            <div onClick={() => // history.goBack()
            window.location.href = routes.IMAGES}>
              <Icon path={mdiArrowLeft} size={1.5} color={colors.dark} />
            </div>
          </div>
        )}
      </div>
    )
  } else {
    buttonContainer = (
      <div className="buttonContainer">
        {data.closeButton && (
          <div>
            <div onClick={() => // history.goBack()
            window.location.href = routes.IMAGES}>
              <Icon path={mdiArrowLeft} size={1.5} color={colors.dark} />
            </div>
          </div>
        )}
        {data.mainImageButton && (
          <Button variant="contained" className="genericButton primaryColor" onClick={() => setMainImage()}>Cover photo</Button>
        )}
        {data.deleteButton && (
          deleteButton
        )}
        {data.submitButton && (
          <Button variant="contained" className="genericButton primaryColor" onClick={() => uploadImage(file)}>Submit</Button>
        )}
      </div>
    )
  }
  
  return (
    <div className="col-10 col-lg-6 mx-auto mt-4 fullwidthCol">
      {uri && (
        <div className="singleImageContainer">
          <ResizeScreen open={resizeVisible} />
          <UploadScreen 
            open={uploadVisible} 
            progress={progress} 
            onComplete={ () => window.location.href = routes.IMAGES }
          /> 
          {data.rotateButton && (
            <div onClick={() => rotateImage()} className="iconWrapper rotateImageButton">
              <Icon spin={false} path={mdiRotateRight} size={1.2} color={colors.white} />
            </div>
          )}
          {buttonContainer}
          {hiddenIcon && (
            <div className="hiddenIconContainer">
              {hiddenIcon && (
                hiddenIcon
              )}
              <p className="lightColor">(Hidden)</p>
            </div>
          )}
          {
          (rotation === 0 || rotation === 180) ?
            (
              <img className={'singleImage'} src={uri} style={{ transform: `rotate(${rotation}deg)` }} />
            ) 
            : 
            (
              <img className={'singleImage wideImage'} src={uri} style={{ transform: `rotate(${rotation}deg)` }} />
            )
          }
          
        </div>
      )}
    </div>
  )
}

export default SingleImageScreen
