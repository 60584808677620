import React, { useState, useEffect } from 'react'

import routes from '../navigation/routes'
import { Util } from '../utils/util'
import useApi from '../hooks/useApi'
import userApi from '../api/userApi'
import { useHistory } from "react-router-dom"
import ConversationItem from '../components/lists/ConversationItem';

function ConversationsScreen () {
  const getConversationsApi = useApi(userApi.getConversations)
  const [conversations, setConversations] = useState(false)
  const history = useHistory();
  const [error, setError] = useState(false)

  useEffect(() => {
    async function fetchMyAPI() {
      const fetch = await getConversationsApi.request()
      if (!fetch) { return }

      const data = fetch.data.conversations
      setConversations(data)
    }
    fetchMyAPI()
  }, [])

  const handleConversationClick = async (userData) => {
    const nextRoute = routes.CONVERSATION + '/' + userData.id
    history.push(nextRoute)
  }

  return (
    <div className="col-10 col-lg-6 mx-auto mt-4 screen">
      {error && ( <p>{error}</p> )}
      {conversations && conversations.length === 0 && ( <p>No conversations here!</p> )}
      {conversations && conversations.length !== 0 && (
        <>
          <div className="row mb-4">
            <div className="contentHeader">
              <h1>Conversations</h1>
            </div>
          </div>
          <div className="row mb-4">
            {
              conversations.map(function (conversationItem, index) {
                return (
                  <ConversationItem
                    title={conversationItem.listTitle}
                    message={conversationItem.message}
                    imageSrc={ Util.getFirstImageSource(conversationItem.otherUserData.image) }
                    onClick={() => handleConversationClick(conversationItem.otherUserData)}
                  />
                )
              })
            }
          </div>
        </>
      )}
    </div>
  )
}

export default ConversationsScreen
