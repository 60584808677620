import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom"
import useApi from '../hooks/useApi'
import userApi from '../api/userApi';
import markerApi from '../api/markerApi';
import routes from '../navigation/routes';
import Form from '../forms/Form'
import TextInputField from '../forms/TextInputField'
import SubmitButton from '../forms/SubmitButton';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import countryItems from '../variables/countryItemsWithoutAllCountries';
import workTypeItems from '../variables/workTypeItems'
import MultiSelectInputField from '../forms/MultiSelectInputField';
import { Util } from '../utils/util';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Spinner from '../components/other/Spinner';
import { v4 as uuidv4 } from 'uuid';
import { mdiMinus} from '@mdi/js'
import Icon from '@mdi/react'
import colors from '../config/colors';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import sources from '../config/sources';

function TravelUserDetailsScreen() {
  const history = useHistory();
  const userData = history.location.state.userData
  const coordinates = history.location.state.coordinates
  const [accountTypeID, setAccountTypeID] = useState(false)
  const [fieldValues, setFieldValues] = useState(false)
  const saveTravelUserDetailsApi = useApi(userApi.saveTravelUserDetails)
  const saveTravelMarkerDetailsApi = useApi(markerApi.saveTravelMarkerDetails)
  const resetUserAndMarkerDataApi = useApi(userApi.resetUserAndMarkerData)
  const [selectedCountryItem, setSelectedCountryItem] = useState(false)
  const [selectedWorkTypeItems, setSelectedWorkTypeItems] = useState([])
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [linkFields, setLinkFields] = useState([])

  const handleClose = () => {
    setOpen(false);
  };

  const handleAddLink = () => {
    if (linkFields.length < 3) {
      setLinkFields(prevLinkFields => [...prevLinkFields, { id: uuidv4(), value: "" }]);
    }
  };

  const handleRemoveLink = (id) => {
    setLinkFields(prevLinkFields => prevLinkFields.filter(linkField => linkField.id !== id));
  };


  const accountTypeSelections = [
    {id: 1, placeholder: "Individual"},
    {id: 2, placeholder: "Couple"}
  ]

  useEffect(() => {    
    if ((userData.user_account_type_id === 1 || userData.user_account_type_id === 2) && userData?.position?.positions_id) {
      const initialLinks = userData.link.map((link) => ({ id: uuidv4(), value: link.link }));
      const valuesToSet = {}
      valuesToSet.profileDescription = userData.profile_description
      valuesToSet.travellingDestinations = userData.location.traveling_location
      valuesToSet.skills = userData.skill.skill
      valuesToSet.languages = userData.language.language
      valuesToSet.age = userData.age
      valuesToSet.nationality = userData.nationality
      valuesToSet.driversLicense = userData.drivers_license
      valuesToSet.allergies = userData.allergy.allergy
      valuesToSet.dietRequirements = userData.diet.user_diet_requirement
      for (let i = 0; i < initialLinks.length; i++) {
        valuesToSet[`link_${initialLinks[i].id}`] = initialLinks[i].value
      }
      valuesToSet.whatsapp = userData.whatsapp

      if (userData.user_account_type_id !== 4) {
        valuesToSet.realName = userData.username
      }

      if (userData.additional_info) { 
        valuesToSet.additionalInfo = userData.additional_info 
      }

      setLinkFields(initialLinks);
      setFieldValues(valuesToSet)
      setAccountTypeID(userData.user_account_type_id)
      setSelectedCountryItem(userData.position.positions_id)
      setSelectedWorkTypeItems(userData.help.help_type.arrayFormat)
    } else {
      setFieldValues({})
    }
  }, [])

  const handleSubmit = async (submitData) => {
    const emptyFields = []
    if (accountTypeID !== 1 && accountTypeID !== 2) { emptyFields.push('Account type') }
    if (!selectedCountryItem) { emptyFields.push('Current country') }
    if (selectedWorkTypeItems.length === 0 || typeof selectedWorkTypeItems[0] === 'undefined') { emptyFields.push('Tasks willing to do') }
    if (!submitData.profileDescription) { emptyFields.push('Profile description') }
    if (!submitData.realName) { emptyFields.push('Full Name (First and Last)') }
    if (!submitData.travellingDestinations) { emptyFields.push('Planned travelling destinations') }
    if (!submitData.skills) { emptyFields.push('Skills') }
    if (!submitData.languages) { emptyFields.push('Languages') }
    if (!submitData.age) { emptyFields.push('Age') }
    if (!submitData.nationality) { emptyFields.push('Nationality') }
    if (!submitData.driversLicense) { emptyFields.push('Drivers license') }
    if (!submitData.allergies) { emptyFields.push('Allergies') }
    if (!submitData.dietRequirements) { emptyFields.push('Diet requirements') }

    if (submitData.whatsapp) {
      if (!Util.checkIfOnlyDigits(submitData.whatsapp)) {
        return alert("Whats app number can only contain digits!")
      }
    }

    if (Util.checkEmptyFields(emptyFields)) {
      setLoading(true);
      const resetUserAndMarkerDataApiResult = await resetUserAndMarkerDataApi.request()
      if (!resetUserAndMarkerDataApiResult) { return }

      const saveData = {
        profileDescription : submitData.profileDescription,
        realName: submitData.realName,
        accountTypeID : accountTypeID,
        age : submitData.age,
        nationality : submitData.nationality,
        driversLicense : submitData.driversLicense,
        additionalInfo : submitData.additionalInfo,
        dietRequirements : submitData.dietRequirements,
        positionID : selectedCountryItem,
        location : submitData.travellingDestinations,
        helpType : Util.formatWorkTypeIdentifiersToString(selectedWorkTypeItems),
        skill : submitData.skills,
        allergy : submitData.allergies,
        language : submitData.languages,
        whatsapp: submitData.whatsapp
      }
      const links = linkFields.map(linkField => submitData[`link_${linkField.id}`]).filter(link => link);
      saveData.links = links;

      const detailsResult = await saveTravelUserDetailsApi.request(saveData)
      if (!detailsResult) { 
        alert('Error saving data (user details)')
        return 
      }

      // If new coordinates exist we need to use them for saving a marker
      if (coordinates) {
        const markerResult = await saveTravelMarkerDetailsApi.request({
          lat: coordinates.lat,
          lng: coordinates.lng
        })
        if (!markerResult) { 
          alert('Error saving data (travelmarker details)')
          return 
        }
      }
      
      const imageSource = Util.getFirstImageSource(userData.image)
      if (Util.getImageName(imageSource) === Util.getImageName(sources.DUMMY_IMAGE.uri)) {
        history.push(routes.IMAGES)
      } else {
        history.push(routes.ACCOUNT)
      }
    }
  }

  const onMultiSelectChange = (value) => {
    setSelectedWorkTypeItems(Util.convertWorkTypeNamesToIDs(Array.from(value)))
  }

  return (
    <div className="col-10 col-lg-6 mx-auto mt-4 screen">
          <Spinner visible={loading} />
          {
            !loading && (
              <>
                <div className="contentHeader mb-4">
                  <h1>Set details</h1>
                </div>
                {fieldValues && (
                  <>
                    <div className="selectInput">
                      <FormControl fullWidth>
                        <InputLabel className="customFormControlLabel" id="select-label1">Individual or Couple?</InputLabel>
                        <Select
                          className="customFormControlPlaceholder"
                          labelId="select-label1"
                          id="select-label1"
                          value={accountTypeID}
                          onChange={(event) => {setAccountTypeID(event.target.value)}}
                        >
                          <MenuItem value={accountTypeSelections[0].id}>{accountTypeSelections[0].placeholder}</MenuItem>
                          <MenuItem value={accountTypeSelections[1].id}>{accountTypeSelections[1].placeholder}</MenuItem>
                        </Select>
                      </FormControl>
                    </div>
                    <div className="selectInput">
                      <FormControl fullWidth>
                        <InputLabel className="customFormControlLabel" id="select-label2">Country</InputLabel>
                        <Select
                          className="customFormControlPlaceholder"
                          labelId="select-label2"
                          id="select-label2"
                          value={selectedCountryItem}
                          onChange={(event) => {setSelectedCountryItem(event.target.value)}}
                        >
                          {countryItems.children.map((countryItem, index) => (
                            <MenuItem key={index} value={countryItem.id}>
                              {countryItem.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </div>
                    <div className="selectInput" onClick={() => { if (!open) { setOpen(true) }}}>
                      <MultiSelectInputField identifier='set-details-multiple-checkbox' selections={workTypeItems} label="Tasks willing to do" onChange={(value) => onMultiSelectChange(value)} selectedItems={selectedWorkTypeItems} open={open} handleClose={handleClose} />
                    </div>
                    <Form onSubmit={handleSubmit} initialValues={fieldValues}>
                      <div className="textBox">
                        <TextInputField name='realName' label='Full Name (First and Last)' multiline inputProps={{ maxLength: 50 }} />
                      </div>
                      <div className="textBox">
                        <TextInputField name='profileDescription' label='Profile description' multiline inputProps={{ maxLength: 5000 }} />
                      </div>
                      <div className="textBox">
                        <TextInputField name='travellingDestinations' label='Planned travelling destinations' multiline inputProps={{ maxLength: 5000 }} />
                      </div>
                      <div className="textBox">
                        <TextInputField name='skills' label='Skills' multiline inputProps={{ maxLength: 5000 }} />
                      </div>
                      <div className="textBox">
                        <TextInputField name='languages' label='Languages' multiline inputProps={{ maxLength: 255 }} />
                      </div>
                      <div className="textBox">
                        <TextInputField name='age' label='Age' />
                      </div>
                      <div className="textBox">
                        <TextInputField name='nationality' label='Nationality' inputProps={{ maxLength: 255 }} />
                      </div>
                      <div className="textBox">
                        <TextInputField name='driversLicense' label='Drivers license' multiline inputProps={{ maxLength: 5000 }} />
                      </div>
                      <div className="textBox">
                        <TextInputField name='allergies' label='Allergies' multiline inputProps={{ maxLength: 5000 }} />
                      </div>
                      <div className="textBox">
                        <TextInputField name='dietRequirements' label='Diet requirements' multiline inputProps={{ maxLength: 5000 }}/>
                      </div>
                      <div className="textBox">
                        <TextInputField name='additionalInfo' label='Additional info' multiline inputProps={{ maxLength: 5000 }} />
                      </div>
                      <div>
                        <div className="contentHeader mb-4 mt-4">
                          <h1>Optional links</h1>
                        </div>
                        <p>Enhance your profile's trustworthiness by adding your social media and website links below. Note: these will only be visible to registered users.</p>
                        {linkFields.map((linkField) => (
                          <div key={linkField.id} className="textBox">
                            <TextInputField 
                              name={`link_${linkField.id}`}
                              label={`Link`}
                              type="text"
                              defaultValue={linkField.value}
                              inputProps={{ maxLength: 255 }}
                            />
                            <div style={{marginLeft: 20}} onClick={() => handleRemoveLink(linkField.id)} className="iconWrapper iconButton secondaryColorIcon">
                              <Icon path={mdiMinus} size={1} color={colors.white} />
                            </div>
                          </div>
                        ))}
                        <Button
                          onClick={handleAddLink} 
                          disabled={linkFields.length >= 3} 
                          variant="contained" 
                          endIcon={<AddIcon />}
                          className={"genericButton secondaryColor mb-2"}
                        >
                          Add link
                        </Button>
                      </div>
                      <div>
                        <div className="contentHeader mb-4 mt-4">
                          <h1>Optional WhatsApp</h1>
                        </div>
                        <p>Make it easier for other users to contact you by adding your WhatsApp info. Note: this will only be visible to registered users.</p>

                        <p>Enter a full phone number in international format. Omit any zeroes, brackets, or dashes when adding the phone number in international format.</p>
                        <p>Examples:</p>
                        <p>Use: 12345678910</p>
                        <p>Don't use: +001-(234)5678910</p>
                        <div className="textBox">
                          <TextInputField 
                            name={`whatsapp`}
                            label={`WhatsApp`}
                            type="text"
                            inputProps={{ maxLength: 255 }}
                          />
                        </div>
                        <div className="mb-4">
                          <SubmitButton />
                        </div>
                      </div>
                    </Form>
                  </>
                )}
              </>
            )
          }
        </div>
  );
}

export default TravelUserDetailsScreen;