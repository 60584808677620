import React, {useState, useEffect, useRef} from 'react'
import useApi from '../hooks/useApi'
import userApi from '../api/userApi';
import Button from '@material-ui/core/Button';
import { Util } from '../utils/util';
import { useHistory } from "react-router-dom"
import markerApi from "../api/markerApi";

function ConversationScreen(props) {
  const history = useHistory();
  const [title, setTitle] = useState(false)
  const [nextPage, setNextPage] = useState(false)
  const getHostMarkerDetailsAnyApprovalApi = useApi(markerApi.getHostMarkerDetailsAnyApproval)
  const getBlockStatusApi = useApi(userApi.getBlockStatus)
  const blockUserApi = useApi(userApi.blockUser)
  const unblockUserApi = useApi(userApi.unblockUser)
  const [action, setAction] = useState(false)

  const fetchMyAPI = async () => {
    const blockStatusFetch = await getBlockStatusApi.request(props.match.params.id)
    if (!blockStatusFetch) { return }
    
    const nextPage = await Util.getDetailsRoute(blockStatusFetch.data.userData, getHostMarkerDetailsAnyApprovalApi)
    if (!nextPage) { return }

    if (blockStatusFetch.data.blockedByAuth === "Blocked") {
      setAction("unblock")  
    } else {
      setAction("block")
    }

    setNextPage(nextPage)
    setTitle(Util.generateTitle(blockStatusFetch.data.userData))
  }

  const blockUser = async () => {
    const blockUserApiFetch = await blockUserApi.request(props.match.params.id)
    if (!blockUserApiFetch) { return }
    alert('User blocked!')
    history.push(nextPage)
  }

  const unBlockUser = async () => {
    const unblockUserApiFetch = await unblockUserApi.request(props.match.params.id)
    if (!unblockUserApiFetch) { return }
    alert('User unblocked!')
    history.push(nextPage)
  }

  useEffect(() => {
    fetchMyAPI()
  }, [])

  return (
    <div className="col-10 col-lg-6 mx-auto mt-4 screen">
      {action && (
        <>
          <div className="row mb-4">
            <div className="contentHeader">
            <h1>{title}</h1>
              <Button className={"genericButton"} onClick={() => history.push(nextPage)}>Profile</Button>
            </div>
          </div>
          {action === "block" && (
            <Button className={"genericButton"} onClick={() => blockUser()}>Block user</Button>
          )}
          {action === "unblock" && (
            <Button className={"genericButton"} onClick={() => unBlockUser()}>Unblock user</Button>
          )}
        </>
      )}
    </div>
  );
}

export default ConversationScreen;