import React from 'react'
import Grid from '@mui/material/Grid';

function Divider() {
  return (
    <Grid container spacing={2}>
      <Grid item xs={5}></Grid>
      <Grid item xs={2}>
        <hr />
      </Grid>
      <Grid item xs={5}></Grid>
    </Grid>
  )
}

export default Divider