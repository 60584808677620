import React from "react"
import CssLoader from './config/CssLoader'
import NavBar from "./components/other/NavBar";
import NavBarLoggedOut from "./components/other/NavBarLoggedOut";
import AppNavigator from "./navigation/AppNavigator";
import { useAuth0 } from "@auth0/auth0-react";
import { useLocation } from 'react-router-dom'

export default function App() {
  const { isAuthenticated, isLoading } = useAuth0();

  const location = useLocation()

  const getOuterCssClass = function () {
    if (location.pathname === '/map' || location.pathname === '/set-travel-marker' || location.pathname === '/set-host-marker' ) {
      return ' desktopFullWidthHeight'
    } else {
      return ' desktopUnsetWidthHeight'
    }
  }

  const getInnerClass = function () {
    if (isAuthenticated) {
      return ' fullHeightMobile'
    } else {
      return ''
    }
  }

  return (
    <>
      <CssLoader />
      {!isLoading && (
        <div className={'appScreenOuter' + getOuterCssClass()}>
          <div className={'appScreenInner' + getInnerClass()}>
            <>
              {isAuthenticated && (<NavBar />) }
              {!isAuthenticated && (<NavBarLoggedOut />) }
              <AppNavigator />
            </>
          </div>
        </div>
      )}
    </>
  );
}

