import React from 'react'
import ChevronRight from '@material-ui/icons/ChevronRight';
import Text from '../other/Text'
import clsx from 'clsx'

function MenuItem ({ 
  title, 
  source, 
  onClick,
}) {
  return (
      <div className={"menuItemContainer menuItemDetailsContainer"} onClick={onClick}>
        <div className={"menuImageDetailsContainer"} style={{backgroundImage: "url(" + source + ")"}}>
          {/* <img className={"menuImageDetails"} src={source} /> */}
        </div>
        <div className={"menuDetailsContainer"}>
          <Text>{title}</Text>
          <Text>Set Profile</Text>
        </div>
        <ChevronRight fontSize="large" className={"chevronRight"} />
      </div>
  )
}

export default MenuItem
