import React from 'react'
import ErrorMessage from './ErrorMessage'

import { useFormikContext } from 'formik'
import TextField from '@material-ui/core/TextField';

function FormField ({ 
    withErrorMessage = true,
    name,
    label,
    icon,
    multiline,
    rows,
    type = "text",
    inputProps
  }) {
  const { setFieldValue, errors, touched, values } = useFormikContext()

  return (
    <>
      <div className="textInput">
        <span className="inputIcon">{icon && icon }</span>
        <TextField 
          InputLabelProps={{
            shrink: true,
          }}
          fullWidth
          id="standard-basic"
          variant="standard"
          label={label}
          onChange={event => setFieldValue(name, event.target.value)}
          value={values[name]}
          multiline={multiline}
          rows={rows}
          type={type}
          inputProps={inputProps}
        />
      </div>
      {withErrorMessage && (
        <ErrorMessage error={errors[name]} visible={touched[name]} />
      )}
    </>
  )
}

export default FormField
