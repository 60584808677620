import React, { useEffect, useState, useCallback } from 'react'
import { GoogleMap, useJsApiLoader, MarkerClusterer, Marker } from '@react-google-maps/api';
import MapCard from './MapCard';
import { useHistory } from "react-router-dom"
import routes from '../../navigation/routes';
import useApi from '../../hooks/useApi';
import markerApi from '../../api/markerApi';
import userApi from '../../api/userApi';
import mapConfig from '../../config/map'
import { Util } from '../../utils/util';
import SearchBox from '../other/SearchBox';
import { useAuth0 } from "@auth0/auth0-react";
import AdminDashboardBox from '../other/AdminDashboardBox'
import AdminDetailsBox from './AdminDetailsBox';
import AsyncSearch from './AsyncSearch';
import AsyncSearchBox from './AsyncSearchResultBox';
import adminApi from '../../api/adminApi';
import sources from '../../config/sources';
import { Toast } from '../toasts/Toast';

// Folder must contain m1.png, m2.png, m3.png, m4.png, m5.png and m6.png.
const clusterOptions = { imagePath: 'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m' }

function MyComponent() {
  const {isLoaded, loadError} = useJsApiLoader({ 
    id: 'google-map-script', 
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_KEY,
    libraries: mapConfig.libraries
  })

  const history = useHistory();
  const hostMarkerApi = useApi(markerApi.getHostMarkerDetailsBySlug)
  const hostMarkerAdminApi = useApi(adminApi.getHostMarkerDetailsBySlugAdmin)
  const getHostMarkerWithoutReferenceApi = useApi(adminApi.getHostMarkerWithoutReference)
  const travelUserApi = useApi(userApi.getTravelUserDetailsBySlug)
  const travelUserAdminApi = useApi(adminApi.getTravelUserDetailsBySlugAdmin)
  const unsetUserApi = useApi(userApi.getUnsetUserDetailsBySlug)
  const checkForUnreadMessagesApi = useApi(userApi.checkForUnreadMessages)
  const updateLoginCountApi = useApi(userApi.updateLoginCount)
  const [data, setData] = useState(false)
  const [map, setMap] = useState(null)
  const onLoad = useCallback(function callback(map) { setMap(map) }, [])
  const onUnmount = useCallback(function callback(map) { setMap(null) }, [])
  const [selectedMarker, setSelectedMarker] = useState(false)
  const [selectedRoute, setSelectedRoute] = useState(false)
  const [center, setCenter] = useState(Util.getInitialPosition())
  const [error, setError] = useState(false)
  const { user } = useAuth0();
  const [controlPosition, setControlPosition] = useState(false)
  const [searchResults, setSearchResults] = useState([])
  const [adminDetailsBoxData, setAdminDetailsBoxData] = useState(false)
  const [adminSearchVisible, setAdminSearchVisible] = useState(false)
  const [userSearchVisible, setUserSearchVisible] = useState(true)
  const [open, setOpen] = useState(false);
  const [newLatLng, setNewLatLng] = useState(false)

  const currentYear = new Date().getFullYear()

  const zoomedIn = 9
  
  if (history?.location?.state?.latLng && map) {
    setSelectedMarker(false)
    setSelectedRoute(false)
    const latLng = history.location.state.latLng
    map.panTo(latLng)
    map.setZoom(zoomedIn)
    // Remove the state from the history object
    history.replace({ ...history.location, state: undefined });
  }

  let getListingsApi
  if (user.email === sources.ADMIN_EMAIL || user.email === sources.MINI_ADMIN_EMAIL) {
    getListingsApi = useApi(adminApi.getAllMarkers)
  } else {
    getListingsApi = useApi(markerApi.getAllMarkers)
  }

  const handleMapClick = (event) => {
    // setSearchResults([])
    setSelectedMarker(false)
    setSelectedRoute(false)

    if (user.email === sources.ADMIN_EMAIL || user.email === sources.MINI_ADMIN_EMAIL) {
      setNewLatLng(event.latLng.toJSON())
    }
  }

  const toggleAdminSearch = () => {
    if (adminSearchVisible) {
      setAdminSearchVisible(false)
    } else {
      setAdminSearchVisible(true)
    }
  }

  const handleMarkerClick = async (event, mapMarkerData) => {
    setSelectedMarker(false)
    setSelectedRoute(false)
    let fetch
    
    if (mapMarkerData.is_travel_event === 0) {
      if (user.email === sources.ADMIN_EMAIL || user.email === sources.MINI_ADMIN_EMAIL) {
        fetch = await hostMarkerAdminApi.request(mapMarkerData.slug)
      } else {
        fetch = await hostMarkerApi.request(mapMarkerData.slug)
      }
      if (!fetch) { return }
      fetch.data.images = Util.getImageArray(fetch.data.owner.image)
      setSelectedRoute(routes.HOST_MARKER_DETAILS + '/' + mapMarkerData.slug)
    } else {
      if (user.email === sources.ADMIN_EMAIL || user.email === sources.MINI_ADMIN_EMAIL) {
        fetch = await travelUserAdminApi.request(mapMarkerData.slug)
      } else {
        fetch = await travelUserApi.request(mapMarkerData.slug)
      }
      if (!fetch) { return }
      fetch.data.name = fetch.data.username
      fetch.data.images = Util.getImageArray(fetch.data.image)
      setSelectedRoute(routes.TRAVEL_USER_DETAILS + '/' + mapMarkerData.slug)
    }
    
    map.panTo(event.latLng)
    setSelectedMarker(fetch.data)
    setAdminDetailsBoxData(fetch.data)
  }

  const handlePlacesClick = (latLng) => {
    map.setZoom(zoomedIn)
    map.panTo(latLng)
  }

  const handleMapCardClick = () => {
    setSelectedMarker(false)
    history.push(selectedRoute)
  }

  const openSnackbar = () => {
    setOpen(true);
    setTimeout(() => {
      setOpen(false);
    }, 15000);
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const checkForUnreadMessages = async () => {
    const fetch = await checkForUnreadMessagesApi.request()
    if (!fetch) { return }
    if (fetch.data.hasUnreadMessages) {
      openSnackbar();
    }
  }

  const fetchAdminData = async () => {
    const getHostMarkerWithoutReference = await getHostMarkerWithoutReferenceApi.request()
    if (!getHostMarkerWithoutReference) { return }

    setAdminDetailsBoxData(getHostMarkerWithoutReference.data)
    if (map) {
      map.panTo({

        lat: parseFloat(getHostMarkerWithoutReference.data.latitude), 
        lng: parseFloat(getHostMarkerWithoutReference.data.longitude)

    })
      map.setZoom(zoomedIn)
    }
  }

  useEffect(() => {
    async function fetchMyAPI() {
      const fetch = await getListingsApi.request() // Data for all markers on the map.
      if (!fetch) { return }
      setData(fetch.data)
    }
    if (isLoaded) {
      if (user.email === sources.ADMIN_EMAIL || user.email === sources.MINI_ADMIN_EMAIL) {
        fetchAdminData()

        // eslint-disable-next-line no-undef
        setControlPosition(google.maps.ControlPosition.RIGHT_TOP)
        setAdminDetailsBoxData()
      } else {
        // eslint-disable-next-line no-undef
        setControlPosition(google.maps.ControlPosition.LEFT_BOTTOM)
      }
    }
    checkForUnreadMessages()
    fetchMyAPI()
    updateLoginCountApi.request()
  }, [isLoaded, map])

  const handleSearchClick = async function (searchItem) {
    let fetch
    if (searchItem.host_id) {
      fetch = await hostMarkerApi.request(searchItem.slug)
      if (!fetch) { return }
      handleMarkerClick({latLng: {lat: parseFloat(fetch.data.latitude), lng: parseFloat(fetch.data.longitude)}}, fetch.data) 
      map.setZoom(zoomedIn)
    } else if (searchItem.user_account_type_id === 1 || searchItem.user_account_type_id === 2) {
      fetch = await travelUserApi.request(searchItem.slug)
      if (!fetch) { return }
      
      if (fetch.data.marker) {
        handleMarkerClick({latLng: {lat: parseFloat(fetch.data.marker.latitude), lng: parseFloat(fetch.data.marker.longitude)}}, fetch.data) 
        map.setZoom(zoomedIn)
      }
    } else {
      fetch = await unsetUserApi.request(searchItem.slug)
      if (!fetch) { return }
    }
    
    setAdminDetailsBoxData(fetch.data)
  }



  const calculateHeight = function () {
    const actualHeight = window.innerHeight;

    if (document.querySelector('#control-height')) {
      const elementHeight = document.querySelector('#control-height').clientHeight;
    
      const barHeight = elementHeight - actualHeight;

      return barHeight * 2 + 400
    } else {
      return 400
    }
  }

  if (map) {
    const thePanorama = map.getStreetView();
    // eslint-disable-next-line no-undef
    google.maps.event.addListener(thePanorama, 'visible_changed', function() {
      if (thePanorama.getVisible()) {
        setUserSearchVisible(false)
      } else {
        setUserSearchVisible(true)
      }
    });
  }


  return (
    <>
        <div>
          <Toast 
            open={open} 
            message={"You have unread message(s)!"}
            severity={"info"}
            handleClose={handleClose}
          />
        </div>
        {error && ( <p>{error}</p> )}
        {loadError && (<p>Apoligies. Map could not be loaded.</p>)}
        {data && isLoaded && controlPosition && (
        <>
          {(user.email === sources.ADMIN_EMAIL) && (
            <>
              <AdminDashboardBox 
                onMarkerClick={(event, mapMarkerData) => { 
                  handleMarkerClick(event, mapMarkerData) 
                  map.setZoom(zoomedIn)
                }}
                toggleAdminSearch={() => toggleAdminSearch()}
              />
              {adminSearchVisible && (
                <div className="selectInput adminSearch">
                  <AsyncSearch onSearchDone={(results) => setSearchResults(results)} />
                  <AsyncSearchBox 
                    searchResults={searchResults} 
                    onClear={() => setSearchResults([])} 
                    onSearchClick={(searchItem) => { handleSearchClick(searchItem) }} />
                </div>
              )}
            </>
          )}
          {user.email !== sources.ADMIN_EMAIL && user.email !== sources.MINI_ADMIN_EMAIL && userSearchVisible && (
            <div className="searchBox">
              <SearchBox onPlacesClick={handlePlacesClick} />
            </div>
          )}
          <GoogleMap
            mapContainerStyle={mapConfig.containerStyle}
            onLoad={onLoad}
            onUnmount={onUnmount}
            zoom={4}
            center={center}
            onClick={(event) => {
              window.scrollTo(0, 0),
              calculateHeight(),
              handleMapClick(event)
            }}
            options={{
              ...mapConfig.mapOptions,
              mapTypeControlOptions: {
                mapTypeIds: ['roadmap', 'satellite', 'hybrid', 'terrain', 'styled_map'],
                // position: controlPosition
                // eslint-disable-next-line no-undef
                position: google.maps.ControlPosition.RIGHT_BOTTOM
              },
              streetViewControlOptions: {
                // eslint-disable-next-line no-undef
                position: google.maps.ControlPosition.LEFT_BOTTOM
              },
              scaleControlOptions: {
                // eslint-disable-next-line no-undef
                position: google.maps.ControlPosition.RIGHT_BOTTOM
              }
            }}
          >
          <MarkerClusterer options={clusterOptions}>
            {(clusterer) => (
                data.map(function (mapMarkerData, index) {
                  let icon


                  if (mapMarkerData.is_travel_event === 0) {
                    if (currentYear - mapMarkerData.updated_at.slice(0,4) > 1) {
                      icon = '/images/host-marker-gray-small.png'
                    } else {
                      icon = '/images/host-marker.png'
                    }
                  } else {
                    icon = '/images/travel-marker.png'
                  }

                  return <Marker
                    key={index}
                    position={{
                      lat: parseFloat(mapMarkerData.latitude),
                      lng: parseFloat(mapMarkerData.longitude)
                    }}
                    icon={icon}
                    onClick={(event) => { handleMarkerClick(event, mapMarkerData) }}
                    clusterer={clusterer}
                  />
                })
              )
            }
          </MarkerClusterer>
          {
            newLatLng && (
              <Marker
                position={{lat: newLatLng.lat, lng: newLatLng.lng}}
                draggable
                onDrag={() => {
                  setSelectedMarker(false)
                  setSelectedRoute(false)
                }}
                onDragEnd={e => {
                  setNewLatLng(e.latLng.toJSON())
                }}
              />
            )
          }
          </GoogleMap>
          {(user.email === sources.ADMIN_EMAIL || user.email === sources.MINI_ADMIN_EMAIL) && (
            <AdminDetailsBox 
              data={adminDetailsBoxData} 
              newLatLng={newLatLng} 
              adminEmail={user.email}
            />
          )}
        </>
        )}
        {selectedMarker && (
          <MapCard
            title={selectedMarker.name}
            images={selectedMarker.images}
            updatedAt={selectedMarker.updated_at}
            onClick={handleMapCardClick}
            onCloseCard={() => {
              setSelectedMarker(false)
              setSelectedRoute(false)
            }}
          />
        )}
    </>
  )
}

export default MyComponent