import React, { useState, useEffect } from 'react'
import Box from '@mui/material/Box';
import { useHistory } from "react-router-dom"

const getCategoryLevelsAbove = (currentCategory) => {
  const path = []

  path.push({
    url: "/blog",
    name: "Blog"
  })

  if (currentCategory.category_slug) {
    path.push({
      url: `/blog/category/${currentCategory.category_slug}`,
      name: currentCategory.category_name
    })
  }

  if (currentCategory.sub_category_slug) {
    path.push({
      url: `/blog/category/${currentCategory.category_slug}/${currentCategory.sub_category_slug}`,
      name: currentCategory.sub_category_name
    })
  }

  if (currentCategory.sub_sub_category_slug) {
    path.push({
      url: `/blog/category/${currentCategory.category_slug}/${currentCategory.sub_category_slug}/${currentCategory.sub_sub_category_slug}`,
      name: currentCategory.sub_sub_category_name
    })
  }

  return path
}


function CategoryPath({
  currentCategory,
}) {
  const history = useHistory();
  const levels = getCategoryLevelsAbove(currentCategory)

  return (
    <>
      <p style={{textAlign: "left", margin: 0, marginBottom: 0}}>You are here:</p>
      <Box className='breadcrumb'>
        {
          levels.map((level, index) => {
            return (
              <React.Fragment key={index}>
                <p className={"hippoLink"} onClick={() => { history.push(level.url) }}>{level.name}</p>
                {index < levels.length - 1 && (
                  <span style={{marginLeft: 5, marginRight: 5}}>{">"}</span>
                )}      
              </React.Fragment>
            )
          })
        }
      </Box>
    </>
  )
}

export default CategoryPath