import React from 'react'
import { Formik } from 'formik'
import FormControl from '@material-ui/core/FormControl';

function Form ({ initialValues, onSubmit, validationSchema, children }) {
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      enableReinitialize
    >
      { () => (
        <FormControl fullWidth>
          {children}
        </FormControl>
      ) }
    </Formik>
  )
}

export default Form
