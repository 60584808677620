import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { BrowserRouter as Router} from 'react-router-dom'
import 'bootstrap/dist/css/bootstrap.min.css'
import $ from 'jquery';
import Popper from 'popper.js';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import "@fontsource/plus-jakarta-sans"; // Defaults to weight 400.
import { MuiThemeProvider, createTheme } from '@material-ui/core/styles';
import { Auth0Provider } from "@auth0/auth0-react";
import {ErrorBoundary} from 'react-error-boundary'
import CssLoader from './config/CssLoader'
import ErrorScreen from './screens/ErrorScreen'

// use default theme
const theme = createTheme();

function ErrorHandler() {
  return (
    <>
      <CssLoader />
      <ErrorScreen />
    </>
  )
}

ReactDOM.render(
  <ErrorBoundary
    FallbackComponent={ErrorHandler}
  >
    <Auth0Provider
      domain={process.env.REACT_APP_AUTH0_DOMAIN}
      clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
      redirectUri={process.env.REACT_APP_AUTH0_LOGIN_REDIRECT}
      audience={process.env.REACT_APP_AUTH0_AUDIENCE}
      scope="read:current_user update:current_user_metadata"
      useRefreshTokens="true"
      cacheLocation="localstorage"
    >
      <React.StrictMode>
        <Router>
          <MuiThemeProvider theme={theme}>
            <App />
          </MuiThemeProvider>
        </Router>
      </React.StrictMode>
    </Auth0Provider>
  </ErrorBoundary>,
  document.getElementById('root')
);

reportWebVitals();
