import React, {useState, useEffect} from "react"
import colors from '../../config/colors';
import { BrowserRouter as Router, Link, useHistory } from "react-router-dom"
import { BottomNavigation, BottomNavigationAction  } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import GroupIcon from '@material-ui/icons/Group';
import MapIcon from '@material-ui/icons/Map';
import PersonIcon from '@material-ui/icons/Person';
import HTMLDialog from "../dialogs/HTMLDialog";


function NavBar () {
  const history = useHistory();
  const [selectedSection, setSelectedSection] = useState(() => {
    return window.location.pathname
  });
  const [getAppDialogOpen, setGetAppDialogOpen] = useState(false)
  
  return (
    <>
      <HTMLDialog 
        title="Get the app!"
        content="getApp"
        passedOpen={getAppDialogOpen} 
        onClose={() => {setGetAppDialogOpen(false)}}
      />
      <div className={"d-flex flex-wrap align-items-center justify-content-center justify-content-md-between py-3 border-bottom hippoNavbar"}>
        <div className="navBarWrapper">
          <img 
            src="../images/logo.png"
            className="hippoLogo" 
            onClick={() => {
              setSelectedSection('/map'),
              history.push('/map')
            }}
          />
        </div>
        <div className="navBarWrapper">
          <BottomNavigation
            showLabels
            value={selectedSection}
            onChange={(event, value) => {
              setSelectedSection(value)
              history.push(value)
            }}
            className="hippoSectionIcons"
          >
            <BottomNavigationAction 
              label="Hippomatch" 
              icon={<GroupIcon fontSize="large" />} 
              value={'/hippomatch'}
            />
            <BottomNavigationAction 
              label="Map" 
              icon={<MapIcon fontSize="large" />} 
              value='/map'
            />
            <BottomNavigationAction 
              label="You" 
              icon={<PersonIcon fontSize="large" />} 
              value='/you'
            />
          </BottomNavigation>
        </div>
        <div className="navBarWrapper">
          {/* <Button className={"linearButton displayNone"}>Get the app</Button> */}
          <Button className={"linearButton"} onClick={() => setGetAppDialogOpen(true)}>Get the app</Button>
        </div>
      </div>
    </>
  )
}

export default NavBar
