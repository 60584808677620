import * as React from 'react';
import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';

export default function MasonryImageList() {
  let cols;

  if (window.innerWidth < 1200) {
    cols = 2
  } else {
    cols =3
  }

  return (
    <Box className='imageGallery'>
      <ImageList variant="masonry" cols={cols} gap={8}>
        {itemData.map((item) => (
          <ImageListItem key={item.img}>
            <img
              src={`${item.img}?w=248&fit=crop&auto=format`}
              srcSet={`${item.img}?w=248&fit=crop&auto=format&dpr=2 2x`}
              alt={item.title}
              loading="lazy"
            />
          </ImageListItem>
        ))}
      </ImageList>
    </Box>
  );
}

const itemData = [
  {
    img: '../images/startPage/x1.png',
    title: 'hippohost',
  },
  {
    img: '../images/startPage/x2.png',
    title: 'hippohost',
  },
  {
    img: '../images/startPage/x3.png',
    title: 'hippohost',
  },
  {
    img: '../images/startPage/x4.png',
    title: 'hippohost',
  },
  {
    img: '../images/startPage/x6.png',
    title: 'hippohost',
  },
  {
    img: '../images/startPage/x7.png',
    title: 'hippohost',
  },
  {
    img: '../images/startPage/x8.png',
    title: 'hippohost',
  },
  {
    img: '../images/startPage/x9.png',
    title: 'hippohost',
  },
  {
    img: '../images/startPage/x10.png',
    title: 'hippohost',
  },
  {
    img: '../images/startPage/x11.png',
    title: 'hippohost',
  },
  {
    img: '../images/startPage/x12.png',
    title: 'hippohost',
  },
];