import React, {useState, useEffect, useRef} from 'react'
import useApi from '../hooks/useApi'
import TextInputField from '../forms/TextInputField'
import Form from '../forms/Form'
import SubmitIcon from '../forms/SubmitIcon'
import ConversationItem from '../components/lists/ConversationItem';
import chatApi from '../api/chatApi';
import sources from '../config/sources';
import routes from '../navigation/routes'
import { useHistory } from "react-router-dom"
import Spinner from '../components/other/Spinner'
import InputSpinner from '../components/other/InputSpinner'
import { Util } from '../utils/util'

function ConversationScreen(props) {
  const history = useHistory();
  const [data, setData] = useState(false)
  
  const getAIConversationApi = useApi(chatApi.getAIConversation)
  const sendAIMessageApi = useApi(chatApi.sendAIMessage)
  const deleteAIMessageApi = useApi(chatApi.deleteAIMessage)
  const scrollViewRef = useRef()
  const [loading, setLoading] = React.useState(false);
  const [saveMessageLoading, setSaveMessageLoading] = React.useState(false);

  const fetchMyAPI = async () => {
    const fetch = await getAIConversationApi.request(props.match.params.id)
    if (!fetch) { return }
    setData(fetch.data)
  }

  const handleSubmit = async ({message}, {resetForm} ) => {
    if (message) {
      setSaveMessageLoading(true)
      window.scrollTo(0, document.body.scrollHeight);
      let scrollingElement = (document.scrollingElement || document.body);
      scrollingElement.scrollTop = scrollingElement.scrollHeight;
      resetForm({ values: '' })
      const result = await sendAIMessageApi.request({
        message: message,
        conversationID: props.match.params.id
      })
      if (!result) { return }
      if (result.data.error) { 
        alert(result.data.content) 
        return setSaveMessageLoading(false)
      }
      
      await fetchMyAPI()
      setSaveMessageLoading(false)
      scrollingElement = (document.scrollingElement || document.body);
      scrollingElement.scrollTop = scrollingElement.scrollHeight;
    } else {
      return alert('Please enter a message')
    }
  }

  const handleMessageDelete = async (messageData) => {
    const confirmed = confirm('Delete message?');
    if (confirmed) {
      setLoading(true)
      const result = await deleteAIMessageApi.request(messageData.id)
      if (!result) { return }
      window.location.reload()
    }
  }

  useEffect(() => {
    fetchMyAPI()
  }, [])

  useEffect(() => {
    if (scrollViewRef.current) {
      scrollViewRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [data])

  return (
    <div className="col-10 col-lg-6 mx-auto mt-4 screen">
      <Spinner visible={loading} />
      {!loading && (
        <>
          {data && (
            <>
              <div className="row">
                {
                  data.conversation.map(function (conversationItem, index) {
                    return (
                      <React.Fragment key={index}>
                        {conversationItem.user_id === data.userData.id && (
                          <>
                            <ConversationItem
                              title={"Hippohelp User"}
                              message={conversationItem.message}
                              icon={true}
                              onDelete={() => handleMessageDelete(conversationItem)}
                              deleted={conversationItem.deleted}
                            />
                            <ConversationItem
                              title={"Hippohelp AI Helper"}
                              message={conversationItem.ai_answer}
                              imageSrc={Util.generateAIHippoAvatar(props.match.params.id)}
                              onDelete={() => handleMessageDelete(conversationItem)}
                              deleted={conversationItem.deleted}
                            />
                          </>
                        )}
                        {conversationItem.user_id !== data.userData.id && (
                          <>
                            <ConversationItem
                              title={"Hippohelp User"}
                              message={conversationItem.message}
                              icon={true}
                              onReport={() => history.push(routes.REPORT_AI_MESSAGE + '/' + conversationItem.id)}
                              deleted={conversationItem.deleted}
                            />
                            <ConversationItem
                              title={"AI Helper"}
                              message={conversationItem.ai_answer}
                              imageSrc={Util.generateAIHippoAvatar(props.match.params.id)}
                              onReport={() => history.push(routes.REPORT_AI_MESSAGE + '/' + conversationItem.id)}
                              deleted={conversationItem.deleted}
                            />
                          </>
                        )}
                      </React.Fragment>

                    )
                  })
                }
              </div>
              <InputSpinner visible={saveMessageLoading} message={"Hold on! AI-generation usually takes 5-15 seconds ..."}/>
              {!saveMessageLoading && (
                <Form onSubmit={handleSubmit} initialValues={{ message: '' }}>
                  <div ref={scrollViewRef} className="textBox messageInput">
                    <TextInputField name='message' label='Question' multiline inputProps={{ maxLength: 5000 }} />
                    <SubmitIcon icon={'send'} size={1} />
                  </div>
                </Form>
              )}
            </>
          )}
        </>
      )}
    </div>
  );
}

export default ConversationScreen;