import React, { useState, useEffect } from 'react';
import useApi from '../hooks/useApi';
import userApi from '../api/userApi';
import Form from '../forms/Form';
import TextInputField from '../forms/TextInputField';
import SubmitButton from '../forms/SubmitButton';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import { Button } from '@material-ui/core';
import Divider from '@mui/material/Divider';
import {
  FacebookIcon,
  RedditIcon,
  FacebookShareButton,
  RedditShareButton,
  TwitterShareButton,
} from "react-share";
import { ReactComponent as CustomXIcon } from '../assets/animations/xIcon.svg';

function PaymentSuccessfulScreen() {
  const sendPurchaseFeedback = useApi(userApi.sendPurchaseFeeback);
  const [referrer, setReferrer] = useState(false)
  const [referrerAd, setReferrerAd] = useState(false)

  const handleSubmit = async ( {socialMediaLink, referrerDetails} ) => {

    const sendData = {}

    if (socialMediaLink) {
      sendData.socialMediaLink = socialMediaLink
      sendData.socialMediaPlatform = "other"
    }

    if (referrer === "google" && referrerDetails && referrerAd) {
      sendData.referrer = referrer
      sendData.referrerDetails = referrerDetails
      sendData.reffererAd = referrerAd
    } else if (referrer !== "google" && referrer && referrerDetails) {
      sendData.referrer = referrer
      sendData.referrerDetails = referrerDetails
    }

    await sendPurchaseFeedback.request(sendData);

    // Redirect after submission
    window.location.href = "/";
  };

  const shareUrl = "https://hippohelp.com"
  const title = "Hippohelp - Work, travel and live with the locals!"

  return (
    <div className="col-10 col-lg-6 mx-auto mt-4 screen">
      <p>Thank you for your payment!</p>
      <p><span style={{ textDecoration: "underline"}} className="primaryBold">Get an automatic €2 refund</span> by filling in both forms below. You may also choose to fill in just one for a €1 refund.</p>
      <p>The money is inserted on your card within a week!</p>

      <Form onSubmit={handleSubmit} initialValues={{ }}>

        <p style={{marginTop: 40, marginBottom: 0}}>Form 1: Share Hippohelp on Social Media</p>
        <p style={{marginTop: 0, marginBottom: 0}}>(€1 refund)</p>
        <p>Click on one of the buttons below, then enter the link to the post where you have shared Hippohelp.</p>
        <div style={{display: "flex", flexDirection: "row", gap: "10px", justifyContent: "left", marginBottom: "1em"}}>
          <RedditShareButton
            url={shareUrl}
            title={title}
            windowWidth={660}
            windowHeight={460}
            className="socialShareButton"
          >
            <RedditIcon size={32} round />
          </RedditShareButton>
          <TwitterShareButton
            url={shareUrl}
            title={title}
            className="socialShareButton"
            hashtags={[
              "workandtravel",
              "volunteer",
              "hippohelp",
              "workexchange",
              "helpexchange"
            ]}
          >
            <CustomXIcon />
          </TwitterShareButton>
          <FacebookShareButton 
            url={shareUrl}
            className="socialShareButton"
            hashtag="#volunteer"
          >
            <FacebookIcon size={32} round />
          </FacebookShareButton>
        </div>

        <TextInputField
          name="socialMediaLink"
          label="Link to the social media post"
          withErrorMessage={false}
        />

        <p style={{marginTop: 40, marginBottom: 0 }}>Form 2: Tell us how you found us</p>
        <p style={{ marginTop: 0, marginBottom: 20 }}>(€1 refund)</p>

        <div className="selectInput" marginTop={0}>
          <FormControl fullWidth>
            <InputLabel className="customFormControlLabel" id="select-label2">How did you find us?</InputLabel>
            <Select
              className="customFormControlPlaceholder"
              name="referrer"
              labelId="select-label2"
              label="How did you find us?"
              id="select-label2"
              value={referrer}
              onChange={e => setReferrer(e.target.value)}
            >
              <MenuItem value={"blog"}>Blog</MenuItem>
              <MenuItem value={"youtube"}>Youtube</MenuItem>
              <MenuItem value={"google"}>Google</MenuItem>
              <MenuItem value={"friend"}>Friend</MenuItem>
              <MenuItem value={"other"}>Other</MenuItem>
            </Select>
          </FormControl>
        </div>

        {referrer === "blog" && (
          <p>Please paste the link to the blog post where you found out about us.</p> 
        )}
        {referrer === "youtube" && (
          <p>Please paste the link to the Youtube video where you found out about us.</p> 
        )}
        {referrer === "google" && (
          <p>What did you search on Google when you found us?</p> 
        )}
        {referrer === "friend" && (
          <p>In what situation did your friend mention us?</p> 
        )}
        {referrer === "other" && (
          <p>Please explain how you found out about us.</p> 
        )}

        {referrer && (
          <TextInputField
            name="referrerDetails"
            label="Please explain how you found out about us"
            withErrorMessage={false}
          />
        )}


        {referrer === "google" && (
          <div className="selectInput" marginTop={0}>
          <FormControl fullWidth>
            <InputLabel className="customFormControlLabel" id="select-label3">Was it an ad that you clicked on?</InputLabel>
            <Select
              className="customFormControlPlaceholder"
              name="wasAd"
              labelId="select-label3"
              label="How did you find us?"
              id="select-label3"
              value={referrerAd}
              onChange={e => setReferrerAd(e.target.value)}
            >
              <MenuItem value={"yes"}>Yes</MenuItem>
              <MenuItem value={"no"}>No</MenuItem>
            </Select>
          </FormControl>
          </div>
        )}

        <div style={{display: "flex", width: "100%", justifyContent: "center", marginTop: "40px"}}>
          <div style={{display: "flex", flexDirection: "column", width: "300px"}}>
            <SubmitButton title="Submit for Refund" />
            <p style={{textAlign: "center", width: "100%"}}>or ...</p>
            <Button color="default" variant='outlined' style={{borderRadius: "25px"}}  onClick={() => (window.location.href = "/")}>
              Continue without Refund
            </Button>
          </div>
        </div>
      </Form>
    </div>
  );
}

export default PaymentSuccessfulScreen;
