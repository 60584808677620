import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';


const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

export default function AdminActionSelect({onSelect, actions}) {
  const classes = useStyles();

  const handleChange = (event) => {
    onSelect(event.target.value);
  };

  return (
    <div>
      <FormControl className={classes.formControl}>
        <InputLabel id="demo-simple-select-label">Function</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          onChange={handleChange}
        >
          {actions.map(action => (
            <MenuItem key={action.actionName} value={{
              action: action.action,
              actionName: action.actionName,
              inputAction: action.inputAction,
              passwordAction: action.passwordAction
            }}>{action.actionName}</MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}
