import React from 'react'
import * as importedJson from '../variables/licenses.json';
import Footer from '../components/formatting/Footer';
import Box from '@mui/material/Box';
import { Typography } from '@material-ui/core';
import Divider from '../components/formatting/Divider';
import HTMLContent from '../variables/HTMLContent';

const data = importedJson.default
const allKeys = Object.keys(data)

function LicensesScren ({ navigation }) {
  return (
    <>   
      <Box className='contentBox'>
        <Typography component="h1" variant="h4">Licenses</Typography>
        <Divider />
        <div className='leftText'>
          <HTMLContent content="licenses" />
        </div>
      </Box>
      <Footer />
    </>
  )
}

export default LicensesScren
