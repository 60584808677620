import React from 'react'
import { useFormikContext } from 'formik'
import Button from '@material-ui/core/Button';

function SubmitButton ({placeholder = 'Submit'}) {
  const { handleSubmit } = useFormikContext()

  return (
    <Button className={"genericButton"} onClick={ handleSubmit }>{placeholder}</Button>
  )
}

export default SubmitButton
