import ApiClient from './ApiClient.js'

const getBlogPostBySlug = async function (...args) { 
  const apiClient = new ApiClient(...args)
  return apiClient.get(`/blog/post-by-slug/${args[0]}`)
}

const getCategories = async function (...args) { 
  const apiClient = new ApiClient(...args)
  return apiClient.get(`/blog/categories`)
}

const getCategoriesAndAllPosts = async function (...args) { 
  const apiClient = new ApiClient(...args)
  return apiClient.get(`/blog/categories-and-all-posts/${args[0]}`)
}

const getCategoryBySlug = async function (...args) { 
  const apiClient = new ApiClient(...args)
  return apiClient.get(`/blog/category-by-slug/${args[0]}&${args[1]}`)
}

const getSubCategoryBySlug = async function (...args) { 
  const apiClient = new ApiClient(...args)
  return apiClient.get(`/blog/sub-category-by-slug/${args[0]}&${args[1]}`)
}

const getSubSubCategoryBySlug = async function (...args) { 
  const apiClient = new ApiClient(...args)
  return apiClient.get(`/blog/sub-sub-category-by-slug/${args[0]}&${args[1]}`)
}

export default {
  getBlogPostBySlug,
  getCategories,
  getCategoriesAndAllPosts,
  getCategoryBySlug,
  getSubCategoryBySlug,
  getSubSubCategoryBySlug
}
