import React, { useState } from 'react'
import Icon from '@mdi/react'
import { mdiWhatsapp} from '@mdi/js'
import { useAuth0 } from "@auth0/auth0-react";
import colors from '../../config/colors';
import GenericModal from '../../components/other/GenericModal'
import { Button } from '@material-ui/core';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';

function WhatsappIconButton({displayName, number, access = { hasAccess: true }}) {
  const { isAuthenticated, loginWithRedirect } = useAuth0();
  const [open, setOpen] = useState(false)

  const generateContent = () => {
    return (
      <div className='whatsAppcontent'>
        <p>WhatsApp number: {number}</p>
        <Button 
          startIcon={<WhatsAppIcon />} 
          className={"genericButton primaryColor"}
          onClick={() => window.open('https://wa.me/' + number, '_blank')}
        >
          Open in WhatsApp
        </Button>
      </div>

    )
  }

  return (
    <div>
      {
        (isAuthenticated === false) ? (
          <div onClick={() => loginWithRedirect({ screen_hint: 'signup' })} className="iconWrapper iconButton">
            <Icon path={mdiWhatsapp} size={1} color={colors.white} />
          </div>
        ) : (
          <>
            <GenericModal 
              open={open} 
              title={`${displayName}'s WhatsApp`}
              onHide={() => {
                setOpen(false)
              }}
              content={generateContent()}
              dialogClassName={"smallModal"}
            />
            {access.hasAccess && (
              <div onClick={() => setOpen(true)} className="iconWrapper iconButton">
                <Icon path={mdiWhatsapp} size={1} color={colors.white} />
              </div>
            )}
            {!access.hasAccess && (
              <>
                {access.type === "hostmarkerAwaitingApproval" && (
                  <div onClick={() => alert('Once your hostmarker is approved you will have premium access for free, come back here then!')} className="iconWrapper iconButton">
                    <Icon path={mdiWhatsapp} size={1} color={colors.white} />
                  </div>
                )}
                {access.type !== "hostmarkerAwaitingApproval" && (
                  <div onClick={() => alert('Purchase premium membership to view WhatsApp.')} className="iconWrapper iconButton">
                    <Icon path={mdiWhatsapp} size={1} color={colors.white} />
                  </div>
                )}
              </>

            )}
          </>

        )
      }
    </div>
  );
}

export default WhatsappIconButton