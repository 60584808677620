import React from 'react'
import { Util } from '../../utils/util'

function TravellerDetailsContent ({listingData}) {
  return (
    <div className="detailsContent">
      <div className="row">
        <h2>Description</h2>
        <p>{listingData.profile_description}</p>
      </div>
      <div className="row">
        <h5>Account type</h5>
        <p>{Util.parseAccountType(listingData.user_account_type_id)}</p>
        <h5>Country where I'm now</h5>
        <p>{listingData?.position?.position ? listingData.position.position : 'Not set'}</p>
        <h5>Countries planning on travelling to</h5>
        <p>{listingData?.location?.traveling_location ? listingData.location.traveling_location : 'Not set'}</p>
        <h5>Tasks willing to do</h5>
        {listingData.help.help_type.arrayFormat.length !== 0 && (
          <ul className="workTypeList">
            {
              listingData.help.help_type.arrayFormat.map (element => {
                return <li key={element}>{Util.parseWorkType(element)}</li>
              })
            }
          </ul>
        )}
        {listingData.help.help_type.arrayFormat == 0 && (
          <p>{listingData?.help.help_type.stringFormat}</p>
        )}
        <h5>Skills</h5>
        <p>{listingData.skill.skill}</p>
        <h5>Languages spoken</h5>
        <p>{listingData.language.language}</p>
        <h5>Nationality</h5>
        <p>{listingData.nationality}</p>
        <h5>Drivers licence</h5>
        <p>{listingData.drivers_license}</p>
        <h5>Allergies</h5>
        <p>{listingData.allergy.allergy}</p>
        <h5>Diet requirements</h5>
        <p>{listingData.diet.user_diet_requirement}</p>
        <h5>Additional info</h5>
        {listingData.additional_info && ( <p>{listingData.additional_info}</p> )}
        {!listingData.additional_info && ( <p>No additional info.</p> )}
      </div>
    </div>
  )
}

export default TravellerDetailsContent
