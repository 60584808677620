import React, { useState, useEffect } from 'react'
import Icon from '@mdi/react'
import { mdiAccountPlusOutline , mdiAccountRemoveOutline , mdiAccountAlertOutline, mdiLoading  } from '@mdi/js'
import { useAuth0 } from "@auth0/auth0-react";
import colors from '../../config/colors'
import useApi from '../../hooks/useApi'
import userApi from '../../api/userApi'
import ActionModal from '../other/ActionModal'

function FriendIconButton({friendStatus, userID}) {
  const [iconPath, setIconPath] = useState()
  const [spin, setSpin] = useState(false)
  const [onClick, setOnClick] = useState()

  const sendFriendRequestApi = useApi(userApi.sendFriendRequest)
  const cancelFriendRequestApi = useApi(userApi.cancelFriendRequest)
  const confirmFriendRequestApi = useApi(userApi.confirmFriendRequest)
  const rejectFriendRequestApi = useApi(userApi.rejectFriendRequest)
  const removeFriendApi = useApi(userApi.removeFriend)
  const { isAuthenticated, loginWithRedirect } = useAuth0();

  const handleError = function (errorMessage) {
    alert(errorMessage)
  }

  const sendFriendRequest = async function () {
    // Before
    setIconPath(mdiLoading)
    setSpin(true)
    setOnClick(() => function () { return })

    // Api call
    const fetch = await sendFriendRequestApi.request(userID)
    if (!fetch) { return }
    
    // After
    setIconAndActions('userFollows')
  }

  const cancelFriendRequest = async function () {
    // Before
    setIconPath(mdiLoading)
    setSpin(true)
    setOnClick(() => function () { return })

    // Api call
    const fetch = await cancelFriendRequestApi.request(userID)
    if (!fetch) { return }
    
    // After
    setIconAndActions('noRelation')
  }

  const confirmFriendRequest = async function () {
    // Before
    setIconPath(mdiLoading)
    setSpin(true)
    setOnClick(() => function () { return })

    // Api call
    const result = await confirmFriendRequestApi.request(userID)
    if (!fetch) { return }
    
    // After
    setIconAndActions('isFriends')
  }

  const rejectFriendRequest = async function () {
    // Before
    setIconPath(mdiLoading)
    setSpin(true)
    setOnClick(() => function () { return })

    // Api call
    const fetch = await rejectFriendRequestApi.request(userID)
    if (!fetch) { return }
    
    // After
    setIconAndActions('noRelation')
  }

  const removeFriend = async function () {
    // Before
    setIconPath(mdiLoading)
    setSpin(true)
    setOnClick(() => function () { return })

    // Api call
    const fetch = await removeFriendApi.request(userID)
    if (!fetch) { return }
    
    // After
    setIconAndActions('noRelation')
  }

  const [modalOpen, setModalOpen] = useState(false)
  const [modalTitle, setModalTitle] = useState(false)
  const [modalActions, setModalActions] = useState(false)

  const setIconAndActions = function (friendStatus)  {
    let friendIconVariant
    let onClick

    if (friendStatus === 'noRelation') { // Send friend request
      friendIconVariant = mdiAccountPlusOutline
      onClick = () => {
        setModalOpen(true)
        setModalTitle("Send Friend Request?")
        setModalActions([{ action: sendFriendRequest, actionName: "Send"}])
      }
    } else if (friendStatus === 'userFollows') { // Cancel friend request
      friendIconVariant = mdiAccountRemoveOutline 
      onClick = () => {
        setModalOpen(true)
        setModalTitle("Cancel Friend Request?")
        setModalActions([{ action: cancelFriendRequest, actionName: "Yes"}])
      }
    } else if (friendStatus === 'followsUser') { // Reject or confirm friend request
      friendIconVariant = mdiAccountAlertOutline 
      onClick = () => {
        setModalOpen(true)
        setModalTitle("Confirm or Reject Friend Request?")
        setModalActions([{ action: rejectFriendRequest, actionName: "Reject"}, { action: confirmFriendRequest, actionName: "Confirm" }])
      }
    } else if (friendStatus === 'isFriends') { // Remove friend
      friendIconVariant = mdiAccountRemoveOutline 
      onClick = () => {
        setModalOpen(true)
        setModalTitle("Remove Friend?")
        setModalActions([{ action: removeFriend, actionName: "Remove"}])
      }
    }

    setSpin(false)
    setIconPath (friendIconVariant)
    setOnClick(() => onClick)
  }

  useEffect(() => {
    setIconAndActions(friendStatus)
  }, [])

  return (
    <div>
      {
        (isAuthenticated === false) ? (
          <div onClick={() => loginWithRedirect({ screen_hint: 'signup' })} className="iconWrapper iconButton">
            <Icon spin={spin} path={iconPath} size={1} color={colors.white} />
          </div>
        ) : (
          <>
            {modalTitle && <ActionModal open={modalOpen} title={modalTitle} actions={modalActions} onHide={() => setModalOpen(false)} />}
            {
              onClick && (
                <div onClick={onClick} className="iconWrapper iconButton" id='friendButton'>
                  <Icon spin={spin} path={iconPath} size={1} color={colors.white} />
                </div>
              )
            }
          </>
        )
      }
    </div>
  );
}

export default FriendIconButton