import React from 'react';

function AppRemovedScreen() {
  return (
    <div style={{
      fontFamily: 'Arial, sans-serif',
      margin: '0 auto',
      maxWidth: '600px',
      padding: '20px'
    }}>
      <div style={{
        backgroundColor: '#fc5c65',
        color: '#ffffff',
        padding: '20px',
        textAlign: 'center',
        borderRadius: '6px'
      }}>
        <img src="/images/logo.png" alt="Hippohelp Logo" style={{ marginTop: '0.67em' }} />
        <h1>Important Announcement About Hippohelp</h1>
      </div>

      <div style={{ padding: '20px' }}>
        <p>Dear Hippohelp Community Member,</p>

        <p>We are writing to inform you about an important change regarding Hippohelp. After serving our community of over 80,000 members, we want to let you know that Hippohelp will be closing its operations, including the deletion of all data on the platform.</p>

        <h2>What This Means</h2>
        <p>We are pleased to announce that HelpStay, another established platform in the volunteering space, has acquired the Hippohelp.com domain and trademark. Only the trademark and domain name has been acquired, so we have not sold any user-related data. We will delete the platform and it's associated data on 2024-12-31.</p>

        <h2>Timeline</h2>
        <p>December 30, 2024 will be the last day when the Hippohelp platform will remain operational. During the transition period the following will apply:</p>
        <ul>
          <li>New user sign-ups will be disabled</li>
          <li>No new markers will be approved</li>
          <li>The iOS and Android apps will be removed from their respective stores</li>
          <li>Existing users can continue to use the platform until the closure date</li>
        </ul>

        <p style={{ fontWeight: 'bold' }}>After the transition period all data on the platform will be permanently deleted, so write to your current contacts about how to reach you in the future, and consider joining the HelpStay platform instead.</p>

        <p>We encourage you to explore HelpStay as an alternative platform for your future volunteering experiences. HelpStay offers similar opportunities for cultural exchange and meaningful travel experiences.</p>

        <p>For questions about HelpStay, reach out to the founder Shay at <strong>shay@helpstay.com</strong>.</p>

        <p>We want to express our heartfelt gratitude for being part of the Hippohelp community. Your participation has made Hippohelp a vibrant platform for cultural exchange and meaningful connections around the world.</p>

        <p>Kind regards,</p>
        <p>The Hippohelp Team</p>
      </div>
    </div>
  );
}

export default AppRemovedScreen;