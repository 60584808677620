import React, { useState, useEffect } from 'react'
import Box from '@mui/material/Box';
import Button from '@material-ui/core/Button';
import { useHistory } from "react-router-dom"
import Text from '../other/Text'

function CategorySection({
  categories,
  showTopLevelCategories = false,
  showSubLevelCategories = false,
  showSubSubLevelCategories = false,
  withPath = true
}) {
  const history = useHistory();

  let categoryName
  if (showTopLevelCategories) {
    categoryName = categories.category_name
  } else if (showSubLevelCategories) {
    categoryName = categories.category_name
  } else if (showSubSubLevelCategories) {
    categoryName = categories.sub_category_name
  }

  return (
    <> 
      {
        categories.length > 0 && (
          <p style={{
            textAlign: "left", 
            margin: 0, 
            marginBottom: 0,
          }}
            >{showTopLevelCategories ? "Blog Categories" : "Sub-categories"}:
          </p>
        )
      }
      <Box className="blogCategories">      
        {
          <>
            {showTopLevelCategories && (
              <>
                {categories.map((category, index) => {
                  return (
                      <p 
                        key={index} 
                        className={"hippoLink"}
                        style={{ margin: "0px", color: "blue" }}
                        onClick={() => { history.push(`/blog/category/${category.category_slug}`) }}
                      >
                        {category.category_name}
                      </p>
                    )
                })}
              </>
            )}
            {showSubLevelCategories && (
              <>
                {categories.map((category, index) => {
                  return (
                    <p 
                      key={index} 
                      className={"hippoLink"}
                      style={{ margin: "0px", color: "blue" }}
                      onClick={() => { history.push(`/blog/category/${category.category_slug}/${category.sub_category_slug}`) }}
                      >
                      {category.sub_category_name}
                    </p>
                  )
                })}
              </>
            )}
            {showSubSubLevelCategories && (
              <>
                {categories.map((category, index) => {
                  return (
                    <p 
                      key={index} 
                      className={"hippoLink"}
                      style={{ margin: "0px", color: "blue" }}
                      onClick={() => { history.push(`/blog/category/${category.category_slug}/${category.sub_category_slug}/${category.sub_sub_category_slug}`) }}
                    >
                      {category.sub_sub_category_name}
                    </p>
                  )
                })}
              </>
            )}
          </>
        }
      </Box>
    </>
  )
}

export default CategorySection