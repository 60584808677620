import React, { useContext, useState, useEffect } from 'react'
import useApi from '../../hooks/useApi'
import userApi from '../../api/userApi'
import Button from '@material-ui/core/Button';
import {
  PaymentElement,
  AddressElement,
  useStripe,
  useElements
} from "@stripe/react-stripe-js";
import Alert from '@mui/material/Alert';
import Spinner from '../other/Spinner';
import routes from '../../navigation/routes';
import sources from '../../config/sources';
import Box from '@mui/material/Box';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ReviewSlider from '../other/ReviewSlider/ReviewSlider';

function Paywall ({subscriptionId, title}) {
  const getOfferingsApi = useApi(userApi.getOfferings)
  const [offering, setOffering] = useState(false);
  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async () => {
    try {
      // e.preventDefault();
  
      if (!stripe || !elements) {
        // Stripe.js has not yet loaded.
        // Make sure to disable form submission until Stripe.js has loaded.
        return;
      }
  
      setIsLoading(true);
  
      const { error } = await stripe.confirmPayment({
        elements,
        confirmParams: {
          // Make sure to change this to your payment completion page
          return_url: sources.frontendUrl + routes.PAYMENT_PROCESSING,
        },
      });
  
      if (error) {
        // This point will only be reached if there is an immediate error when
        // confirming the payment. Show error to your customer (for example, payment
        // details incomplete)
        setMessage(error.message);
      } else {
        // Your customer will be redirected to your `return_url`. For some payment
        // methods like iDEAL, your customer will be redirected to an intermediate
        // site first to authorize the payment, then redirected to the `return_url`.
      }
      setIsLoading(false);
    } catch (error) {
      console.log(error)
      setMessage("Error making payment. Please pay in our app instead.")
      setIsLoading(false)
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      const offering = await getOfferingsApi.request();
      setOffering(offering.data);
    };

    fetchData()
  }, []);


  const calculateHeight = function () {
    const actualHeight = window.innerHeight;

    if (document.querySelector('#control-height')) {
      const elementHeight = document.querySelector('#control-height').clientHeight;
    
      const barHeight = elementHeight - actualHeight;

      return barHeight * 2 + 400 + "px"
    } else {
      return 400 + "px"
    }
  }

  return (
    <div className={"paywall"}>
      {offering?.id && (
        <Box style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center"
        }}>
          <Box className="paywallBox">
            <h1>{title}</h1>
            <p>Upgrade now to get access to:</p>
            <ul>
              <li>In-app messaging</li>
              <li>Reading and writing of user feedback</li>
            </ul>
            <p style={{marginBottom: "10px", paddingBottom: "10px"}}>We charge €{offering.unit_amount.toString().substring(0, 2)}.{offering.unit_amount.toString().substring(2, 4)} per year to support a thriving community and a bug-free app (cancel anytime)!</p>
            <h2>Stories from our 80.000+ users</h2>
            <Box className="paywallReviewSliderContainer">
              <ReviewSlider />
            </Box>
            <form className="paymentForm">
              <PaymentElement id="payment-element" />
              <AddressElement options={{mode: 'billing'}} />
              {isLoading && (
                <>
                  <Spinner visible={true} withMessage={false} withPaymentMessage={true} />
                </>

              )}
              {!isLoading && (
                <>
                  <Button style={{marginTop: 20}} className={"genericButton"} onClick={() => handleSubmit()}>Submit</Button>
                  {message && (
                    <div id="payment-message">
                      <h2>{message}</h2>
                      <Alert 
                        sx={{ mt: 2, mb: 2, justifyContent: 'center' }} 
                        severity="error" 
                        className='detailsAlertBox'
                      >
                        If you are attempting to pay with a UK card, please purchase Hippohelp Premium via our apps on Google Play or the App Store.
                      </Alert>
                      <p></p>
                  </div>)}
                  <p>We don't store any card details. All payments are safely processed via <a rel="noreferrer" href="https://www.stripe.com" target="_blank">Stripe</a>.</p>
                  <p>You can cancel at any time through your account settings. To get there, tap on 'You', then 'More', then 'Account'.</p>
                </>
              )}
            </form>
          </Box>
        </Box>
      )}
    </div>
  )
}

export default Paywall
