import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom"
import useApi from '../hooks/useApi'
import userApi from '../api/userApi';
import markerApi from '../api/markerApi';
import routes from '../navigation/routes';
import Form from '../forms/Form'
import TextInputField from '../forms/TextInputField'
import SubmitButton from '../forms/SubmitButton';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import workTypeItems from '../variables/workTypeItems'
import MultiSelectInputField from '../forms/MultiSelectInputField';
import { Util } from '../utils/util';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Spinner from '../components/other/Spinner';
import { v4 as uuidv4 } from 'uuid';
import { mdiMinus} from '@mdi/js'
import Icon from '@mdi/react'
import colors from '../config/colors';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import sources from '../config/sources';

function HostUserDetailsScreen() {
  const history = useHistory();
  const userData = history.location.state.userData
  const markerData = history.location.state.userData.marker
  const coordinates = history.location.state.coordinates
  const [smoker, setSmoker] = useState(false)
  const [lastMinuteRequests, setLastMinuteRequests] = useState(false)
  const [fieldValues, setFieldValues] = useState(false)
  const saveHostMarkerDetailsApi = useApi(markerApi.saveHostMarkerDetails)
  const saveHostUserDetailsApi = useApi(userApi.saveHostUserDetails)
  const [selectedWorkTypeItems, setSelectedWorkTypeItems] = useState([])
  const resetUserAndMarkerDataApi = useApi(userApi.resetUserAndMarkerData)
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [linkFields, setLinkFields] = useState([])

  const handleClose = () => {
    setOpen(false);
  };

  const handleAddLink = () => {
    if (linkFields.length < 3) {
      setLinkFields(prevLinkFields => [...prevLinkFields, { id: uuidv4(), value: "" }]);
    }
  };

  const handleRemoveLink = (id) => {
    setLinkFields(prevLinkFields => prevLinkFields.filter(linkField => linkField.id !== id));
  };

  const smokerTypeSelections = [
    {id: 1, placeholder: "No"},
    {id: 2, placeholder: "Yes"}
  ]

  const lastMinuteRequestsSelections = [
    {id: 2, placeholder: "Yes"},
    {id: 1, placeholder: "No"}
  ]

  useEffect(() => {
    if (markerData?.is_travel_event === 0 && userData?.language?.language) {
      const initialLinks = userData.link.map((link) => ({ id: uuidv4(), value: link.link }));
      const valuesToSet = {}
      valuesToSet.title = markerData.name
      valuesToSet.description = markerData.event_description
      valuesToSet.workingHours = markerData.work_hours
      valuesToSet.requirements = markerData.work_requirements
      valuesToSet.durationOfStay = markerData.stay_duration_preferences
      valuesToSet.maximumNumberOfHelpers = markerData.guest_max_number_preferences
      valuesToSet.languages = userData.language.language
      valuesToSet.pets = markerData.pets_home
      valuesToSet.accommodation = markerData.sleeping_home
      for (let i = 0; i < initialLinks.length; i++) {
        valuesToSet[`link_${initialLinks[i].id}`] = initialLinks[i].value
      }
      valuesToSet.whatsapp = userData.whatsapp

      if (userData.user_account_type_id !== 4) {
        valuesToSet.realName = userData.username
      }
      
      if (markerData.additional_info) { 
        valuesToSet.additionalInfo = markerData.additional_info 
      }

      setLinkFields(initialLinks);
      setFieldValues(valuesToSet)
      setSmoker(markerData.smoking_home + 1)
      setSelectedWorkTypeItems(markerData.work.work_type.arrayFormat)
      setLastMinuteRequests(markerData.last_minute_preferences + 1)
    } else {
      setFieldValues({})
    }
  }, [])

  const handleSubmit = async (submitData) => {
    if (submitData.whatsapp) {
      if (!Util.checkIfOnlyDigits(submitData.whatsapp)) {
        return alert("Whats app number can only contain digits!")
      }
    }

    if (submitData.maximumNumberOfHelpers) { 
      if (!Util.checkIfOnlyDigits(submitData.maximumNumberOfHelpers)) {
        return alert("Maximum number of helpers can only contain digits!")
      }
    }
    const emptyFields = []
    if (smoker !== 1 && smoker !== 2) { emptyFields.push('Smoker') }
    if (lastMinuteRequests !== 1 && lastMinuteRequests !== 2) { emptyFields.push('Last minute requests') }
    if (selectedWorkTypeItems.length === 0 || typeof selectedWorkTypeItems[0] === 'undefined') { emptyFields.push('Type of work') }
    if (!submitData.title) { emptyFields.push('Title') }
    if (!submitData.realName) { emptyFields.push('Full Name (First and Last)') }
    if (!submitData.description) { emptyFields.push('Description') }
    if (!submitData.workingHours) { emptyFields.push('Working hours') }
    if (!submitData.requirements) { emptyFields.push('Requirements') }
    if (!submitData.durationOfStay) { emptyFields.push('Duration of stay') }
    if (!submitData.maximumNumberOfHelpers) { emptyFields.push('Maximum Number Of Helpers (only digits)') }
    if (!submitData.languages) { emptyFields.push('Languages you speak') }
    if (!submitData.pets) { emptyFields.push('Pets') }
    if (!submitData.accommodation) { emptyFields.push('Accommodation') }

    if (Util.checkEmptyFields(emptyFields)) {
      setLoading(true);
      const resetUserAndMarkerDataApiResult = await resetUserAndMarkerDataApi.request()
      if (!resetUserAndMarkerDataApiResult) { return }
      
      const saveData = {
        title: submitData.title,
        realName: submitData.realName,
        coordinates: coordinates,
        durationOfStay: submitData.durationOfStay,
        maximumNumberOfHelpers: submitData.maximumNumberOfHelpers,
        lastMinutePreferences: lastMinuteRequests - 1,
        languages: submitData.languages,
        pets: submitData.pets,
        smokingHome: smoker - 1,
        accommodation: submitData.accommodation,
        workingHours: submitData.workingHours,
        requirements: submitData.requirements,
        additionalInfo: submitData.additionalInfo,
        description: submitData.description,
        workType: Util.formatWorkTypeIdentifiersToString(selectedWorkTypeItems),
        whatsapp: submitData.whatsapp
      }
      const links = linkFields.map(linkField => submitData[`link_${linkField.id}`]).filter(link => link);
      saveData.links = links;
      
      const userResult = await saveHostUserDetailsApi.request(saveData)
      if (!userResult) { 
        alert('Error saving data (user details)')
        return 
      }
      
      const markerResult = await saveHostMarkerDetailsApi.request(saveData)
      if (!markerResult) { 
        alert('Error saving data (marker details)')
        return 
      }

      const imageSource = Util.getFirstImageSource(userData.image)
      if (Util.getImageName(imageSource) === Util.getImageName(sources.DUMMY_IMAGE.uri)) {
        history.push(routes.IMAGES)
      } else {
        history.push(routes.ACCOUNT)
      }
    }
  }

  const onMultiSelectChange = (value) => {
    setSelectedWorkTypeItems(Util.convertWorkTypeNamesToIDs(Array.from(value)))
  }

  return (
    <div className="col-10 col-lg-6 mx-auto mt-4 screen">
          <Spinner visible={loading} />
          {
            !loading && (
              <>
                <div className="contentHeader mb-4">
                  <h1>Set details</h1>
                </div>
                {fieldValues && (
                  <>
                    <div className="selectInput">
                    <FormControl fullWidth>
                      <InputLabel className="customFormControlLabel" id="select-label1">Do you smoke inside your home?</InputLabel>
                      <Select
                        className="customFormControlPlaceholder"
                        labelId="select-label1"
                        id="select-label1"
                        value={smoker}
                        label="Do you smoke inside your home?"
                        onChange={(event) => {setSmoker(event.target.value)}}
                      >
                        <MenuItem value={smokerTypeSelections[0].id}>{smokerTypeSelections[0].placeholder}</MenuItem>
                        <MenuItem value={smokerTypeSelections[1].id}>{smokerTypeSelections[1].placeholder}</MenuItem>
                      </Select>
                    </FormControl>
                    </div>
                    <div className="selectInput" onClick={() => { if (!open) { setOpen(true) }}}>
                      <MultiSelectInputField identifier='set-details-multiple-checkbox' selections={workTypeItems} label="Work types" onChange={(value) => onMultiSelectChange(value)} selectedItems={selectedWorkTypeItems} open={open} handleClose={handleClose}/>
                    </div>
                    <div className="selectInput">
                      <FormControl fullWidth>
                        <InputLabel className="customFormControlLabel" id="select-label4">Are last minute requests okay?</InputLabel>
                        <Select
                          className="customFormControlPlaceholder"
                          labelId="select-label4"
                          id="select-label4"
                          value={lastMinuteRequests}
                          onChange={(event) => {setLastMinuteRequests(event.target.value)}}
                        >
                          <MenuItem value={lastMinuteRequestsSelections[0].id}>{lastMinuteRequestsSelections[0].placeholder}</MenuItem>
                          <MenuItem value={lastMinuteRequestsSelections[1].id}>{lastMinuteRequestsSelections[1].placeholder}</MenuItem>
                        </Select>
                      </FormControl>
                    </div>
                    <Form onSubmit={handleSubmit} initialValues={fieldValues}>
                      <div className="textBox">
                        <TextInputField name='title' label='Title' inputProps={{ maxLength: 255 }} />
                      </div>
                      <div className="textBox">
                        <TextInputField name='realName' label='Full Name (First and Last)' multiline inputProps={{ maxLength: 50 }} />
                      </div>
                      <div className="textBox">
                        <TextInputField name='description' label='Description' multiline inputProps={{ maxLength: 5000 }} />
                      </div>
                      <div className="textBox">
                        <TextInputField name='accommodation' label='Housing' inputProps={{ maxLength: 5000 }} multiline />
                      </div>
                      <div className="textBox">
                        <TextInputField name='workingHours' label='Working hours' multiline inputProps={{ maxLength: 255 }} />
                      </div>
                      <div className="textBox">
                        <TextInputField name='requirements' label='Requirements' multiline inputProps={{ maxLength: 5000 }} />
                      </div>
                      <div className="textBox">
                        <TextInputField name='durationOfStay' label='Duration of stay' inputProps={{ maxLength: 255 }} />
                      </div>
                      <div className="textBox">
                        <TextInputField name='maximumNumberOfHelpers' label='Maximum number of helpers' type="number" inputProps={ {min: 0, max: 10} } />
                      </div>
                      <div className="textBox">
                        <TextInputField name='languages' label='Languages you speak' multiline inputProps={{ maxLength: 255 }} />
                      </div>
                      <div className="textBox">
                        <TextInputField name='pets' label='Do you have pets at your home?' multiline inputProps={{ maxLength: 255 }} />
                      </div>
                      <div className="textBox">
                        <TextInputField name='additionalInfo' label='Additional Info' multiline inputProps={{ maxLength: 5000 }} />
                      </div>
                      <div>
                        <div className="contentHeader mb-4 mt-4">
                          <h1>Optional links</h1>
                        </div>
                        <p>Enhance your profile's trustworthiness by adding your social media and website links below. Note: these will only be visible to registered users.</p>
                        {linkFields.map((linkField) => (
                          <div key={linkField.id} className="textBox">
                            <TextInputField 
                              name={`link_${linkField.id}`}
                              label={`Link`}
                              type="text"
                              defaultValue={linkField.value}
                              inputProps={{ maxLength: 255 }}
                            />
                            <div style={{marginLeft: 20}} onClick={() => handleRemoveLink(linkField.id)} className="iconWrapper iconButton secondaryColorIcon">
                              <Icon path={mdiMinus} size={1} color={colors.white} />
                            </div>
                          </div>
                        ))}
                        <Button
                          onClick={handleAddLink} 
                          disabled={linkFields.length >= 3} 
                          variant="contained" 
                          endIcon={<AddIcon />}
                          className={"genericButton secondaryColor mb-2"}
                        >
                          Add link
                        </Button>
                      </div>
                      <div>
                        <div className="contentHeader mb-4 mt-4">
                          <h1>Optional WhatsApp</h1>
                        </div>
                        <p>Make it easier for other users to contact you by adding your WhatsApp info. Note: this will only be visible to registered users.</p>

                        <p>Enter a full phone number in international format. Omit any zeroes, brackets, or dashes when adding the phone number in international format.</p>
                        <p>Examples:</p>
                        <p>Use: 12345678910</p>
                        <p>Don't use: +001-(234)5678910</p>
                        <div className="textBox">
                          <TextInputField 
                            name={`whatsapp`}
                            label={`WhatsApp`}
                            type="text"
                            inputProps={{ maxLength: 255 }}
                          />
                        </div>
                        <div className="mb-4">
                          <SubmitButton />
                        </div>
                      </div>
                    </Form>
                  </>
                )}
              </>
            )
          }
    </div>
  );
}

export default HostUserDetailsScreen;