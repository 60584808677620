import React, {useState, useEffect, useRef} from 'react'

import sources from '../config/sources';
import useApi from '../hooks/useApi'
import markerApi from '../api/markerApi'
import userApi from '../api/userApi'
import "react-image-gallery/styles/css/image-gallery.css";
import ImageGallery from 'react-image-gallery';
import { Util } from '../utils/util';
import FavouriteIconButton from '../components/buttons/FavouriteIconButton';
import FriendIconButton from '../components/buttons/FriendIconButton';
import colors from '../config/colors';
import Icon from '@mdi/react'
import { mdiChatOutline, mdiFlagOutline, mdiBlockHelper } from '@mdi/js'
import { useHistory } from "react-router-dom"
import routes from '../navigation/routes';
import ReviewsComponent from '../components/other/ReviewsComponent';
import { useAuth0 } from "@auth0/auth0-react";
import HostDetailsContent from '../components/other/HostDetailsContent';
import Footer from '../components/formatting/Footer';
import LinkComponent from '../components/other/LinkComponent';
import WhatsappIconButton from '../components/buttons/WhatsappIconButton';
import Alert from '@mui/material/Alert';
import adminApi from '../api/adminApi';
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import Paywall from '../components/premium/Paywall';
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY)

function HostDetailsScreen(props) {
  const history = useHistory();
  const imageComponent = useRef(null);
  const [images, setImages] = useState([sources.DUMMY_IMAGE])
  const [isFullScreen, setIsFullScreen] = useState(false)
  const [error, setError] = useState(false)
  const getHostMarkerDetailsBySlugApi = useApi(markerApi.getHostMarkerDetailsBySlug)
  const getHostMarkerDetailsBySlugAdminApi = useApi(adminApi.getHostMarkerDetailsBySlugAdmin)
  const getHostMarkerDetailsBySlugPublicApi = useApi(markerApi.getHostMarkerDetailsBySlugPublic)
  const getProfileDetailsApi = useApi(userApi.getProfileDetails)
  const getOrCreateRevenuecatCustomerInfoApi = useApi(userApi.getOrCreateRevenuecatSubscriber)
  const createStripeCustomerAndSubscriptionApi = useApi(userApi.createStripeCustomerAndSubscription)
  const { isAuthenticated, loginWithRedirect, user } = useAuth0();
  const [listingData, setListingData] = useState(false)
  const [col, setCol] = useState(false)
  const [authStatus, setAuthStatus] = useState(false)
  const [access, setAccess] = useState(false)
  
  const fetchMyApi = async () => {
    let fetch1
    if (isAuthenticated) {
      if (user.email === sources.ADMIN_EMAIL || user.email === sources.MINI_ADMIN_EMAIL) {
        fetch1 = await getHostMarkerDetailsBySlugAdminApi.request(props.match.params.slug)
      } else {
        fetch1 = await getHostMarkerDetailsBySlugApi.request(props.match.params.slug)
      }

      const profileFetch = await getProfileDetailsApi.request()
      if (!profileFetch) { return }

      const fetchedAccess = await Util.getAccessType(profileFetch.data, getOrCreateRevenuecatCustomerInfoApi, createStripeCustomerAndSubscriptionApi)
      setAccess(fetchedAccess)
    } else {
      setAccess({
        hasAccess: true,
        type: "noAuth"
      })
      fetch1 = await getHostMarkerDetailsBySlugPublicApi.request(props.match.params.slug)
    }
    if (!fetch1) { return }

    
    if (fetch1.data.owner.image.length > 0) { setImages(fetch1.data.owner.image) }
    if (fetch1.data.owner.whatsapp) { 
      setCol("col-3")
    } else {
      setCol("col-4")
    }
    setListingData(fetch1.data)
    setAuthStatus(await Util.getAuthStatus(fetch1.data.user_id, isAuthenticated, getProfileDetailsApi))
  }

  const toggleFullScreen = function () {
    if (!isFullScreen) {
      setIsFullScreen(true)
      imageComponent.current.fullScreen()
    } else {
      setIsFullScreen(false)
      imageComponent.current.exitFullScreen()
    }
  }



  useEffect(() => {
    fetchMyApi()
  }, [])

  let screenClass
  if (authStatus === 'noAuth') { screenClass = "col-lg-10 mx-auto mt-lg-4 pb-4 detailsContainer" }
  else { screenClass = "col-lg-10 mx-auto mt-lg-4 detailsContainer screen" }

  return (
    <>
      {error && ( <p>{error}</p> )}
      {authStatus && access && (
        <>
            <div className={screenClass}>
              {listingData && col && (
                <>
                  <div className="row">
                    <div className="col-lg-6 detailsImageContainer">
                      <ImageGallery ref={imageComponent} onClick={() => toggleFullScreen()} showPlayButton={false} items={Util.setGalleryImages(images)} />
                    </div>
                    <div className="col-lg-6 detailsContent">
                      {authStatus === 'authOthersListing' && (
                        <>
                          <div className="col-4 d-flex flex-wrap w-100 mb-2" style={{justifyContent: 'right'}}>
                            <div style={{marginRight: '10px'}} onClick={() => history.push(routes.BLOCK + '/' + listingData.owner.id)} className="iconWrapper iconButton">
                              <Icon path={mdiBlockHelper} size={1} color={colors.white} />
                            </div>
                            <div onClick={() => history.push(routes.REPORT + '/' + listingData.owner.id)} className="iconWrapper iconButton">
                              <Icon path={mdiFlagOutline} size={1} color={colors.white} />
                            </div>
                          </div>
                          {new Date().getFullYear()  - Number(listingData.owner.updated_at.slice(0,4)) > 1 && (
                            <Alert sx={{ mt: 2, mb: 2, justifyContent: 'center' }} severity="warning" className='detailsAlertBox'>
                              Last login {listingData.owner.updated_at.slice(0, 10)}. Response rate may be low.
                            </Alert>
                          )}
                        </>
                      )}
                      <h1 className="centeredText">{listingData.name}</h1>
                      {authStatus === 'authOthersListing' && (
                        <p className="centeredText">
                          by {listingData.owner.username} (last login: {listingData.owner.updated_at.slice(0, 10)})
                        </p>
                      )}
                      
                      {listingData.owner.link.length !== 0 && (
                        <LinkComponent displayName={listingData.owner.username} links={listingData.owner.link} access={access}/>
                      )}
                      <div className="sectionSeperator" />
                      {(authStatus === 'authOthersListing' || authStatus === 'noAuth') && (
                        <div className="row">
                          <div className={`${col} d-flex flex-wrap align-items-center justify-content-center`}>
                            <FavouriteIconButton isFavourite={listingData.favouriteStatus} isHostMarker={true} markerID={listingData.event_id} />
                          </div>
                          {listingData.owner.whatsapp && (
                            <div className={`${col} d-flex flex-wrap align-items-center justify-content-center`}>
                              <WhatsappIconButton displayName={listingData.owner.username} number={listingData.owner.whatsapp} access={access}/>
                            </div>
                          )}
                          <div className={`${col} d-flex flex-wrap align-items-center justify-content-center`}>
                            {
                              (isAuthenticated === true) ? (
                                <div onClick={() => history.push(routes.CONVERSATION + '/' + listingData.owner.id)} className="iconWrapper iconButton">
                                  <Icon path={mdiChatOutline} size={1} color={colors.white} />
                                </div>
                              ) : (
                                <div onClick={() => loginWithRedirect({ screen_hint: 'signup' })} className="iconWrapper iconButton">
                                  <Icon path={mdiChatOutline} size={1} color={colors.white} />
                                </div>
                              )
                            }
                          </div>
                          <div className={`${col} d-flex flex-wrap align-items-center justify-content-center`}>
                            <FriendIconButton friendStatus={listingData.friendStatus} userID={listingData.owner.id} />
                          </div>
                          <div className="sectionSeperator-mini" />
                        </div>
                      )}
                      <ReviewsComponent userID={listingData.owner.id} authStatus={authStatus} />
                    </div>
                  </div>
                  <HostDetailsContent listingData={listingData}/>
                </>
              )}
            </div>
          {authStatus === 'noAuth' && ( <Footer /> )}
        </>
      )}
    </>
  );
}

export default HostDetailsScreen;