import React, { useState, useEffect, useRef } from 'react';
import useApi from '../hooks/useApi';
import chatApi from '../api/chatApi';
import TextInputField from '../forms/TextInputField';
import Form from '../forms/Form';
import SubmitIcon from '../forms/SubmitIcon';
import ConversationItem from '../components/lists/ConversationItem';
import sources from '../config/sources';
import Spinner from '../components/other/Spinner';
import routes from '../navigation/routes';
import { useHistory } from "react-router-dom"
import Text from '../components/other/Text.js'
import InputSpinner from '../components/other/InputSpinner';
import { Util } from '../utils/util';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import Chip from '@mui/material/Chip';

// // //
function HippochatScreen() {
  const history = useHistory();
  const [userConversations, setUserConversations] = useState([]);
  const [otherConversations, setOtherConversations] = useState([]);
  const sendAIMessageApi = useApi(chatApi.sendAIMessage);
  const getUserAIConversationsApi = useApi(chatApi.getUserAIConversations)
  const getOtherAIConversationsApi = useApi(chatApi.getOtherAIConversations)
  const deleteAIConversationAPI = useApi(chatApi.deleteAIConversation)
  const [loading, setLoading] = React.useState(false);
  const [saveMessageLoading, setSaveMessageLoading] = React.useState(false);
  const [inputValue, setInputValue] = useState('');

  const suggestedQuestions = [
    'How can I stay safe on my travels?',
    'Give me some nice surfing spots in South America',
    'How can I travel for cheap in Europe?',
    'How can I get a work-visa for USA?',
    'What are some nice thing to do in Vietnam?'
  ];

  const handleSuggestedQuestionClick = (question) => {
    setInputValue(question);
  };
  
  const fetchUserConversations = async () => {
    const fetch = await getUserAIConversationsApi.request();
    if (!fetch) { return }
    setUserConversations(fetch.data.conversations);
  };

  const fetchOtherConversations = async () => {
    const fetch = await getOtherAIConversationsApi.request();
    if (!fetch) { return }
    setOtherConversations(fetch.data);
  };

  const handleConversationClick = async (conversationData) => {
    const nextRoute = routes.AI_CONVERSATION + '/' + conversationData.id
    history.push(nextRoute)
  }

  const handleConversationDelete = async (conversationData) => {
    const confirmed = confirm('Delete conversation?');
    if (confirmed) {
      setLoading(true)
      const result = await deleteAIConversationAPI.request(conversationData.id)
      if (!result) { return }
      window.location.reload()
    }
  }

  const handleSubmit = async ({ message }, { resetForm }) => {
    if (message) {
      setSaveMessageLoading(true)
      resetForm({ values: '' });
      const result = await sendAIMessageApi.request({
        message: message
      });
      if (!result) { return; }
      if (result.data.error) { 
        alert(result.data.content) 
        return setSaveMessageLoading(false)
      }

      fetchUserConversations();
      setSaveMessageLoading(false)
      history.push(`${routes.AI_CONVERSATION}/${result.data.conversationID}`)
    } else {
      return alert('Please enter a message');
    }
  };

  useEffect(() => {
    fetchUserConversations();
    fetchOtherConversations();
  }, []);

  return (
    <div className="col-10 col-lg-6 mx-auto mt-4 screen">
      <Spinner visible={loading} />
      {!loading && (
        <>
          <h1 className="centeredText">Hippohelp AI Helper!</h1>
          <Text>Our very own AI Hippo will answer all of your travel-related questions! Please note that it's not trained on the functionality of this site and that the answers can be inaccurate.</Text>
          <div className='hippochatBox'>
            <h2>New conversation:</h2>
            <InputSpinner visible={saveMessageLoading} message={"Hold on! AI-generation usually takes 5-15 seconds ..."}/>
            {!saveMessageLoading && (
              <>
                <Box className="scrollableChips">
                  <Typography variant="h6">Example questions:</Typography>
                  {suggestedQuestions.map((question, index) => (
                    <Chip
                      key={index}
                      label={question}
                      onClick={() => handleSuggestedQuestionClick(question)}
                      sx={{ marginRight: 1, marginBottom: 1 }}
                    />
                  ))}
                </Box>

                <Form onSubmit={handleSubmit} initialValues={{ message: inputValue }}>
                  <div className="textBox messageInput">
                    <TextInputField
                      name="message"
                      label="Question"
                      multiline
                      inputProps={{ maxLength: 5000 }}
                    />
                    <SubmitIcon icon="send" size={1} />
                  </div>
                </Form>
              </>
            )}
          </div>
          <div>
            {userConversations.length > 0 && (
                <div className='hippochatBox'>
                  <h2>Your conversations:</h2>
                  {userConversations.map((conversationData) => (
                    <ConversationItem
                      index={conversationData.id}
                      key={conversationData.id}
                      title="Hippochat AI Helper"
                      message={conversationData.topic}
                      imageSrc={Util.generateAIHippoAvatar(conversationData.id)}
                      onClick={() => handleConversationClick(conversationData)}
                      onDelete={() => handleConversationDelete(conversationData)}
                    />
                  ))}
                </div>
              )}
          </div>
        </>
      )}
    </div>
  );
}

export default HippochatScreen;