import React from 'react'
import Text from '../components/other/Text'

function ErrorMessage ({ error }) {
  return (
    <Text className="errorText">{ error }</Text>
  )
}

export default ErrorMessage
