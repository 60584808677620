import { useEffect, useState } from 'react';
import useApi from '../../../hooks/useApi';
import userApi from '../../../api/userApi';

const staticReviewIds = [
  963,
  14601, 
  392,
  79975,
  1003,
  16645,
  4118,
  81256,
  17638,
  62014
]

export const useReviewSlider = () => {
  const getReviewsApi = useApi(userApi.getReviews);

  const getLink = (isHost, userData) => {
    return isHost ? `https://hippohelp.com/host/${userData.marker.slug}` : `https://hippohelp.com/traveller/${userData.slug}`;
  };

  const getTitle = (isHost, userData) => {
    return isHost ? userData.marker.name : userData.username;
  };

  const fetchReview = async (id) => {
    const response = await getReviewsApi.request(id);
    const fetchedData = response?.data;

    if (fetchedData) {
      return {
        text: fetchedData.references[0].reference_text,
        receiver: {
          isHost: fetchedData.references[0].to_host === 1,
          image: `https://image.hippohelp.com/uploads/profiles/${fetchedData.userData.id}/${fetchedData.userData.image[0].image_path}.png`,
          link: getLink(fetchedData.references[0].to_host, fetchedData.userData),
          title: getTitle(fetchedData.references[0].to_host, fetchedData.userData)
        },
        sender: {
          isHost: fetchedData.references[0].to_host !== 1,
          image: fetchedData.references[0].owner.image[0].uri,
          link: getLink(fetchedData.references[0].to_host !== 1, fetchedData.references[0].owner),
          title: getTitle(fetchedData.references[0].to_host !== 1, fetchedData.references[0].owner)
        }
      };
    }
    return null;
  };

  const fetchReviews = async () => {
    const fetchedReviews = []

    for (const id of staticReviewIds) {
      const review = await fetchReview(id);
      fetchedReviews.push(review);
    }
    
    return fetchedReviews;
  };

  return {
   fetchReviews,
  };
};
