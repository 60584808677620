import React, { useState, useEffect } from 'react'
import useApi from '../hooks/useApi'
import chatApi from '../api/chatApi';
import { Util } from '../utils/util';
import Form from '../forms/Form'
import TextInputField from '../forms/TextInputField'
import SubmitButton from '../forms/SubmitButton';
import { useHistory } from "react-router-dom"
import routes from '../navigation/routes';

function ReportAIMessageScreen(props) {
  const history = useHistory();
  const saveAIMessageReportApi = useApi(chatApi.saveAIMessageReport)
  const getAIMessageReportApi = useApi(chatApi.getAIMessageReport)
  const [chatApiMethod, setChatApiMethod] = useState(false)
  const [fieldValues, setFieldValues] = useState(false)

  const handleSubmit = async ({ report }) => {
    const emptyFields = []
    if (!report) { emptyFields.push('Report') }

    if (Util.checkEmptyFields(emptyFields)) {
      const saveData = {
        report: report,
        messageID: props.match.params.id,
        chatApiMethod: chatApiMethod
      }

      const saveUserReportApiFetch = await saveAIMessageReportApi.request(saveData)
      if (!saveUserReportApiFetch) { return }

      alert("Thanks for your report!")
      history.goBack()
    }
  }

  const fetchMyAPI = async () => {
    const fetch = await getAIMessageReportApi.request(props.match.params.id)
    if (!fetch) { return }

    if (fetch.data?.report_text) {
      setChatApiMethod('put')
      setFieldValues({ report: fetch.data.report_text })
    } else {
      setChatApiMethod('post')
      setFieldValues({})      
    }
  }

  useEffect(() =>  {
    fetchMyAPI()
  }, [])

  return (
    <div className="col-10 col-lg-6 mx-auto mt-4 screen">
      {fieldValues && (
        <>
          <Form onSubmit={handleSubmit} initialValues={fieldValues}>
            <div className="textBox">
              <TextInputField 
                name='report' 
                label='Report' 
                inputProps={{ maxLength: 1000 }} 
                multiline
              />
            </div>
            <div>
              <SubmitButton />
            </div>
          </Form>
        </>
      )}
    </div>
  );
}

export default ReportAIMessageScreen;