import React, { useState, useEffect } from 'react'
import Box from '@mui/material/Box';
import { Typography } from '@material-ui/core';
import Divider from '../components/formatting/Divider';
import Footer from '../components/formatting/Footer';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import Button from '@material-ui/core/Button';
import { useAuth0 } from "@auth0/auth0-react";
import HTMLDialog from '../components/dialogs/HTMLDialog'
import CategoryPath from "../components/blog/CategoryPath.js"
import PostsSection from '../components/blog/PostSection';
import BlogPagination from '../components/blog/BlogPagination';
import { useParams } from "react-router-dom";

function BlogSubSubCategoryScreen(props) {
  const [categories, setCategories] = useState(false)
  const [currentCategory, setCurrentCategory] = useState()
  const [posts, setPosts] = useState([])
  const { loginWithRedirect } = useAuth0();
  const [getAppDialogOpen, setGetAppDialogOpen] = useState(false)
  const { subSubCategory } = useParams();

  const handleLogin = function (nextScreen) {
    if (window.innerWidth < 1199) {
      setGetAppDialogOpen(true)
    } else {
      if (nextScreen === "signup") {
        loginWithRedirect({ screen_hint: 'signup' })
      } else {
        loginWithRedirect()
      }
    }
  }

  const handleBlogPaginationOnFetch = function (fetch) {
    setCategories({
      category_slug: fetch.data.category_slug,
      category_name: fetch.data.category_name,
      sub_category_slug: fetch.data.sub_category_slug,
      sub_category_name: fetch.data.sub_category_name,
      sub_sub_category_slug: fetch.data.sub_sub_category_slug,
      sub_sub_category_name: fetch.data.sub_sub_category_name
    });
    setPosts(fetch.data.postsOfSubSubCategory);
    setCurrentCategory(fetch.data)
  }

  return (
    <>   
      <HTMLDialog 
        title="Get the app!"
        content="getApp"
        passedOpen={getAppDialogOpen} 
        onClose={() => {setGetAppDialogOpen(false)}}
        data={{
          authActions: true
        }}
      />
      <HelmetProvider>
        <Helmet>
          <title>Hippohelp | Blog</title>
          <meta name="description" content="The Hipphelp blog!" />
        </Helmet>
      </HelmetProvider>
      <Box className='contentBox' minHeight={"400px"}>
        {categories && currentCategory && (
          <Box>
            <Box className="pathAndCategory">
              <Box>
                <CategoryPath currentCategory={currentCategory} />
              </Box>
            </Box>
            <Box flex={1} p={4}>
              <Typography component="h1" variant="h4">{categories.sub_sub_category_name}</Typography>
              <Divider />
              <PostsSection posts={posts} />
            </Box>
          </Box>
        )}
        <BlogPagination subSubCategorySlug={subSubCategory} onFetch={handleBlogPaginationOnFetch} />
      </Box>
      <Footer />
    </>
  )
}

export default BlogSubSubCategoryScreen