import React, { useEffect } from 'react';
import { useAuth0 } from "@auth0/auth0-react";

function LogoutScreen() {
  const { logout } = useAuth0();

  useEffect(() => {
    logout({ returnTo: window.location.origin })
  }, [])
  
  return (
    <div className="col-10 col-lg-6 mx-auto mt-4 screen">
      <p>Logging out...</p>
    </div>
  );
}

export default LogoutScreen;