import React, { useState, useEffect } from 'react';
import useApi from '../hooks/useApi'
import userApi from '../api/userApi'
import Paywall from '../components/premium/Paywall';
import Cancel from '../components/premium/Cancel';
import { Elements } from "@stripe/react-stripe-js";
import { Util } from '../utils/util';
import { loadStripe } from "@stripe/stripe-js";
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY)

function PremiumScreen ({title = "Hippohelp Premium"}) {
  const [access, setAccess] = useState(false)
  const getProfileDetailsApi = useApi(userApi.getProfileDetails)
  const getOrCreateRevenuecatCustomerInfoApi = useApi(userApi.getOrCreateRevenuecatSubscriber)
  const createStripeCustomerAndSubscriptionApi = useApi(userApi.createStripeCustomerAndSubscription)

  const fetchData = async () => {
    const fetch = await getProfileDetailsApi.request()
    if (!fetch) { return }
    setAccess(await Util.getAccessType(fetch.data, getOrCreateRevenuecatCustomerInfoApi, createStripeCustomerAndSubscriptionApi))
  };

  useEffect(() => {
    fetchData()
  }, []);

  return (
      <>
        <p>Since Hippohelp has been aquired by helpstay all premium memberships have been canceled. People who had an active Premium subscription can contact shay@helpstay.com for a free premium HelpStay account for the remaining billing cycle.</p>
      </>
  )
}

export default PremiumScreen;