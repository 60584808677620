import React, { useState } from 'react';

function ErrorScreen(props) {
  const [secondsRemaining, setSecondsRemaining] = useState(process.env.REACT_APP_ERROR_SECONDS)

  const interval = setInterval(function () {
    clearInterval(interval)
    if (secondsRemaining !== 0) {
      setSecondsRemaining(secondsRemaining - 1)
    } else {
      window.location.href = "/"
    }
  }, 1000)

  return (
    <div className="col-10 col-lg-6 mx-auto mt-4 screen">
      <p>Sorry, an error occurred :(</p>
      <p>Redirecting you to the front-page in <span className="primaryBold">{secondsRemaining}</span> seconds ...</p>
    </div>
  );
}

export default ErrorScreen;