import ApiClient from './ApiClient.js'

const getAllMarkers = async function (...args) { 
  const apiClient = new ApiClient(...args)
  return apiClient.get('/admin/all-markers')
}

const getHostMarkersUnderReview = async function (...args) {
  const apiClient = new ApiClient(...args)
  return apiClient.get(`/admin/hostmarkers-under-review`)
}

const getTravelMarkersUnderReview = async function (...args) {
  const apiClient = new ApiClient(...args)
  return apiClient.get(`/admin/travelmarkers-under-review`)
}

const approveHostMarker = async function (...args) {
  const apiClient = new ApiClient(...args)
  apiClient.setAdminPasswordHeader(args[1])
  const data = { ownerID: args[0] }
  return apiClient.patch(`/admin/approve-hostmarker`, data)
}

const deleteHostMarker = async function (...args) {
  const apiClient = new ApiClient(...args)
  apiClient.setAdminPasswordHeader(args[1])
  return apiClient.delete(`/admin/hostmarker/${args[0]}`)
}

const approveTravelMarker = async function (...args) {
  const apiClient = new ApiClient(...args)
  apiClient.setAdminPasswordHeader(args[1])
  const data = { ownerID: args[0] }
  return apiClient.patch(`/admin/approve-travelmarker`, data)
}

const deleteTravelMarker = async function (...args) {
  const apiClient = new ApiClient(...args)
  apiClient.setAdminPasswordHeader(args[1])
  return apiClient.delete(`/admin/travelmarker/${args[0]}`)
}

const deleteUser = async function (...args) {
  const apiClient = new ApiClient(...args)
  apiClient.setAdminPasswordHeader(args[1])
  return apiClient.delete(`/admin/user/${args[0]}`)
}

const deleteOldTravelmarkers = async function (...args) {
  const apiClient = new ApiClient(...args)
  apiClient.setAdminPasswordHeader(args[0])
  return apiClient.delete(`/admin/old-travelmarkers`)
}

const getUnsetCountryId = async function (...args) {
  const apiClient = new ApiClient(...args)
  apiClient.setAdminPasswordHeader(args[0])
  return apiClient.get(`/admin/unset-country-id`)
}

const updateCountryId = async function (...args) {
  const apiClient = new ApiClient(...args)
  apiClient.setAdminPasswordHeader(args[2])

  const data = {
    userId : args[0],
    countryId : args[1]
  }
  
  return apiClient.put(`/admin/update-country-id`, data)
}

const updateCountryIds = async function (...args) {
  const apiClient = new ApiClient(...args)
  apiClient.setAdminPasswordHeader(args[0])
  return apiClient.put(`/admin/update-country-ids`)
}

const customAction = async function (...args) {
  const apiClient = new ApiClient(...args)
  apiClient.setAdminPasswordHeader(args[1])
  const data = { data: args[0] }
  return apiClient.post(`/admin/custom-action`, data)
}

const addReferences = async function (...args) {
  const apiClient = new ApiClient(...args)
  apiClient.setAdminPasswordHeader(args[1])
  const data = args[0]
  return apiClient.post(`/admin/add-references`, data)
}

const addNoReferences = async function (...args) {
  const apiClient = new ApiClient(...args)
  apiClient.setAdminPasswordHeader(args[1])
  const data = args[0]
  return apiClient.post(`/admin/add-no-references-to-host`, data)
}

const getHostMarkerWithoutReference = async function (...args) {
  const apiClient = new ApiClient(...args)
  return apiClient.get(`/admin/hostmarkers-without-references`)
}

const changeMarkerPosition = async function (...args) {
  const apiClient = new ApiClient(...args)
  apiClient.setAdminPasswordHeader(args[1])
  const data = args[0]
  return apiClient.post(`/admin/change-marker-position`, data)
}

const getHostMarkerDetailsBySlugAdmin = async function (...args) { 
  const apiClient = new ApiClient(...args)
  return apiClient.get(`/admin/host-marker-details-by-slug/${args[0]}`)
}

const getTravelUserDetailsBySlugAdmin = async function (...args) { 
  const apiClient = new ApiClient(...args)
  return apiClient.get(`/admin/travel-user-details-by-slug/${args[0]}`)
}

export default {
  getAllMarkers,
  getHostMarkersUnderReview,
  getTravelMarkersUnderReview,
  approveHostMarker,
  deleteHostMarker,
  approveTravelMarker,
  deleteTravelMarker,
  deleteUser,
  deleteOldTravelmarkers,
  getUnsetCountryId,
  updateCountryId,
  updateCountryIds,
  customAction,
  addReferences,
  addNoReferences,
  getHostMarkerWithoutReference,
  changeMarkerPosition,
  getHostMarkerDetailsBySlugAdmin,
  getTravelUserDetailsBySlugAdmin
}
