import React, {useState} from 'react';
import Modal from "react-bootstrap/Modal";
import Button from '@material-ui/core/Button';

function GenericModal({open, title, onHide, content, dialogClassName="genericModal"}) {
  
  return (
    <Modal animation={false} show={open} onHide={onHide} dialogClassName={dialogClassName}>
      <Modal.Body>
        <h3>{title}</h3>
        
        {content}
      </Modal.Body>
      <Modal.Footer>
        <div>
          <Button onClick={onHide}>Close</Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}

export default GenericModal;