import React, {useState, useEffect} from 'react';
import { useHistory } from "react-router-dom"
import Icon from '@mdi/react'
import { mdiImage, mdiChat, mdiRocketLaunch, mdiHeart, mdiEmoticonHappy, mdiLogout, mdiEarth, mdiArrowRightBold, mdiLightbulb, mdiStar, mdiRobotExcited} from '@mdi/js'
import useApi from '../hooks/useApi'
import userApi from '../api/userApi'
import markerApi from '../api/markerApi';
import colors from '../config/colors'
import routes from '../navigation/routes';
import MenuItem from '../components/lists/MenuItem';
import MenuItemDetails from '../components/lists/MenuItemDetails';
import { Util } from '../utils/util';
import { useAuth0 } from "@auth0/auth0-react";
import HTMLDialog from '../components/dialogs/HTMLDialog';
import sources from "../config/sources"

function AccountScreen() {
  const history = useHistory();
  const [userData, setUserData] = useState(false)
  const getProfileDetailsApi = useApi(userApi.getProfileDetails)
  const getHostMarkerDetailsAnyApprovalApi = useApi(markerApi.getHostMarkerDetailsAnyApproval)
  const { logout } = useAuth0();
  const [publicRoute, setPublicRoute] = useState(false)
  const getOrCreateRevenuecatCustomerInfoApi = useApi(userApi.getOrCreateRevenuecatSubscriber)
  const createStripeCustomerAndSubscriptionApi = useApi(userApi.createStripeCustomerAndSubscription)
  const checkForUnreadMessagesApi = useApi(userApi.checkForUnreadMessages)
  const [access, setAccess] = useState(false)
  const [getAppDialogOpen, setGetAppDialogOpen] = useState(false)
  const [menuItems, setMenuItems] = useState(false)

  const checkForUnreadMessages = async () => {
    const fetch = await checkForUnreadMessagesApi.request()
    if (!fetch) { return }
    if (fetch.data.hasUnreadMessages) {
      return true
    } else {
      return false
    }
  }

  useEffect(() => {
    async function fetchMyAPI() {
      const fetch = await getProfileDetailsApi.request()
      if (!fetch) { return }

      const publicRoute = await Util.getDetailsRoute(fetch.data, getHostMarkerDetailsAnyApprovalApi)
      if (!publicRoute) { return }

      const access = await Util.getAccessType(fetch.data, getOrCreateRevenuecatCustomerInfoApi, createStripeCustomerAndSubscriptionApi)
      setAccess(access)

      setPublicRoute(publicRoute)
      setUserData(fetch.data)
      
      const itemsArray = [
        {
          title: 'Messages',
          icon: {
            iconComponent: <Icon path={mdiChat} size={1} color={colors.white} />,
            iconWrapperClassName: "menuIconWrapper"
          },
          targetScreen: routes.CONVERSATIONS,
          withDot: await checkForUnreadMessages(),
        },
        {
          title: 'Favourites',
          icon: {
            iconComponent: <Icon path={mdiHeart} size={1} color={colors.white} />,
            iconWrapperClassName: "menuIconWrapper"
          },
          targetScreen: routes.FAVOURITES
        },
        {
          title: 'Friends',
          icon: {
            iconComponent: <Icon path={mdiEmoticonHappy} size={1} color={colors.white} />,
            iconWrapperClassName: "menuIconWrapper"
          },
          targetScreen: routes.FRIENDS
        },
        {
          title: 'View Public Profile',
          icon: {
            iconComponent: <Icon path={mdiEarth} size={1} color={colors.white} />,
            iconWrapperClassName: "menuIconWrapper"
          },
          targetScreen: publicRoute
        },
        {
          title: 'Feedback',
          icon: {
            iconComponent: <Icon path={mdiLightbulb} size={1} color={colors.white} />,
            iconWrapperClassName: "menuIconWrapper"
          },
          targetScreen: routes.HIPPOHELP_FEEDBACK
        },
        {
          title: 'More',
          icon: {
            iconComponent: <Icon path={mdiArrowRightBold} size={1} color={colors.white} />,
            iconWrapperClassName: "menuIconWrapper"
          },
          targetScreen: routes.MORE
        }
      ]

      if (window.innerWidth < 1199) {
        itemsArray.unshift({
          title: 'Get the app!',
          icon: {
            iconComponent: <Icon path={mdiStar} size={1} color={colors.white} />,
            iconWrapperClassName: "menuIconWrapper"
          },
          action:() => setGetAppDialogOpen(true)
        })
      }

      if (access.type !== 'freeAccessOldTravellerVip') {
        itemsArray.unshift({
          title: 'Premium',
          icon: {
            iconComponent: <Icon path={mdiRocketLaunch} size={1} color={colors.white} />,
            iconWrapperClassName: "menuIconWrapper"
          },
          targetScreen: routes.PREMIUM
        })
      }

      if (fetch.data?.serverMetadata?.hippochatEnabled === true) {
        const feedbackIndex = itemsArray.findIndex(item => item.title === 'Feedback');
        itemsArray.splice(feedbackIndex, 0, {
          title: 'AI Travel Guide',
          icon: {
            iconComponent: <Icon path={mdiRobotExcited} size={1} color={colors.white} />,
            iconWrapperClassName: "menuIconWrapper"
          },
          targetScreen: routes.HIPPOCHAT
        })
      }

      itemsArray.unshift({
        title: 'Images',
        icon: {
          iconComponent: <Icon path={mdiImage} size={1} color={colors.white} />,
          iconWrapperClassName: "menuIconWrapper"
        },
        targetScreen: routes.IMAGES
      })

      setMenuItems(itemsArray)
    }
    fetchMyAPI()
  }, [])

  return (
    <div className="col-10 col-lg-6 mx-auto mt-4 screen">
      {userData && menuItems && (
        <>
        {userData.username === "1feb24" && (
          <p onClick={() => {
            // console.log( sources.frontendUrl + routes.PAYMENT_SUCCESSFUL)
            // history.push({ pathname: routes.PAYMENT_SUCCESSFUL, state: userData })
            window.location.href = routes.PAYMENT_SUCCESSFUL
          }}>
            asdsa
          </p>
        )}
          <HTMLDialog 
            title="Get the app!"
            content="getApp"
            passedOpen={getAppDialogOpen} 
            onClose={() => {setGetAppDialogOpen(false)}}
          />
          <div>
            <MenuItemDetails
              title={userData.username}
              source={Util.getFirstImageSource(userData.image)}
              onClick={() => history.push({ pathname: routes.CHOOSE_ACCOUNT_TYPE, state: userData })}
              iconWrapperClassName={"menuIconWrapperDetails"}
            />
          </div>
          <div>
            <>
              {
                menuItems.map(function (menuItem, index) {
                  return (
                  <MenuItem
                    key={index}
                    title={menuItem.title}
                    IconComponent={ menuItem.icon.iconComponent }
                    onClick={menuItem.action ? () => menuItem.action() : () => history.push(menuItem.targetScreen)}
                    iconWrapperClassName={menuItem.icon.iconWrapperClassName}
                    withDot={menuItem.withDot}
                  />)
                })
              }
              <MenuItem
                title={"Log Out"}
                IconComponent={ <Icon path={mdiLogout} size={1} color={colors.white} /> }
                onClick={() => logout({ returnTo: window.location.origin })}
                iconWrapperClassName={"menuIconWrapper"}
              />
            </>
          </div>
        </>
      )}
    </div>
  );
}

export default AccountScreen;