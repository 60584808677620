import React, { useState, useEffect } from 'react'
import useApi from '../../hooks/useApi'
import blogApi from '../../api/blogApi';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { useLocation, useHistory } from "react-router-dom";
import routes from '../../navigation/routes';

function BlogPagination({
  onFetch,
  categorySlug = false,
  subCategorySlug = false,
  subSubCategorySlug = false
}) {
  
  const getCategoryBySlugApi = useApi(blogApi.getCategoryBySlug)
  const getSubCategoryBySlugApi = useApi(blogApi.getSubCategoryBySlug)
  const getSubSubCategoryBySlugApi = useApi(blogApi.getSubSubCategoryBySlug)
  const getCategoriesAndAllPostsApi = useApi(blogApi.getCategoriesAndAllPosts)

  const [posts, setPosts] = useState([])
  const history = useHistory();
  const { search, pathname } = useLocation();

  const query = new URLSearchParams(search);
  const [currentPage, setCurrentPage] = useState(() => {
    if (query.get('page')) {
      if (Number(query.get('page')) < 1) {
        return 1
      } else {
        return Number(query.get('page'))
      }
    } else {
      return 1
    }
  })
  const postsPerPage = 5

  const getDisabled = function () {
    if (currentPage > 999 || posts.length < postsPerPage) {
      return true
    } else {
      return false
    }
  }

  useEffect(() => {
    async function fetchMyApi() {
      const offset = (currentPage - 1) * postsPerPage;
      let fetch
      if (categorySlug) {
        fetch = await getCategoryBySlugApi.request(categorySlug, offset);
        setPosts(fetch.data.postsOfCategory);
      } else if (subCategorySlug) {
        fetch = await getSubCategoryBySlugApi.request(subCategorySlug, offset);
        setPosts(fetch.data.postsOfSubCategory);
      } else if (subSubCategorySlug) {
        fetch = await getSubSubCategoryBySlugApi.request(subSubCategorySlug, offset);
        setPosts(fetch.data.postsOfSubSubCategory);
      } else {
        fetch = await getCategoriesAndAllPostsApi.request(offset);
        setPosts(fetch.data.allPosts);
      }
      if (!fetch) { return }
      onFetch(fetch)
    }
    
    fetchMyApi();
  }, [currentPage]);

  return (
    <Stack direction="row" spacing={1} className="navigationButtons">
      <IconButton
        disabled={currentPage < 2 ? true : false}
        size={"large"}
        aria-label="previous"
        color="primary"
        onClick={ () => {
            setCurrentPage(currentPage - 1)
            history.push(routes.BLOG + `?page=${currentPage - 1}`) 
        }}>
      <NavigateBeforeIcon />
      </IconButton>
        {currentPage}
      <IconButton 
        disabled={getDisabled()}
        size={"large"}
        aria-label="next"
        color="primary"
        onClick={ () => {
          setCurrentPage(currentPage + 1);
          history.push(pathname + `?page=${currentPage + 1}`);
        }}>
        <NavigateNextIcon />
      </IconButton>
    </Stack>
  )
}

export default BlogPagination
