import React, { useEffect } from 'react'
import Snackbar from '@mui/material/Snackbar';
import Slide from '@mui/material/Slide';
import MuiAlert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';


const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function TransitionDown(props) {
  return <Slide {...props} direction="down" />;
}

export function Toast({message, open, severity, handleClose = false}) {

  let closeButton
  if (handleClose) {
    closeButton = (
      <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
        <CloseIcon fontSize="small" />
      </IconButton>
    )
  }

  return (
    <Snackbar
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      open={open}
      TransitionComponent={TransitionDown}
      message={message}
    >
      <Alert 
        severity={severity} 
        sx={{ width: '100%' }}
        action={closeButton}
      >
        {message}
      </Alert>
    </Snackbar>
  )
}