import React from 'react'
import Icon from '@mdi/react'
import Text from '../other/Text'
import { mdiFlagOutline, mdiDeleteOutline } from '@mdi/js';
import colors from '../../config/colors';
import { mdiChat } from '@mdi/js'

function ConversationItem ({ 
  title, 
  message,
  imageSrc,
  onClick,
  onDelete,
  onReport,
  deleted,
  icon
}) {

  let outerContainerClassName
  if (onClick) {
    outerContainerClassName="conversationItemContainer clickable"
  } else {
    outerContainerClassName="conversationItemContainer"
  }

  return (
    <div className={outerContainerClassName} onClick={onClick}>
      {imageSrc && (
        <div className={"conversationImageContainer"}>
          <img src={imageSrc} />
        </div>
      )}
      {icon && (
        <div className={"avatarIcon"}>
          <Icon path={mdiChat} size={2} color={colors.white} />
        </div>
      )}
      <div className={"conversationContentContainer"}>
        <div className='conversationContentContainerHeaderRow'>
          <Text classes="boldText">{title}</Text>
          {onDelete && !deleted && (
            <div className={"clickableIcon"} onClick={(event) => {
                event.stopPropagation()
                onDelete()
              }}>
              <Icon path={mdiDeleteOutline} color={colors.blackTransparent} size={'30px'} />
            </div>
          )}
          {onReport && !deleted && (
            <div className={"clickableIcon"} onClick={(event) => {
                event.stopPropagation()
                onReport()
              }}>
              <Icon path={mdiFlagOutline} color={colors.blackTransparent} size={'30px'} />
            </div>
          )}
        </div>
        <div>
          <Text>{message}</Text>
        </div>
      </div>
    </div>
  )
}

export default ConversationItem
