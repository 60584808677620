import React, { useEffect } from 'react';
import { useAuth0 } from "@auth0/auth0-react";

function LogoutAuthErrorScreen() {
  const { logout } = useAuth0();

  useEffect(() => {
    logout({ returnTo: window.location.origin + '?error=authError' })
  }, [])
  
  return (
    <div className="col-10 col-lg-6 mx-auto mt-4 screen">
      <p>Please login / create account with another authentication method.</p>
    </div>
  );
}

export default LogoutAuthErrorScreen;