import ApiClient from './ApiClient.js'

const setMainImage = async function (...args) {
  const apiClient = new ApiClient(...args)
  const data = { id: args[0] }

  return apiClient.post('/image/set-main-image', data)
}

const uploadImage = async function (...args) {
  const apiClient = new ApiClient(...args)
  const data = new FormData();
  data.append("nativeimage", args[0])

  return apiClient.upload('/image/upload-image', data, args[1])
}

const deleteImage = async function (...args) {
  const apiClient = new ApiClient(...args)
  return apiClient.delete(`/image/delete-image/${args[0]}`)
}

const setSocialMainImage = async function (...args) {
  const apiClient = new ApiClient(...args)
  return apiClient.post('/image/set-social-main-image')
}

export default {
  uploadImage,
  deleteImage,
  setMainImage,
  setSocialMainImage
}
