import React, {useState} from 'react';
import Modal from "react-bootstrap/Modal";
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';

function ActionModal({open, title, actions, onHide, passwordAction, inputAction}) {
  const [password, setPassword] = React.useState('');
  const [dataInput, setDataInput] = React.useState('');
  return (
    <Modal animation={false} show={open} onHide={onHide}>
      <Modal.Body>{title}</Modal.Body>
      <Modal.Footer>
        <div>
          <Button onClick={onHide}>Cancel</Button>
        </div>
        <>
          {passwordAction && (
            actions.map(function (object, index) {
              return (
                <div key={index}>
                  <>
                    <TextField
                      type="password"
                      id="standard-basic"
                      label="Admin password"
                      value={password}
                      onChange={(event) => { setPassword(event.target.value); }}
                    />
                    {inputAction && (
                      <>
                        <TextField
                          type="id"
                          id="standard-basic"
                          label="Extra input"
                          value={dataInput}
                          onChange={(event) => { setDataInput(event.target.value); }}
                        />
                      </>
                    )}
                    {inputAction && (
                      <Button onClick={() => { onHide(); object.action(dataInput, password)  }}>{object.actionName}</Button>
                    )}
                    {!inputAction && (
                      <Button onClick={() => { onHide(); object.action(password) }}>{object.actionName}</Button>
                    )}
                  </>
                </div>
              )
            })
          )}
          {!passwordAction && (
            actions.map(function (object, index) {
              return (
                <Button key={index} onClick={() => { onHide(); object.action() }}>{object.actionName}</Button>
              )
            })
          )}
        </>'
      </Modal.Footer>
    </Modal>
  );
}

export default ActionModal;