import React from 'react'
import colors from '../../config/colors'

function UnsetDetailsContent ({ownHostMarkerIsUnderReview}) {
  return (
    <div className="detailsContent">
      <div className="row">
          {ownHostMarkerIsUnderReview === true && (
            <h3 style={{textAlign: 'center', color: colors.primary, marginTop: 20}}>Your details will be visible here once they approved.</h3>
          )}
          {ownHostMarkerIsUnderReview !== true && (
            <>
              <h2>Details</h2>
              <p>No details set yet.</p>
            </>
          )}
      </div>
    </div>
  )
}

export default UnsetDetailsContent
