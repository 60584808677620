import React, { useState, useEffect } from 'react'
import useApi from '../hooks/useApi'
import userApi from '../api/userApi';
import Button from '@material-ui/core/Button';
import { useHistory } from "react-router-dom"
import Spinner from '../components/other/Spinner';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import routes from '../navigation/routes';

function NotificationsScreen() {
  const allowBrowserNotifications = false
  const history = useHistory(); 
  const getProfileDetailsApi = useApi(userApi.getProfileDetails)
  const setNotificationLevelApi = useApi(userApi.setNotificationLevel)
  const [loading, setLoading] = useState(true)
  const [appEnabled, setAppEnabled] = useState(false);
  const [emailEnabled, setEmailEnabled] = useState(false);
  const [browserEnabled, setBrowserEnabled] = useState(false);

  async function handleSubmit() {
    setLoading(true)
    const submitArray = []
    emailEnabled ? submitArray[0] = '1' : submitArray[0] = '0'
    appEnabled ? submitArray[1] = '1' : submitArray[1] = '0'
    browserEnabled ? submitArray[2] = '1' : submitArray[2] = '0'
    const fetch = await setNotificationLevelApi.request(submitArray.join(''))
    if (!fetch) { return }
    history.replace(routes.ACCOUNT)
  }

  async function fetchMyAPI() {
    const profileFetch = await getProfileDetailsApi.request()
    if (!profileFetch) { return }
    const splittedLevel = profileFetch.data.notification_level.split('')
    setEmailEnabled(splittedLevel[0] === '1' ? true : false)
    setAppEnabled(splittedLevel[1] === '1' ? true : false)
    setBrowserEnabled(splittedLevel[2] === '1' ? true : false)
    setLoading(false)
  }

  useEffect(() => {
    fetchMyAPI()
  }, [])

  return (
    <div className="col-10 col-lg-6 mx-auto mt-4 screen">
      <Spinner visible={loading} withMessage={false} />
      {!loading && (
        <>
          <div className="row mb-4">
            <div className="contentHeader">
              <h1>Message Notifications</h1>
            </div>
          </div>
          <p>Use the fields below to turn on or off notifications for messages from other users.</p>
          <div className="selectInput">
            <FormControl fullWidth>
              <InputLabel className="customFormControlLabel" id="select-label1">Email notifications</InputLabel>
              <Select
                className="customFormControlPlaceholder"
                labelId="select-label1"
                id="select-label1"
                value={emailEnabled}
                label="iOS / Android App notifications"
                onChange={(event) => {setEmailEnabled(event.target.value)}}
              >
                <MenuItem value={true}>Enabled</MenuItem>
                <MenuItem value={false}>Disabled</MenuItem>
              </Select>
            </FormControl>
          </div>
          <div className="selectInput">
            <FormControl fullWidth>
              <InputLabel className="customFormControlLabel" id="select-label1">iOS / Android App notifications</InputLabel>
              <Select
                className="customFormControlPlaceholder"
                labelId="select-label1"
                id="select-label1"
                value={appEnabled}
                label="Email notifications"
                onChange={(event) => {setAppEnabled(event.target.value)}}
              >
                <MenuItem value={true}>Enabled</MenuItem>
                <MenuItem value={false}>Disabled</MenuItem>
              </Select>
            </FormControl>
          </div>
          {allowBrowserNotifications && (
            <div className="selectInput">
              <FormControl fullWidth>
                <InputLabel className="customFormControlLabel" id="select-label1">Browser notifications</InputLabel>
                <Select
                  className="customFormControlPlaceholder"
                  labelId="select-label1"
                  id="select-label1"
                  value={browserEnabled}
                  label="iOS / Android App notifications"
                  onChange={(event) => {setBrowserEnabled(event.target.value)}}
                >
                  <MenuItem value={true}>Enabled</MenuItem>
                  <MenuItem value={false}>Disabled</MenuItem>
                </Select>
              </FormControl>
            </div>
          )}
          <Button className={"genericButton genericButtonWidth"} onClick={ handleSubmit }>Save</Button>
        </>
      )}
    </div>
  );
}

export default NotificationsScreen;