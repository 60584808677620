import React from 'react'

function CssLoader () {
  return (
    <>
      <link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap" />
      <link rel="stylesheet" href="https://fonts.googleapis.com/icon?family=Material+Icons" />
      <link rel="stylesheet" href="../css/app.css" />
      <link rel="stylesheet" href="../css/mobile.css" />
      <link rel="stylesheet" href="../css/desktop.css" />
      <link rel="stylesheet" href="../css/mobileSizes.css" />
    </>
  )
}

export default CssLoader
