const workTypeItems = [
  { name: 'Animal care', id: 1, },
  { name: 'Art project', id: 2, },
  { name: 'Babysitting / Childcare', id: 3, },
  { name: 'Building', id: 4, },
  { name: 'Charity work', id: 5, },
  { name: 'Cooking / Shopping', id: 6, },
  { name: 'Elderly care', id: 7, },
  { name: 'Farmstay', id: 8, },
  { name: 'Gardening', id: 9, },
  { name: 'General maintenance', id: 10, },
  { name: 'Help in the house', id: 11, },
  { name: 'Help with computers / internet', id: 12, },
  { name: 'Help with eco project', id: 13, },
  { name: 'Helping with tourists', id: 14, },
  { name: 'Language practice', id: 15, },
  { name: 'Teaching', id: 16, },
  { name: 'Other', id: 17, },
]

export default workTypeItems
