import React, { useEffect } from 'react';
import { useAuth0 } from "@auth0/auth0-react";

function LoginScreen() {
  const { loginWithRedirect } = useAuth0();
  

  useEffect(() => {
    loginWithRedirect();
  }, [])
  
  return (
    <div className="col-10 col-lg-6 mx-auto mt-4 screen">
      <p>Logging in...</p>
    </div>
  )
}

export default LoginScreen;