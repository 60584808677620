export const staticReviews = [
    {
        "text": "Hello Sue, Hanzel, Aaron and Becky.\n\n The 6 weeks that I spent on your farm were way better than I imagined it, I just have to thank you, your family and all the crew the welcomed me so amazingly. IT WAS THE BEST EXPERIENCE OF MY LIFE. I not only made friends there: I was part of a family. I learned a lot about life, and the farm life as well. I improved a lot my English too, thanks for the patience and for the love given to me. I will love you all forever.\n\nBest wishes, Letícia from Brazil. ",
        "receiver": {
            "isHost": true,
            "image": "https://image.hippohelp.com/uploads/profiles/963/1main618449844771.png",
            "link": "https://hippohelp.com/host/kern-family-farm",
            "title": "Kern Family Farm"
        },
        "sender": {
            "isHost": false,
            "image": "https://image.hippohelp.com/uploads/profiles/4520/1main360648384047.png",
            "link": "https://hippohelp.com/traveller/leticia",
            "title": "Letícia"
        }
    },
    {
        "text": "We are happy that Deev choose to come and give a hand here. He is very handy,  skilled gardener and knows how to move in the woods, he likes to participate actively in what is going on araund him, . A very clean and well organize person. Also used to be outdoors and easy adapting. So such a present!! To bad he only had 2 weeks to be araund!! We hope to see him again araund here some time soon!! Thanks!",
        "receiver": {
            "isHost": false,
            "image": "https://image.hippohelp.com/uploads/profiles/14601/109792581001.png",
            "link": "https://hippohelp.com/traveller/deev-datta",
            "title": "deev_datta"
        },
        "sender": {
            "isHost": true,
            "image": "https://image.hippohelp.com/uploads/profiles/9025/1main644622413297.png",
            "link": "https://hippohelp.com/host/helpin-agroecologic-project-by-the-river",
            "title": "Helpin agroecologic project by the river !!"
        }
    },
    {
        "text": "I spent 10 days at Mike's and Zeta's little piece of paradise up in the mountains. Very kind and free people, living busy life with the restaurant, farm, garden, olives, woods, parenthood and exhibitions. But you know what, they rock at it and it's a pleasure to be part of it. Zeta is strong woman and magician in the kitchen, and Mike's energy is just contagious :)  And Athina and Yannis will brighten your days there. \n\nP.S. Thank you for everything and hope to see you again :)",
        "receiver": {
            "isHost": true,
            "image": "https://image.hippohelp.com/uploads/profiles/392/994087515232.png",
            "link": "https://hippohelp.com/host/sunrise-farm",
            "title": "sunrise farm"
        },
        "sender": {
            "isHost": false,
            "image": "https://image.hippohelp.com/uploads/profiles/616/1main296553975835.png",
            "link": "https://hippohelp.com/traveller/milenophyta",
            "title": "milenophyta"
        }
    },
    {
        "text": "Kris joined us for the month of June and was friendly, chill, and a good worker. We originally thought he didn't do much cooking, and then all of a sudden he was on a circuit of making yummy empanadas and breads and desserts. On the farm, he helped with weeding, cardboarding, planting, bicycle maintenance, scything, planing wood,... pretty much anything except for working above ground level haha! Look forward to meeting up again.",
        "receiver": {
            "isHost": false,
            "image": "https://image.hippohelp.com/uploads/profiles/79975/206343713638.png",
            "link": "https://hippohelp.com/traveller/krisgormaz",
            "title": "krisgormaz"
        },
        "sender": {
            "isHost": true,
            "image": "https://image.hippohelp.com/uploads/profiles/1003/1main419159801748.png",
            "link": "https://hippohelp.com/host/eden-in-seasons-garden-of-eating",
            "title": "Eden in Season's Garden of Eating"
        }
    },
    {
        "text": "I had an amazing time with Chris and Joanna. Awesome vegan food, hard but fun work, comfortable accomodation, incredible view and 2 welcoming host who are really passionate about their project. I never missed anything. They made sure of that. And they are really grateful for the work done. It was my first work exchange and I'm very glad it was with Chris and Joanna. Thank you!",
        "receiver": {
            "isHost": true,
            "image": "https://image.hippohelp.com/uploads/profiles/4118/1main776316516207.png",
            "link": "https://hippohelp.com/host/come-to-beautiful-lunigiana",
            "title": "Come to beautiful Lunigiana"
        },
        "sender": {
            "isHost": false,
            "image": "https://image.hippohelp.com/uploads/profiles/11986/1main622912583766.png",
            "link": "https://hippohelp.com/traveller/benjamin-harvey",
            "title": "Benjamin Harvey"
        }
    },
    {
        "text": "Had a great time helping Iris and Jacob build their little house.  Was really well looked after with accomodation ,food plus all the other information they gave to me.  It's a really nice little village that gives you a totally different look at Hong Kong.  Rivers and streams , country settings with nice walks and animals around.  Really enjoyed it ",
        "receiver": {
            "isHost": true,
            "image": "https://image.hippohelp.com/uploads/profiles/17638/1main498685197624.png",
            "link": "https://hippohelp.com/host/organic-farm-in-hong-kong",
            "title": "Organic Farm in Hong Kong"
        },
        "sender": {
            "isHost": false,
            "image": "https://image.hippohelp.com/assets/dummy-image.png",
            "link": "https://hippohelp.com/traveller/nick-milington",
            "title": "Nick Milington"
        }
    },
    {
        "text": "Cecilia stayed at my farm for 2 weeks and helped on a wide variety of things: harvesting sweet potatoes and potatoes and apples, weeding, drywall mudding, grouting, pressing cider, wood moving and splitting. Marianne, Yan, myself, and friend around our community enjoyed many conversations. Cecilia was very helpful and tidy around the kitchen and is also a great cook.\nAll the best in the rest of your travels in Canada and hope to see you again.",
        "receiver": {
            "isHost": false,
            "image": "https://image.hippohelp.com/uploads/profiles/62014/1main602589648233.png",
            "link": "https://hippohelp.com/traveller/cecilia-bobba",
            "title": "Cecilia Bobba"
        },
        "sender": {
            "isHost": true,
            "image": "https://image.hippohelp.com/uploads/profiles/1003/1main419159801748.png",
            "link": "https://hippohelp.com/host/eden-in-seasons-garden-of-eating",
            "title": "Eden in Season's Garden of Eating"
        }
    }
]