import React from 'react'
import Box from '@mui/material/Box';
import { Typography } from '@material-ui/core';
import Divider from '../components/formatting/Divider';
import Footer from '../components/formatting/Footer';
import HTMLContent from '../variables/HTMLContent';
import { Helmet, HelmetProvider } from 'react-helmet-async';

function SupportScreen() {
  return (
    <>   
      <HelmetProvider>
        <Helmet>
          <title>Hippohelp | Support | FAQ For Our Users</title>
          <meta name="description" content="Find answers to frequently asked questions and get support for Hippohelp. Explore our FAQ." />
        </Helmet>
      </HelmetProvider>
      <Box className='contentBox'>
        <Typography component="h1" variant="h4">Support</Typography>
        <Divider />
        <div className='leftText'>
          <HTMLContent content="faq" />
        </div>
      </Box>
      <Footer />
    </>
  )
}

export default SupportScreen