import React from 'react';
import { mdiLoading} from '@mdi/js'
import Icon from '@mdi/react'
import colors from '../../config/colors';

function InputSpinner({visible, message = "Please wait ...", withMessage = true, size = 5}) {
  return (
    <>
      {visible && (
        <>
          <div className='spinner'>
            <div>
              <Icon spin={true} path={mdiLoading} size={size} color={colors.primary} />
            </div>
            {withMessage && (
              <div> 
                {message}
              </div>
            )}

          </div>
        </>
      )}
    </>
  );
}

export default InputSpinner;