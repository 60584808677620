import React, { useState } from 'react';
import sources from "../config/sources"
import routes from '../navigation/routes';
import Spinner from '../components/other/Spinner';

function PaymentProcessingScreen(props) {
  const [secondsRemaining, setSecondsRemaining] = useState(3)

  const interval = setInterval(function () {
    clearInterval(interval)
    if (secondsRemaining === 0) {
      setSecondsRemaining(secondsRemaining - 1)
    } else {
      window.location.href = sources.frontendUrl + routes.PAYMENT_SUCCESSFUL
    }
  }, 2000)

  return (
    <div className="col-10 col-lg-6 mx-auto mt-4 screen">
      <Spinner visible={true} withMessage={false} withPaymentMessage={false} />
      <p style={{textAlign: "center"}}>Processing payment, please wait ...</p>
    </div>
  );
}

export default PaymentProcessingScreen;