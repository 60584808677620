import React, { useEffect, useState } from 'react'
import Box from '@mui/material/Box';
import { Typography } from '@material-ui/core';
import Grid from '@mui/material/Grid';
import Icon from '@mdi/react'
import { mdiMap, mdiMapMarker, mdiHandPeace, mdiChatProcessing, mdiEmoticonOutline } from '@mdi/js'
import colors from '../config/colors';
import Divider from '../components/formatting/Divider';
import Footer from '../components/formatting/Footer';
import ImageGallery from '../components/formatting/ImageGallery';
import CookieConsent from "react-cookie-consent";
import { Toast } from '../components/toasts/Toast.js';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import Button from '@material-ui/core/Button';
import { useAuth0 } from "@auth0/auth0-react";
import HTMLDialog from '../components/dialogs/HTMLDialog'
import blogApi from '../api/blogApi';
import useApi from '../hooks/useApi'
import PostsSection from '../components/blog/PostSection';
import { useHistory } from "react-router-dom"
import ReviewSlider from '../components/other/ReviewSlider/ReviewSlider.js';

function StartScreen() {
  const [open, setOpen] = React.useState(false);
  const [posts, setPosts] = useState([])
  const { loginWithRedirect } = useAuth0();
  const [getAppDialogOpen, setGetAppDialogOpen] = useState(false)
  const [mapLoginDialogOpen, setMapLoginDialogOpen] = useState(false)
  const getCategoriesAndAllPostsApi = useApi(blogApi.getCategoriesAndAllPosts)
  const history = useHistory();
  const params = new URLSearchParams(window.location.search);
  const paramError = params.get('error');
  const [touchTriggered, setTouchTriggered] = useState(false);

  const handleLogin = function (nextScreen) {
    if (window.innerWidth < 1199) {
      setGetAppDialogOpen(true)
    } else {
      if (nextScreen === "signup") {
        loginWithRedirect({ screen_hint: 'signup' })
      } else {
        loginWithRedirect()
      }
    }
  }

  const handleMapClick = function () {
    if (window.innerWidth < 1199) {
      setGetAppDialogOpen(true)
    } else {
      setMapLoginDialogOpen(true)
    }
  }

  const handleTouchStart = () => {
    if (!touchTriggered) {
      handleMapClick();
      setTouchTriggered(true);
    }
  };

  const openSnackbar = () => {
    setOpen(true);
    setTimeout(() => {
      setOpen(false);
    }, 10000);
  };

  useEffect(() => {
    async function fetchMyApi() {
      const fetch = await getCategoriesAndAllPostsApi.request(0);
      setPosts(fetch.data.allPosts.slice(0, 3));
    }

    if (paramError) {
      if (paramError === 'authError') {
        openSnackbar();
      }
    }
    fetchMyApi()
  }, []);

  return (
    <div className='startScreenContainer'>
      <HTMLDialog 
        title="Get the app!"
        content="getApp"
        passedOpen={getAppDialogOpen} 
        onClose={() => {setGetAppDialogOpen(false)}}
        data={{
          authActions: true
        }}
      />
      <HTMLDialog 
        title="Login to view all users!"
        content="mapLogin"
        passedOpen={mapLoginDialogOpen} 
        onClose={() => {setMapLoginDialogOpen(false)}}
        maxWidth="sm"
      />
      <HelmetProvider>
        <Helmet>
          <title>Hippohelp | Work Exchange Programs | Live With Locals</title>
          <meta name="description" content="Explore our exciting work exchange programs! Discover unique cultures by living with local people. Get inspired and develop your global network." />
        </Helmet>
      </HelmetProvider>
      <div>
        <Toast 
          open={open} 
          message={"Please try again or login / create account with another authentication method"}
          severity={"error"}
        />
      </div>
      <div className='cookeiConsentContainer'>
        <CookieConsent
          style={{ 
            background: colors.secondary
          }}
        >
          This website uses cookies to enhance the user experience.
        </CookieConsent>
      </div>
        <>  
          <div className="startScreenMapContainer">
          <img 
            className='startpage-image'
            src="../images/startPage/map1_logo.png"
            onClick={() => handleMapClick()}
            onDrag={() => handleMapClick()}
            onTouchStart={() => handleTouchStart()}
          />
          </div>
          
          <Box>
            
            <Box className='contentBox contentBoxWrapper'>
              <Box className='contentBoxContainer'>
                <Typography component="h1" variant="h4">Work, travel and live with the locals</Typography>
                <Divider />
                <Typography component="h2" variant="h5" className='allForFree'>Join our community of 80.000+ users!</Typography>
                <p>
                  Hippohelp is a map-based work exchange platform connecting hosts from all over the world with travellers who are happy to work in exchange for free food and accommodation.
                </p>
              </Box>
            </Box>

            <Box className="stepsContainer">
              <Grid container paddingTop={2} paddingBottom={2}>
                <Grid item lg={2}></Grid>
                <Grid item lg={2} xs={12} className="stepItem">
                  <div className='circle'>
                    <div><Icon path={mdiMap} size={2.5} color={colors.lightBlue} /></div>
                    <div><Typography component="h5" variant="h5">Step One</Typography></div>
                    <div>Check the map</div>
                  </div>
                  <div className='rectangle'>
                    <div>Check the map for hostmarkers.</div>
                  </div>
                </Grid>
                <Grid item lg={2} xs={12} className="stepItem">
                  <div className='circle'>
                    <div><Icon path={mdiMapMarker} size={2.5} color={colors.lightBlue} /></div>
                    <div><Typography component="h5" variant="h5">Step Two</Typography></div>
                    <div>Choose marker</div>
                  </div>
                  <div className='rectangle'>
                    <div>Check the details of a marker.</div>
                  </div>
                </Grid>
                <Grid item lg={2} xs={12} className="stepItem">
                  <div className='circle'>
                    <div><Icon path={mdiChatProcessing} size={2.5} color={colors.lightBlue} /></div>
                    <div><Typography component="h5" variant="h5">Step Three</Typography></div>
                    <div>Send request</div>
                  </div>
                  <div className='rectangle'>
                    <div>If it's something for you, send a request.</div>
                  </div>
                </Grid>
                <Grid item lg={2} xs={12} className="stepItem">
                  <div className='circle'>
                    <div><Icon path={mdiHandPeace} size={2.5} color={colors.lightBlue} /></div>
                    <div><Typography component="h5" variant="h5">Step Four</Typography></div>
                    <div>Celebrate!</div>
                  </div>
                  <div className='rectangle'>
                    <div>Pack the bags and visit your new host!</div>
                  </div>
                </Grid>
                <Grid item lg={2}></Grid>
              </Grid>
            </Box>

            <Box className='contentBox' style={{display: "flex", width: "100%", alignItems: "center", flexDirection: "column"}}>
              <Typography component="h2" variant="h5" className='moreOnWorkExchange'>Unique stories written every day</Typography>
              <Divider />
              <Box className="startPageReviewSliderContainer">
                <ReviewSlider />
              </Box>
            </Box>

            <Box className='contentBox'>
              <Typography component="h2" variant="h5" className='moreOnWorkExchange'>More on the work exchange program idea!</Typography>
              <Divider />
              <Box className='dualBox'>
                <Box>
                  <Box className='dualBoxTextContent'>
                    <Typography component="h3" variant="h6">Travellers - Experience the cultural exchange!</Typography>
                    <p>Our travelling members are open minded people interested in cultural exchange and saving on their expenses while working and travelling around the world and are happy to exchange work for housing.</p>
                    <p>They are willing to do a few hours of free work per day while living and eating for free. After travelling the world they will have a whole new set of skills, and let's not even start speaking of all the new memories.</p>
                    <p>The typical visit at a host can be anywhere from a few days to several months.</p>
                  </Box>
                  <img className='staticImage' src="../images/startPage/1.jpeg"/>
                </Box>
                <Box>
                  <Box className='dualBoxTextContent'>
                    <Typography component="h3" variant="h6" className='dualBoxSecondHeader'>Hosts - Find free help and meet new people!</Typography>
                    <p>Hosts from all over the globe are getting free help on their projects, while the same time meeting interesting personalities from the comfort of their own home.</p>
                    <p>Hosts on our platform post all kinds of exciting volunteer opportunities, spanning from a few weeks to several months, or even on an ongoing basis. Some examples might include eco-building, childcare, language learning, gardening, web design or general help around the farm.</p>
                  </Box>
                  <img className='staticImage' src="../images/startPage/2_l.jpeg"/>
                </Box>
              </Box>

              <Typography component="h2" variant="h5" className='startPageSectionTitle'>Latest from the Blog!</Typography>
              <Divider />
              <Box className="contentBox">
                <PostsSection posts={posts} />
                <Button onClick={() => history.push('/blog')} className={"genericButton secondaryColor"}>Visit the blog</Button>
              </Box>

              <Typography component="h3" variant="h5" className='startPageSectionTitle'>Don't forget our app!</Typography>
              <Divider />
              <Box className='dualBox'>
                <Box className="dualboxMiniImageContainer">
                  <img className='staticImage' src="../images/startPage/3.jpeg"/>
                </Box>
                <Box>
                  <Box className='dualBoxTextContent'>
                    <p>Your dream experience awaits! Download our app now and open the door to a world of exciting possibilities.</p>
                    <p>Our mobile apps are removed.</p>
                  </Box>
                </Box>
              </Box>

              <Typography component="h3" variant="h5" className='startPageSectionTitle'>Pictures of popular volunteer opportunities</Typography>
              <Divider />
              <ImageGallery />

            </Box>
          </Box>
          
          <Footer />
        </>
    </div>
  )
}

export default StartScreen