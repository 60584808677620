import React, { useEffect, useState } from 'react'
import ImageGallery from 'react-image-gallery';
import { Util } from '../../utils/util'
import HostDetailsContent from './HostDetailsContent';
import TravelDetailsContent from './TravellerDetailsContent'
import UnsetDetailsContent from './UnsetDetailsContent'
import { Button } from '@mui/material';
import adminApi from '../../api/adminApi';
import useApi from '../../hooks/useApi';
import TextField from '@material-ui/core/TextField';
import countryItems from '../../variables/countryItemsWithoutAllCountries';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import colors from '../../config/colors';
import sources from '../../config/sources';
import { useHistory } from "react-router-dom"
import routes from '../../navigation/routes';
import ReviewsComponent from './ReviewsComponent';
import LinkComponent from './LinkComponent';

function AdminDetailsBox ({data, newLatLng, adminEmail}) {
  const [images, setImages] = useState(false)
  const [detailsSection, setDetailsSection] = useState(false)
  const [buttons, setButtons] = useState([])
  const [adminPassword, setAdminPassword] = React.useState('');
  const [positiveReferences, setPositiveReferences] = React.useState()
  const [negativeReferences, setNegativeReferences] = React.useState()
  const [link, setLink] = React.useState()
  const [approveHostMarkerButtonDisabled, setApproveHostMarkerButtonDisabled] = useState(false)
  const [deleteHostMarkerButtonDisabled, setDeleteHostMarkerButtonDisabled] = useState(false)
  const [approveTravelMarkerButtonDisabled, setApproveTravelMarkerButtonDisabled] = useState(false)
  const [deleteTravelMarkerButtonDisabled, setDeleteTravelMarkerButtonDisabled] = useState(false)
  const [deleteUserButtonDisabled, setDeleteUserButtonDisabled] = useState(false)
  const [selectedCountryItem, setSelectedCountryItem] = useState(false)
  const [boxSize, setBoxSize] = useState('smallAdminDetailsBox')
  const history = useHistory();
  const toggleLarge = () => {
    if (boxSize === 'smallAdminDetailsBox') {
      setBoxSize('largeAdminDetailsBox')
    }
    if (boxSize === 'largeAdminDetailsBox') {
      setBoxSize('smallAdminDetailsBox')
    }
  }

  const addReferencesApi = useApi(adminApi.addReferences)
  const addNoReferencesApi = useApi(adminApi.addNoReferences)
  const approveHostMarkerApi = useApi(adminApi.approveHostMarker)
  const deleteHostMarkerApi = useApi(adminApi.deleteHostMarker)
  const approveTravelMarkerApi = useApi(adminApi.approveTravelMarker)
  const deleteTravelMarkerApi = useApi(adminApi.deleteTravelMarker)
  const deleteUserApi = useApi(adminApi.deleteUser)
  const updateCountryIdApi = useApi(adminApi.updateCountryId)
  const changeMarkerPositionApi = useApi(adminApi.changeMarkerPosition)

  const changeMarkerPosition = async () => {
    const result = await changeMarkerPositionApi.request({
      latitude: newLatLng.lat,
      longitude: newLatLng.lng,
      userID: data.user_id
    }, adminPassword)
    if (!result) { return }
    window.location.reload()
  }
  
  useEffect(() => {
    let tempButtons = []
    if (data?.event_description) {
      setImages(Util.getImageArray(data.owner.image))
      setDetailsSection(() => {
        return (
          <>
          <h1>{data.name} ({data.event_id})</h1>
          <p>By user: {data.owner.username} ({data.owner.id})</p>
          <HostDetailsContent listingData={data} />
          </>
        )

      })
      setSelectedCountryItem(data?.hostMarkerPosition?.owner?.positions_id)
      tempButtons.push({ 
        title: 'Add references to host', 
        action: async (adminPassword) => {
          
            if (!positiveReferences || !negativeReferences || !link) {
              return alert("Add all fields!")
            }
            const result = await addReferencesApi.request({
              userID: data.user_id,
              positiveReferenceCount: positiveReferences,
              negativeReferenceCount: negativeReferences,
              link: link,
              toHost: 1
            }, adminPassword)
            if (!result) { return }
            window.location.reload()
        },
        disabled: false
      })
      tempButtons.push({ 
        title: 'No references found', 
        action: async (adminPassword) => {
            const result = await addNoReferencesApi.request({
              userID: data.user_id,
            }, adminPassword)
            if (!result) { return }
            window.location.reload()
        },
        disabled: false
      })
      tempButtons.push({ 
        title: 'View on Map', 
        action: () => history.push({
          pathname: routes.MAP,
          state: {latLng: {lat: parseFloat(data.latitude), lng: parseFloat(data.longitude)}}
        }),
        disabled: false
      })
      tempButtons.push({ 
        title: 'New tab', 
        action: () => window.open(routes.HOST_MARKER_DETAILS + '/' + data.slug, "_blank"),
        disabled: false
      })
      if (adminEmail === sources.ADMIN_EMAIL) {
        if (data.status !== '') {
          tempButtons.push({
            title: 'Approve Hostmarker',
            action: async (adminPassword) => {
              setApproveHostMarkerButtonDisabled(true)
              const result = await approveHostMarkerApi.request(data.user_id, adminPassword)
              if (!result) { return }
              window.location = '/map'
            },
            disabled: approveHostMarkerButtonDisabled
          })
        }
        tempButtons.push({ 
          title: 'Delete Hostmarker', 
          action: async (adminPassword) => {
              setDeleteHostMarkerButtonDisabled(true)
              const result = await deleteHostMarkerApi.request(data.user_id, adminPassword)
              if (!result) { return }
              alert('Done!')
              window.location = '/map'
          },
          disabled: deleteHostMarkerButtonDisabled
        })
        tempButtons.push({ 
          title: 'Delete User', 
          action: async (adminPassword) => { 
            const promptReply = prompt('Have you manually canceled subscriptions on all platforms?');
            if (promptReply === 'yes') {
              setDeleteUserButtonDisabled(true)
              const result = await deleteUserApi.request(data.user_id, adminPassword)
              if (!result) { return }
              alert('Done!')
              window.location = '/map'
            } else {
              alert('Action not executed.')
            }
          },
          disabled: deleteUserButtonDisabled
        })
      }
    } else if (data?.id) {
      setImages(Util.getImageArray(data.image))
      if (data.user_account_type_id === 1 || data.user_account_type_id === 2) {
        setDetailsSection(<TravelDetailsContent listingData={data} />)
      } else {
        setDetailsSection(<UnsetDetailsContent />)
      }
      if (data.marker) {
        if (data.marker.status !== '') {
          tempButtons.push({ 
            title: 'Approve Travelmarker', 
            action: async (adminPassword) => { 
              setApproveTravelMarkerButtonDisabled(true)
              const result = await approveTravelMarkerApi.request(data.id, adminPassword)
              if (!result) { return }
              window.location = '/map'
            },
            disabled: approveTravelMarkerButtonDisabled
          })
        }
        tempButtons.push({ 
          title: 'Delete Travelmarker', 
          action: async (adminPassword) => { 
            setDeleteTravelMarkerButtonDisabled(true)
            const result = await deleteTravelMarkerApi.request(data.id, adminPassword)
            if (!result) { return }
            alert('Done!')
            window.location = '/map'
          },
          disabled: deleteTravelMarkerButtonDisabled
        })
      }
      tempButtons.push({ 
        title: 'Delete User', 
        action: async (adminPassword) => { 
          const promptReply = prompt('Have you manually canceled subscriptions on all platforms?');
          if (promptReply === 'yes') {
            setDeleteUserButtonDisabled(true)
            const result = await deleteUserApi.request(data.id, adminPassword)
            if (!result) { return }
            alert('Done!')
            window.location = '/map'
          } else {
            alert('Action not executed.')
          }
        },
        disabled: deleteUserButtonDisabled
      })
    }
    setButtons(tempButtons)
  }, [data, approveHostMarkerButtonDisabled, positiveReferences, negativeReferences, link, boxSize])

  return (
    <div className={`adminDetailsBox ${boxSize}`}>
      {images && detailsSection && data && (
        <>
          <TextField
            type='password'
            id="standard-basic"
            label="Admin password"
            value={adminPassword}
            onChange={(event) => { setAdminPassword(event.target.value); }}
          />
          {data.event_description && (
            <>
              {adminEmail === sources.ADMIN_EMAIL && (
                <Button 
                  className='genericButton' 
                  onClick={() => { window.open('http://google.com/search?q='+data.event_description) }}
                  disabled={approveHostMarkerButtonDisabled}
                  style={{marginTop: 20}}
                >
                  Google description
                </Button>
              )}

              <div style={{
                margin: "20px 0px",
                padding: 20,
                border: "1px solid",
                borderColor: colors.primary,
                borderRadius: 10
              }}>
                <TextField
                  type="number"
                  id="standard-basic"
                  label="Positive references"
                  value={positiveReferences}
                  onChange={(event) => { setPositiveReferences(event.target.value); }}
                />
                <TextField
                  type="number"
                  id="standard-basic"
                  label="Negative references"
                  value={negativeReferences}
                  onChange={(event) => { setNegativeReferences(event.target.value); }}
                />
                <TextField
                  id="standard-basic"
                  label="Link"
                  value={link}
                  onChange={(event) => { setLink(event.target.value); }}
                />
              </div>
            </>
          )}
          {selectedCountryItem === process.env.REACT_APP_UNSET_COUNTRY_ID && (
            <div className="selectInput">
              <FormControl fullWidth>
                <InputLabel className="customFormControlLabel" id="select-label2">Country</InputLabel>
                <Select
                  className="customFormControlPlaceholder"
                  labelId="select-label2"
                  id="select-label2"
                  value={selectedCountryItem}
                  onChange={(event) => {setSelectedCountryItem(event.target.value)}}
                >
                  {countryItems.children.map((countryItem, index) => (
                    <MenuItem key={index} value={countryItem.id}>
                      {countryItem.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <Button 
                className='genericButton' 
                onClick={async () => {
                  const result = await updateCountryIdApi.request(data.user_id, selectedCountryItem, adminPassword)
                  if (!result) { return }
                  alert('Done!')
                }}
                disabled={approveHostMarkerButtonDisabled}
              >
                Update country id
              </Button>
            </div>
          )}
          <div className='adminButtonsContainer'>
          {data.event_description && (
            <>
              {data.owner.link.length !== 0 && (
                <LinkComponent displayName={data.owner.username} links={data.owner.link} />
              )}
              <ReviewsComponent userID={data.owner.id} authStatus={"authOthersListing"} isAdmin={true} />
            </>
          )}
            {buttons.map((element, index) => {
              return (
                <Button 
                  key={index} onClick={async () => await element.action(adminPassword)} 
                  className='genericButton' 
                  disabled={element.disabled}
                >
                  {element.title}
                </Button>
              )
            })}
            {newLatLng && (
              <div>
                <p>Move marker?</p>
                <p>New lat: {newLatLng.lat}</p>
                <Button 
                  className='genericButton' 
                  onClick={changeMarkerPosition}
                >
                  Move marker
                </Button>
              </div>
            )}
          </div>
          <ImageGallery showPlayButton={false} items={Util.setGalleryImages(images)} />
          {detailsSection}
        </>
      )}
    </div>
  )
}

export default AdminDetailsBox
