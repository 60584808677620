import React from 'react';

function NotFoundScreen(props) {
  return (
    <div className="col-10 col-lg-6 mx-auto mt-4 screen">
      Sorry, the page could not be found :(
    </div>
  );
}

export default NotFoundScreen;