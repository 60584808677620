import React, { memo } from 'react';

class HippomatchScreen extends React.Component {
  constructor(props) {
    super(props)
    this.onMount = props.onMount
    this.onUnmount = props.onUnmount
  }

  componentDidMount() {
    this.onMount()
  }

  componentWillUnmount() {
    this.onUnmount(window.scrollY)
  }

  render() {
    return (
      <>
      </>
    );
  }
}

export default memo(HippomatchScreen);