import * as React from 'react';
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, .05)'
      : 'rgba(0, 0, 0, .03)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

export default function CustomizedAccordions() {
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const topics = [
    {
      title: "Can I change my username?",
      content:
      <div>
          <p>
            Please contact us and we'll see what we can do.
          </p>
      </div>
    },
    {
      title: "How do I cancel my subscription?",
      content:
      <div>
          <p>
            Subscriptions created on Hippohelp.com: Please log in, then press on "You" -{'>'} "Premium" -{'>'} "Cancel Subscription".
          </p>
          <p>
            Subscriptions created on Google Play or Apple App Store: Go to the settings of the marketplace and cancel your subscription from there.
          </p>
          <p>
            Please note that we don't offer any refunds.
          </p>
      </div>
    },
    {
      title: "Can you arrange a visa or a volunteer opportunity for me?",
      content:
      <div>
          <p>
            No, we only provide a way for travellers and hosts to find each other.
          </p>
      </div>
    },
    {
      title: "How do I contact Hippohelp?",
      content:
      <div>
          <p>
            Before contacting us, please take a look at the questions in this section. You will most likely find your answer right here. If you don't find the answer you are looking for then feel free to contact us. We will answer you as soon as we can. Remember to post us the email associated with your account if your question is about your account or payments. Please note that we don't refund payments. You can cancel your subscription from the account section at any time by pressing "More" -{'>'} "Account" -{'>'} "Cancel subscription". Click <a href="/contact">here</a> to get to our contact form.
          </p>
      </div>
    },
    {
      title: "Will you sponsor my adventure?",
      content:
      <div>
          <p>
            In most cases not, but if you have any creative idea on how you can travel while spreading the word about Hippohelp we're all ears!
          </p>
      </div>
    },
    {
      title: "What's the minimum age for using Hippohelp?",
      content:
      <div>
          <p>
            The minimum age for all members is 18 years.
          </p>
      </div>
    },
    {
      title: "What's the maximum age for using Hippohelp",
      content:
      <div>
          <p>
            We don't have any maximum age. Many travellers prefer to live with old people, and many hosts prefer to have people with many years of experience helping out.
          </p>
      </div>
    },
    {
      title: "Can I travel as a family or group?",
      content:
      <div>
          <p>
            Yes, just make sure that the host knows how many will be coming from the start. Many hosts prefer to host multiple travellers at a time.
          </p>
      </div>
    },
    {
      title: "I've lost my password! What now?",
      content:
      <div>
          <p>
            Please click on "Reset your password" on the login popup box. Important: If you registered with Google/Facebook/Apple, password reset won't work. 
          </p>
      </div>
    },
    {
      title: "Can I change my email address?",
      content:
      <div>
          <p>
            Please contact us and we'll do our best to help you out.
          </p>
      </div>
    },
    {
      title: "I've forgotten my username/email address!",
      content:
      <div>
          <p>
            Please contact us and we'll do our best to help you out.
          </p>
      </div>
    },
    {
      title: "I cannot log in!",
      content:
      <div>
          <ol>
            <li>Make sure that you are entering the email address and not your username.</li>
            <li>If you've forgotten your password please click on "Reset your password".</li>
            <li>Important: If you registered with Google/Facebook/Apple, password reset won't work.</li>
            <li>If you still need any assistance, then please contact us by clicking on "Contact us (see "How do I contact Hippohelp?").</li>
          </ol>
      </div>
    },
    {
      title: "Can I use Hippohelp to find paid work?",
      content:
      <div>
          <p>
            Hippohelp is a platform for finding food and accommodation in exchange for free services for the hosts. In some cases a host may offer money as well and it's not against our rules.
          </p>
          <p>
            Hippohelp does however not take part in any dealings between you and the host, and it's up to the traveller and host to make sure that they are following all relevant laws and regulations, such as those covering minimum hourly pay.
          </p>
      </div>
    },
  ]

  return (
    <div>
      {topics.map((element, i) => {
        return (
          <Accordion expanded={expanded === 'panel' + i} onChange={handleChange('panel' + i)} key={i}>
            <AccordionSummary aria-controls={"panel" + i + "d-content"} id={"panel" + i + "d-header"}>
              <Typography>{element.title}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              {element.content}
            </AccordionDetails>
          </Accordion>
        )
      })}
    </div>
  );
}
