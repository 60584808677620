import * as React from 'react';
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, .05)'
      : 'rgba(0, 0, 0, .03)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

export default function CustomizedAccordions() {
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const topics = [
    {
      title: "How do I create a marker?",
      content:
      <div>
          <p>
            Left click on the map if you are using a computer, or tap the map with one finger if you are using a mobile device. It's that easy!
          </p>
          <p>
            You can have up to 5 travel markers, or 1 host marker active at a time.
          </p>
      </div>
    },
    {
      title: "How do I edit my marker?",
      content:
      <div>
          <p>
            Press on "Your markers", then on "Show Marker" and then on "Edit Details".
          </p>
      </div>
    },
    {
      title: "How do I edit my profile?",
      content:
      <div>
          <p>
            Press "You" from the header bar and then "Edit profile details".
          </p>
      </div>
    },
    {
      title: "How do I upload photos?",
      content:
      <div>
          <p>
            First press "You", then "Images". From here you'll be able to upload multiple photos, as well as decide which one that should be your profile-photo.
          </p>
      </div>
    },
    {
      title: "How do I zoom in and out?",
      content:
      <div>
          <p>
            Computer: Use the scroll wheel, or press the zoom buttons.
          </p>
          <p>
            Mobile devices: Use two fingers to zoom in and out, or press the zoom buttons.
          </p>
      </div>
    },
    {
      title: "How do I navigate on the map?",
      content:
      <div>
          <p>
            Computer: Press and hold the left mouse button to drag the map.
          </p>
          <p>
            Mobile devices: Press and hold one finger to drag the map.
          </p>
      </div>
    },
    {
      title: "Can I search for a particular place or user?",
      content:
      <div>
          <p>
            Yes! Just use the search box. You could for example search for "New York" or "User 1".
          </p>
      </div>
    },
    {
      title: "How do I contact hosts",
      content:
      <div>
          <p>
            You can contact hosts by clicking on a host marker. From here you can see all the details and send a request to help out.
          </p>
          <p>
            You will also see a link to the host's profile, you can go there to send a private message.
          </p>
          <p>
            You can also use the search box if you already know the username of the host.
          </p>
      </div>
    },
    {
      title: "How do I contact travellers",
      content:
      <div>
          <p>
            Many travellers place travel markers to indicate places they intend on going to. Click on a marker and you will see the details entered by the traveller. From here you'll also be able to view the travellers profile details.
          </p>
          <p>
            You will also see a link to the traveller's profile, you can go there to send a private message.
          </p>
          <p>
            You can also use the search box if you already know the username of the traveller.
          </p>
      </div>
    },
    {
      title: "How do I set my profile photo/main image?",
      content:
      <div>
          <p>
            First press "You", then "Images". Navigate to the image you would like to use, then click on "Main image".
          </p>
      </div>
    },
    {
      title: "How do I delete my account?",
      content:
      <div>
          <ol>
            <li>Press on "More" from the header menu.</li>
            <li>Press "Account".</li>
            <li>Press "Delete account".</li>
          </ol>
          <p>Warning! This action cannot be undone.</p>
      </div>
    },
    {
      title: "How do I leave feedback for a host?",
      content:
      <div>
        <ol>
          <li>Press on "Markers" from the header menu.</li>
          <li>Press "Options" and then "Leave reference".</li>
        </ol>
      </div>
    },
    {
      title: "How do I leave feedback for a traveller?",
      content:
      <div>
        <ol>
          <li>Press on your marker, or open it from the "Markers" list.</li>
          <li>Press the button that displays all users connected to the marker.</li>
          <li>Press "Options" and then "Leave reference".</li>
        </ol>
      </div>
    },
    {
      title: "How can I remove feedback?",
      content:
      <div>
          <p>
            It's generally not possible to remove any feedback left or received. But if you feel like any feedback is offensive or in any other way inappropriate then please let us know and we'll take a look at it and remove it if we feel its necessary.
          </p>
      </div>
    },
    {
      title: "Where can I see my username?",
      content:
      <div>
          <p>
            Your username will be displayed when you press the "You" button.
          </p>
      </div>
    },
    {
      title: "Can I change between being a host and a traveller?",
      content:
      <div>
          <p>
            Yes, but first you need to remove your connection to all markers. You can do this by pressing "Markers". Then from each marker in the list perform the necessary actions to remove your connection, such as leaving a marker you've been accepted to, canceling a helping request or removing the marker.
          </p>
          <p>
            Before placing a host marker or travel marker you'll also need to edit your profile and from there select if you intend to travel or host travellers.
          </p>
      </div>
    }
  ]

  return (
    <div>
      {topics.map((element, i) => {
        return (
          <Accordion expanded={expanded === 'panel' + i} onChange={handleChange('panel' + i)} key={i}>
            <AccordionSummary aria-controls={"panel" + i + "d-content"} id={"panel" + i + "d-header"}>
              <Typography>{element.title}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              {element.content}
            </AccordionDetails>
          </Accordion>
        )
      })}
    </div>
  );
}
