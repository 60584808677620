import React, { useState, useEffect } from 'react'

import Text from '../components/other/Text'
import useApi from '../hooks/useApi'
import userApi from '../api/userApi'
import { Util } from '../utils/util';
import { useHistory } from "react-router-dom"
import FavouriteItem from '../components/lists/FavouriteItem';
import markerApi from "../api/markerApi";

function FavouritesScreen () {
  const history = useHistory();
  const getFavouritesApi = useApi(userApi.getFavourites)
  const getHostMarkerDetailsAnyApprovalApi = useApi(markerApi.getHostMarkerDetailsAnyApproval)
  const [favourites, setFavourites] = useState(false)
  const [error, setError] = useState(false)

  useEffect(() => {
    async function fetchMyAPI() {
      let fetch = await getFavouritesApi.request()
      if (!fetch) { return }
      
      setFavourites(fetch.data)
    }
    fetchMyAPI()
  }, [])

  const handleFavouriteClick = async (userData) => {
    const nextPage = await Util.getDetailsRoute(userData, getHostMarkerDetailsAnyApprovalApi)
    if (!nextPage) { return }
    
    history.push(nextPage)
  }

  return (
    <div className="col-10 col-lg-6 mx-auto mt-4 screen">
      {error && ( <p>{error}</p> )}
      {favourites && favourites.favouriteHostMarkers.length === 0 && favourites.favouriteUsers.length === 0 && ( <p>No favourites here!</p> )}
      {favourites && favourites.favouriteHostMarkers.length !== 0 && (
        <>
          <div className="row mb-4">
            <div className="contentHeader">
              <h1>Hostmarkers</h1>
            </div>
          </div>
          <div className="row mb-4">
            {
              favourites.favouriteHostMarkers.map(function (favouriteItem, index) {
                return (
                <FavouriteItem
                  key={index}
                  userData={favouriteItem.owner.owner}
                  title={favouriteItem.owner.name}
                  imageSrc={ Util.getFirstImageSource(favouriteItem.owner.owner.image) }
                  onClick={handleFavouriteClick}
                />)
              })
            }
          </div>
        </>
      )}
      {favourites && favourites.favouriteUsers.length !== 0 && (
        <>
          <div className="row mb-4">
            <div className="contentHeader">
              <h1>Users</h1>
            </div>
          </div>
          <div className="row mb-4">
            {
              favourites.favouriteUsers.map(function (favouriteItem, index) {
                return (
                <FavouriteItem
                  key={index}
                  userData={favouriteItem.owner}
                  title={favouriteItem.owner.username}
                  imageSrc={ Util.getFirstImageSource(favouriteItem.owner.image) }
                  onClick={handleFavouriteClick}
                />)
              })
            }
          </div>
        </>
      )}
    </div>
  )
}

export default FavouritesScreen
