import * as React from 'react';
import Input from '@mui/material/Input';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import { Util } from '../utils/util';
import InputLabel from '@mui/material/InputLabel';
import Button from '@material-ui/core/Button';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default function MultiSelectInputFieldWithPlaceholder({selections, placeholder, onChange, selectedItems, label, handleClose, open, identifier = "demo-multiple-checkbox"}) {
  const initSelectionArray = function () {
    if (selectedItems?.length > 0) {
      const selectedItemsNamesArray = []
      for (let i = 0; i < selectedItems.length; i++) {
        selectedItemsNamesArray.push(Util.parseWorkType(selectedItems[i]))
      }
      return selectedItemsNamesArray
    } else {
      return [placeholder]
    }
  }
  const [selectionArray, setSelectionArray] = React.useState(initSelectionArray());

  const handleChange = (event) => {
    const {target: { value } } = event;
    setSelectionArray(
      // On autofill we get a the stringified value.
      typeof value === 'string' ? value.split(',') : value,
      value[0] === placeholder  ? value.shift() : value,
      value.length === 0  ? value.push(placeholder) : value,
    );
    onChange(value)
  };

  return (
    <div className='multiSelectContainer'>
      <FormControl sx={{ m: 1, width: 300 }}>
        {label && (
          <InputLabel className="customFormControlLabel" id={`${identifier}-label`}>{label}</InputLabel>
        )}
        <Select
          labelId={`${identifier}-label`}
          id={identifier}
          multiple
          value={selectionArray}
          onChange={handleChange}
          input={<Input placeholder="Placeholder" fullWidth={true} />}
          renderValue={(selected) => selected.join(', ')}
          MenuProps={MenuProps}
          open={open}
          style={{ textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "pre", margin: 0 }}
        > 
          {handleClose && (
            <div className='confirmButtonHolder'>
              <Button style={{marginBottom: '8px'}} className='genericButton' id="multiselectCloseButton" onClick={handleClose}>Confirm</Button>
            </div>
            
          )}
          {selections.map((selection) => (
            <MenuItem key={selection.id} value={selection.name}>
              <Checkbox checked={selectionArray.indexOf(selection.name) > -1} />
              <ListItemText primary={selection.name} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}
