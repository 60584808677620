import React, { useState, useEffect, memo } from 'react';

function MapScreen({onMount, onUnmount}) {
  useEffect(() => {
    onMount()
    return () => {
      onUnmount()
    };
  }, []);

  
  return (
    <>
    </>
  );
}

export default memo(MapScreen);