import React from 'react'
import ChevronRight from '@material-ui/icons/ChevronRight';
import Text from '../other/Text'
import clsx from 'clsx'

function FavouriteItem ({ 
  userData,
  title, 
  imageSrc,
  onClick,
}) {
  return (
    <div className={"favouriteItemContainer"} onClick={() => onClick(userData)}>
      <div className={"favouriteImageContainer"}>
        <img src={imageSrc} />
      </div>
      <div className={"favouriteContentContainer"}>
        <div>
          <Text>{title}</Text>
        </div>
      </div>
    </div>
  )
}

export default FavouriteItem
