import React, { useState, useEffect, useRef } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component';
import useApi from '../../hooks/useApi'
import userApi from '../../api/userApi'
import markerApi from '../../api/markerApi'
import routes from '../../navigation/routes';
import HippomatchCard from './HippomatchCard';
import { useHistory } from "react-router-dom"
import Form from '../../forms/Form'
import SubmitButton from '../../forms/SubmitButton';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import countryItems from '../../variables/countryItems';
import workTypeItems from '../../variables/workTypeItems'
import MultiSelectInputField from '../../forms/MultiSelectInputField';
import { Util } from '../../utils/util';
import AsyncSearch from './AsyncSearch';
import colors from '../../config/colors';
import Icon from '@mdi/react'
import { mdiArrowUpBoldCircleOutline, mdiLoading, mdiAccountSearch  } from '@mdi/js'

function HippomatchComponent({hippomatchComponentActive}) {
  const getUserListingsApi = useApi(userApi.getUserListings)
  const getHostMarkerListingsApi = useApi(markerApi.getHostMarkerListings)
  const getProfileDetailsApi = useApi(userApi.getProfileDetails)
  const getHostMarkerDetailsAnyApprovalApi = useApi(markerApi.getHostMarkerDetailsAnyApproval)
  const history = useHistory();
  const [stateDone, setStateDone] = useState(false)
  const [selectedWorkTypeItems, setSelectedWorkTypeItems] = useState([])
  const [selectedCountryItem, setSelectedCountryItem] = useState(undefined)
  const [selectedUserTypeItem, setSelectedUserTypeItem] = useState(undefined)
  const [listingsData, setListingsData] = useState([])
  const [offset, setOffset] = useState(0)
  const [searchOffset, setSearchOffset] = useState(0)
  const [hasMore, setHasMore] = useState(false)
  const [searchResults, setSearchResults] = useState(false)
  const [loading, setLoading] = useState(false)
  const [fetchCount, setFetchCount] = useState(0)
  const scrollableDivRef = useRef(null)
  const [searchVisible, setSearchVisible] = useState(false)

  const fetchMyAPI = async (passedFilterData) => {
    if (stateDone && hippomatchComponentActive) {
      if (fetchCount === 0) { setLoading(true) }
      // Data for user type filter
      let fetchedListingsData
      let userTypeToFetch
      let filterData
      if (passedFilterData) {
        setOffset(0)
        filterData = passedFilterData
        userTypeToFetch = passedFilterData.selectedUserTypeItem
      } else {
        userTypeToFetch = selectedUserTypeItem
        filterData = {
          selectedCountryItem: selectedCountryItem, // [0] initial state
          selectedWorkTypeItems: selectedWorkTypeItems, // [0] initial state
          offset: offset,
          selectedUserTypeItem: selectedUserTypeItem
        }
      }
      
      // Selection 1 is search for travellers, selection 2 is search for hosts.
      if (userTypeToFetch === 1) {
        const getUserListingsApiFetch = await getUserListingsApi.request(filterData)
        if (!getUserListingsApiFetch) { return }

        fetchedListingsData = getUserListingsApiFetch.data.userMatches
      } else {
        const getHostMarkerListingsApiFetch = await getHostMarkerListingsApi.request(filterData)
        if (!getHostMarkerListingsApiFetch) { return }

        fetchedListingsData = getHostMarkerListingsApiFetch.data.hostMarkerMatches
      }

      if (fetchedListingsData.length < 7) {
        for (let i = 0; i < 3; i++) {
          filterData.offset = filterData.offset + 24

          let secondFetchedListingsData
          if (userTypeToFetch === 1) {
            const secondGetUserListingsApiFetch = await getUserListingsApi.request(filterData)
            if (!secondGetUserListingsApiFetch) { return }

            secondFetchedListingsData = secondGetUserListingsApiFetch.data.userMatches
          } else {
            const secondGetHostMarkerListingsApiFetch = await getHostMarkerListingsApi.request(filterData)
            if (!secondGetHostMarkerListingsApiFetch) { return }

            secondFetchedListingsData = secondGetHostMarkerListingsApiFetch.data.hostMarkerMatches
          }
  
          fetchedListingsData = fetchedListingsData.concat(secondFetchedListingsData)
        }
        if (!passedFilterData) {
          setOffset(offset + 96)
        } else {
          setOffset(96)
        }
      } else {
        if (!passedFilterData) {
          setOffset(offset + 24)
        } else {
          setOffset(24)
        }
      }

      setLoading(false)
      if (passedFilterData) {
        setListingsData(fetchedListingsData)
      } else {
        setListingsData(listingsData.concat(fetchedListingsData))
      }
      if (fetchedListingsData.length === 0) { 
        setHasMore(false) 
      } else {
        setHasMore(true)
      }
      setFetchCount(fetchCount + 1)
    }
  }

  const renderMoreCardsFromSearchResults = (passedSearchResults) => {
    if (stateDone && hippomatchComponentActive) {
      let takenData
      if (passedSearchResults) {
        takenData = passedSearchResults.slice(0, 24)
      } else {
        takenData = searchResults.slice(searchOffset, (searchOffset + 24))
      }
      setSearchOffset(searchOffset + 24)
      
      // If we just fetched the search result
      if (passedSearchResults) {
        setListingsData(takenData)
      } else {
        setListingsData(listingsData.concat(takenData))
      }

      if (takenData.length === 0) { 
        setHasMore(false)
      } else {
        setHasMore(true)
      }
    }
  }

  const setInitialState = async () => {
    const fetch = await getProfileDetailsApi.request()
    if (!fetch) { return }

    const fetchedAuthData = fetch.data
    if (fetchedAuthData.user_account_type_id === 3) {
      setSelectedUserTypeItem(1) // 1 is travellers. Meaning host search for travellers.
      if (fetchedAuthData.marker?.hostMarkerPosition?.owner?.positions_id) {
        setSelectedCountryItem([fetchedAuthData.marker.hostMarkerPosition.owner.positions_id])
      } else {
        setSelectedCountryItem(0)
      }
    } else {
      setSelectedUserTypeItem(2)
      if (fetchedAuthData?.position?.positions_id) { 
        setSelectedCountryItem([fetchedAuthData.position.positions_id])
      } else {
        setSelectedCountryItem(0)
      }
    }
    // setOffset(0)
    // setSearchOffset(0)
    // setSelectedWorkTypeItems([])
    // setListingsData([])
    // setHasMore(false)
    setStateDone(true)
    setSearchResults([])
  }

  useEffect(() => {
    if (!stateDone) {
      setInitialState()
    }
    fetchMyAPI()
  }, [stateDone, selectedUserTypeItem, hippomatchComponentActive])

  const handleButtonSubmit = async (passedFilterData) => {
    setSearchResults([])
    setListingsData([])
    setOffset(0)
    setSearchOffset(0)
    fetchMyAPI(passedFilterData)
  }

  const onMultiSelectChange = (value) => {
    setSelectedWorkTypeItems(Util.convertWorkTypeNamesToIDs(Array.from(value)))
    handleButtonSubmit({
      selectedCountryItem : selectedCountryItem,
      selectedWorkTypeItems: Util.convertWorkTypeNamesToIDs(Array.from(value)),
      offset: 0,
      selectedUserTypeItem: selectedUserTypeItem, 
    })
  }

  const handleSearchDone = (results) => {
    setSearchResults(results)
    setListingsData([])
    // setOffset(0)
    // setSearchOffset(0)
    renderMoreCardsFromSearchResults(results)
  }

  const handleNext = () => {
    if (searchResults.length > 0) {
      renderMoreCardsFromSearchResults()
    } else {
      fetchMyAPI()
    }
  }
  

  const handleClick = async (element) => {
    const detailsRoute = await Util.getDetailsRoute(element, getHostMarkerDetailsAnyApprovalApi)
    if (!detailsRoute) { return }

    history.push(detailsRoute)
  }

  return (
    <div id="scrollableDiv" ref={scrollableDivRef}>
      {stateDone && (
        <div className="hippomatchContainerInner">
          <div className="filterContainer auth col-10 col-lg-6 mx-auto">
            {searchVisible && (
              <div className="selectInput w-100">
                <AsyncSearch onSearchDone={(results) => handleSearchDone(results)} />
              </div>
            )}
            {!searchVisible && (
              <div className='w-100' style={{textAlign: 'end'}}>
                <Icon className='searchIcon' onClick={() => setSearchVisible(true)} spin={false} path={mdiAccountSearch} size={1.5} color={colors.dark} style={{marginRight: '3%', marginBottom: '3%'}} />
              </div>
            )}
            <div className="selectInput">
              <Select
                labelId="select-label2"
                id="select-label2"
                  value={selectedCountryItem}
                  onChange={(event) => {
                    setListingsData([])
                    setSelectedCountryItem(event.target.value)
                    handleButtonSubmit({
                      selectedCountryItem : event.target.value,
                      selectedWorkTypeItems: selectedWorkTypeItems,
                      offset: 0,
                      selectedUserTypeItem: selectedUserTypeItem, 
                    })
                  }}
                >
                {countryItems.children.map((countryItem, index) => (
                  <MenuItem key={index} value={countryItem.id}>
                    {countryItem.name}
                  </MenuItem>
                ))}
              </Select>
            </div>
            <div className="selectInput">
              <MultiSelectInputField selections={workTypeItems} placeholder="All work types" onChange={(value) => onMultiSelectChange(value)} />
            </div>
            <div className="selectInput">
              <Select
                labelId="select-label1"
                id="select-label1"
                value={selectedUserTypeItem}
                onChange={(event) => {
                  setListingsData([])
                  setSelectedUserTypeItem(event.target.value)
                  handleButtonSubmit({
                    selectedCountryItem : selectedCountryItem,
                    selectedWorkTypeItems: selectedWorkTypeItems,
                    offset: 0,
                    selectedUserTypeItem: event.target.value, 
                  })
                }}
              >
                <MenuItem value={2}>Hosts</MenuItem>
                <MenuItem value={1}>Travellers</MenuItem>
              </Select>
            </div>
          </div>
          {loading && (
            <div style={{textAlign: 'center'}}>
              <Icon spin={true} path={mdiLoading} size={5} color={colors.primary} />
            </div>
          )}
          {listingsData.length > 0 && (
              <>
                <div 
                  onClick={() => {
                    if (scrollableDivRef.current) {
                      if (window.innerWidth < 1199) {
                        scrollableDivRef.current.scrollTo({ top: 0, behavior: 'smooth' });
                      } else {
                        window.scrollTo({ top: 0, behavior: 'smooth' })
                      }
                    }
                  }}
                  className="iconWrapper scrollToTopButton"
                >
                  <Icon spin={false} path={mdiArrowUpBoldCircleOutline} size={1.2} color={colors.white} />
                </div>
                <InfiniteScroll
                scrollableTarget={window.innerWidth < 1199 ? "scrollableDiv" : null}
                scrollThreshold={0.8}
                dataLength={listingsData.length} //This is important field to render the next data
                next={handleNext}
                hasMore={hasMore}
                loader={
                  // <p className="endMessage">
                  //   <b>Loading ...</b>
                  // </p>
                  <div style={{textAlign: 'center'}}>
                    <Icon spin={true} path={mdiLoading} size={5} color={colors.primary} />
                  </div>
                }
                endMessage={
                  <p className="endMessage">
                    <b>Yay! You have seen it all.</b>
                  </p>
                }
              >
                {
                  listingsData.map(function (element, index) {
                    let userId
                    if (element.host_id) {
                      userId = element.host_id
                    } else {
                      userId = element.users_id
                    }
                    
                    return (
                      <HippomatchCard
                        key={index}
                        title={element.name}
                        images={Util.getImageArray(Util.convertFilterimage(element.image_path, userId))}
                        onClick={async () => await handleClick(element)}
                      />
                    )

                    // return (<p>{index}</p>)
                  })
                }
              </InfiniteScroll>
            </>
          )}
        </div>
      )}
    </div>
  );
}

export default HippomatchComponent;