import React, { useState } from 'react'
import AdminActionSelect from './AdminActionSelect';
import Button from '@material-ui/core/Button';
import ActionModal from './ActionModal';
import useApi from '../../hooks/useApi';
import adminApi from '../../api/adminApi';

function AdminDashboardBox ({toggleAdminSearch, onMarkerClick}) {
  const deleteOldTravelmarkersApi = useApi(adminApi.deleteOldTravelmarkers)
  const deleteUserApi = useApi(adminApi.deleteUser)
  const getUnsetCountryIdApi = useApi(adminApi.getUnsetCountryId)
  const updateCountryIdsApi = useApi(adminApi.updateCountryIds)
  const customActionApi = useApi(adminApi.customAction)
  
  const deleteOldTravelmarkers = async (password) => {
    const fetch = await deleteOldTravelmarkersApi.request(password)
    if (!fetch) { return }
    alert('Done!')
    window.location = '/map'
  }

  const deleteUserById = async (id, password) => {
    const promptReply = prompt('Have you manually canceled subscriptions on all platforms?');
    if (promptReply === 'yes') {
      const fetch = await deleteUserApi.request(id, password)
      if (!fetch) { return }
      alert('Done!')
      window.location = '/map'
    } else {
      alert('Action not executed.')
    }
  }

  const getUnsetCountryId = async () => {
    const fetch = await getUnsetCountryIdApi.request()
  }

  const updateCountryIds = async (password) => {
    const fetch = await updateCountryIdsApi.request(password)
    if (!fetch) { return }
    alert('Done!')
    window.location = '/map'
  }

  const customAction = async (id, password) => {
    const fetch = await customActionApi.request(id, password)
    if (!fetch) { return }
    alert('Done!')
  }
  
  const actions = [
    { passwordAction: true, inputAction: false, actionName: 'Delete old travelmarkers', action: deleteOldTravelmarkers },
    { passwordAction: true, inputAction: true, actionName: 'Delete user by id', action: deleteUserById },
    { passwordAction: false, inputAction: false, actionName: 'Get unset country id', action: getUnsetCountryId },
    { passwordAction: true, inputAction: false, actionName: 'Update country ids', action: updateCountryIds },
    { passwordAction: true, inputAction: true, actionName: 'Custom action', action: customAction },
  ];

  const [open, setOpen] = useState(false)
  const [title, setTitle] = useState(false)
  const [action, setAction] = useState(false)

  return (
    <div className='adminReviewItemSection'>
      <h3 className="mediumColor">Admin controls</h3>
      <AdminActionSelect
        actions={actions}
        onSelect={(action) => {
          setAction(action),
          setTitle(action.actionName) 
        }}
      />
      {action && (
        <>
          <ActionModal 
            open={open} 
            title={title}
            actions={[action]}
            onHide={() => {
              setOpen(false),
              setTitle(false),
              setAction(false)
            }}
            passwordAction={action.passwordAction}
            inputAction={action.inputAction}
          />
          <Button className={"genericButton"} onClick={() =>  { setOpen(true) }}>
            {action.actionName}
          </Button>
        </>
      )}
      <Button className={"genericButton"} onClick={() =>  { toggleAdminSearch() }}>
        Toggle Search
      </Button>
    </div>
  )
}

export default AdminDashboardBox
