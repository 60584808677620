import React, { useState, useEffect, useRef } from 'react';
import useApi from '../hooks/useApi'
import userApi from '../api/userApi';
import markerApi from '../api/markerApi'
import routes from '../navigation/routes';
import Form from '../forms/Form'
import SubmitButton from '../forms/SubmitButton';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import countryItems from '../variables/countryItems';
import workTypeItems from '../variables/workTypeItems'
import MultiSelectInputField from '../forms/MultiSelectInputField';
import { Util } from '../utils/util';1
import HippomatchCard from '../components/other/HippomatchCard';
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import { Link, useLocation, useHistory } from "react-router-dom";
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import Footer from '../components/formatting/Footer';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Typography } from '@material-ui/core';

function HippomatchNoAuthScreen(props) {
  const history = useHistory();
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const getHostMarkerListingsApi = useApi(markerApi.getHostMarkerListings)
  const getUserListingsApi = useApi(userApi.getUserListings)
  const [selectedWorkTypeItems, setSelectedWorkTypeItems] = useState(undefined)
  const [selectedCountryItem, setSelectedCountryItem] = useState(undefined)
  const [listingsData, setListingsData] = useState(undefined)
  const [offset, setOffset] = useState(undefined)
  const [stateDone, setStateDone] = useState(false)
  const [updatePresses, setUpdatePresses] = useState(0)
  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
  };

  const generateHippomatchCard = (element, index) => {
    let userId
    if (element.host_id) {
      userId = element.host_id
    } else {
      userId = element.users_id
    }

    return (
      <HippomatchCard
        key={index}
        title={element.name}
        images={Util.getImageArray(Util.convertFilterimage(element.image_path, userId))}
        onClick={() => handleHippomatchCardClick(element)}
      />
    )
  }

  const [currentPage, setCurrentPage] = useState(() => {
    if (query.get('page')) {
      if (Number(query.get('page')) < 1) {
        return 1
      } else {
        return Number(query.get('page'))
      }
    } else {
      return 1
    }
  })

  let helmet
  let handleHippomatchCardClick
  let intro
  let ending
  if (props.location.pathname === '/hosts') {
    handleHippomatchCardClick = (element) => {
      window.open(routes.HOST_MARKER_DETAILS + '/' + element.slug);
    }

    helmet = function () {
      return (
        <HelmetProvider>
          <Helmet>
            <title>Hippohelp | Exchange Work For Accommodation | Free Staying - Hippohelp</title>
            <meta name="description" content="Discover Hippohelp! Get free housing by working, a platform where you can exchange work for accommodation. Find your perfect accommodation now!" />
          </Helmet>
        </HelmetProvider>
      )
    }
    intro = function () {
      return (
        <div className='hippomatchNoAuthIntro'>
          <Typography component="h1" variant="h4">Exchange Work For Accommodation</Typography>
          <p>See all our available hosts looking for help</p>
        </div>
        
      )
    }
    ending = function () {
      return (
        <div>
          <p>Hippohelp connects you with friendly hosts worldwide, offering a fantastic opportunity to exchange your valuable skills for comfortable accommodation in a supportive community. With Hippohelp, you'll have an incredible cultural immersion experience, make meaningful connections, and broaden your horizons while enjoying the warm hospitality of hosts around the world.</p>
        </div>
        
      )
    }
  } else {
    handleHippomatchCardClick = (element) => {
      window.open(routes.TRAVEL_USER_DETAILS + '/' + element.slug);
    }

    helmet = function () {
      return (
        <HelmetProvider>
          <Helmet>
            <title>Hippohelp | Looking For Volunteers Exchange for Housing? </title>
            <meta name="description" content="Looking for some free help at home? Discover Hippohelp's exchange program and exchange accommodation for work. Join now for exciting housing opportunities." />
          </Helmet>
        </HelmetProvider>
      )
    }
    intro = function () {
      return (
        <div className='hippomatchNoAuthIntro'>
          <Typography component="h1" variant="h4">Volunteers Exchange for Housing</Typography>
          <p>See all our available travellers looking for a place to stay</p>
        </div>
      )
    }
    ending = function () {
      return (
        <div>
          <p>We have an amazing community of volunteers eager to share their skills and knowledge in exchange for housing. Whether you're looking for assistance with gardening projects, fun activities to do with your children, expertise in natural building, or language teaching and practice, the possibilities are endless!</p>
        </div>
        
      )
    }
  }


  const onMultiSelectChange = (value) => {
    if (typeof value[0] === 'undefined') {
      value.shift()
    }
    
    const workTypeIds = Util.convertWorkTypeNamesToIDs(Array.from(value))


    setSelectedWorkTypeItems(workTypeIds)
    
  }

  const fetchMyAPI = async (passedOffset) => {
    if (stateDone) {
      const filterData = {
        selectedCountryItem: selectedCountryItem,
        selectedWorkTypeItems: selectedWorkTypeItems,
        selectedUserTypeItem: 2,
      }
      
      if (passedOffset || passedOffset === 0) {
        filterData.offset = passedOffset
      } else {
        filterData.offset = offset
      }

      if (props.location.pathname === '/hosts') {
        const fetch = await getHostMarkerListingsApi.request(filterData)
        if (!fetch) { return }
        setListingsData(fetch.data.hostMarkerMatches)
      } else {
        const fetch = await getUserListingsApi.request(filterData)
        if (!fetch) { return }
        setListingsData(fetch.data.userMatches)
      }
    }
  }

  const setInitialState = async () => {
    setSelectedWorkTypeItems([])
    setSelectedCountryItem(0)
    setListingsData(false)
    updateOffset()
    setStateDone(true)
  }

  const updateOffset = function () {
    if (query.get('page')) {
      if (query.get('page') !== '1') {
        const newOffset = Number((query.get('page') * 24) - 24)
        setOffset(newOffset)
      } else {
        setOffset(0)
      }
    } else {
      setOffset(0)
    }
  }

  useEffect(() => {
    if (!stateDone) {
      setInitialState()
    }
    setListingsData(false)
    let passedOffset = 0
    if (currentPage !== '1') { passedOffset = (currentPage * 24) - 24 }
    fetchMyAPI(passedOffset)
  }, [stateDone, currentPage, updatePresses])

  const handleUpdate = function () {
    if (history.location.search) {
      history.push({
        pathname: routes.AllHostsScreen,
        search: '?page=1',
      });
      setCurrentPage(1)
    }
    setUpdatePresses(updatePresses + 1)
  }

  const getDisabled = function () {
    if (currentPage > 999 || listingsData.length === 0) {
      return true
    } else {
      return false
    }
  }

  return (
    <>
      {helmet()}
      <div className="col-lg-10 mx-auto mt-lg-4 screen">
        {listingsData && stateDone && (
          <>
            {intro()}
            <div className="hippomatchContainerInner hippomatchStartContainerInner">
              <div className="filterContainer col-10 col-lg-6 mx-auto mt-4">
              <div className="selectInput" onClick={() => { if (!open) { setOpen(true) }}}>
                  <MultiSelectInputField 
                    selections={workTypeItems}
                    placeholder="All work types" 
                    onChange={(value) => onMultiSelectChange(value)} 
                    selectedItems={selectedWorkTypeItems}
                    open={open}
                    handleClose={handleClose}
                  />
                </div>
                <div className="selectInput">
                  <Select
                    labelId="select-label2"
                    id="select-label2"
                      value={selectedCountryItem}
                      onChange={(event) => {setSelectedCountryItem(event.target.value)}}
                    >
                    {countryItems.children.map((countryItem, index) => (
                      <MenuItem key={index} value={countryItem.id}>
                        {countryItem.name}
                      </MenuItem>
                    ))}
                  </Select>
                </div>
                <Form 
                  initialValues={false}
                  onSubmit={ () => {
                    handleUpdate()
                  }}
                >
                <div className="selectInput filterSubmitButton">
                  <SubmitButton placeholder='Update filter' />
                </div>
                </Form>
              </div>
              <div className="startpageCardContainer">
                {listingsData.map((element, index) => (
                  generateHippomatchCard(element, index)
                ))}
              </div>
            </div>
            <Stack direction="row" spacing={1} className="navigationButtons">
              <IconButton
                disabled={currentPage < 2 ? true : false}
                size={"large"}
                aria-label="previous"
                color="primary"
                onClick={ () => {
                    setCurrentPage(currentPage - 1)
                    if (props.location.pathname === '/hosts') {
                      history.push(routes.HOSTS + `?page=${currentPage - 1}`) 
                    } else {
                      history.push(routes.TRAVELLERS + `?page=${currentPage - 1}`) 
                    }
                }}>
                <NavigateBeforeIcon />
              </IconButton>
              {currentPage}
              <IconButton 
                disabled={getDisabled()}
                size={"large"}
                aria-label="next"
                color="primary"
                onClick={ () => {
                  setCurrentPage(currentPage + 1)
                  if (props.location.pathname === '/hosts') {
                    history.push(routes.HOSTS + `?page=${currentPage + 1}`) 
                  } else {
                    history.push(routes.TRAVELLERS + `?page=${currentPage + 1}`) 
                  }
                }}>
                <NavigateNextIcon />
              </IconButton>
            </Stack>
            {ending()}
            <Footer />
          </>
        )}
      </div>
    </>
  );
}

export default HippomatchNoAuthScreen;