import React, { useRef } from 'react'
import Icon from '@mdi/react'
import colors from '../../config/colors'
import { mdiArrowRightCircle, mdiCloseCircle } from '@mdi/js';
import { Util } from '../../utils/util';
import ImageGallery from 'react-image-gallery';
import Alert from '@mui/material/Alert';

// friendAndFavouriteStatus here will always be fresh, because card will be hidden on tab-switch, and the only way to show it is by pressing a marker, and then data will be fetched from MapScreen and passed to Card before Card is shown.
function MapCard ({ title, images, onClick, showThumbnails = false, showNav = true, showFullScreenButton = false, onCloseCard, updatedAt }) {
  const imageComponent = useRef(null);
  if (title.length > 34) {
    title = title.substring(0, 34) + ' ...'
  }

  return (
    <div className="listingCard mapCard">
        <div className="listingCardImageWrapper mapListingCardImageWrapper">
          {new Date().getFullYear()  - Number(updatedAt.slice(0,4)) > 1 && (
            <div className='mapCardWarning'>
              <Alert sx={{ justifyContent: 'center' }} severity="warning">
                Last login: {updatedAt.slice(0, 10)}.
              </Alert>
            </div>
          )}
          <div style={{position: 'absolute', left: '10px', top: '10px', zIndex: 10}} onClick={() => onCloseCard()}>
            <Icon path={mdiCloseCircle} color={colors.blackTransparent} size={'30px'} />
          </div>
            <ImageGallery 
              showThumbnails={showThumbnails}
              showNav={showNav}
              showFullscreenButton={showFullScreenButton}
              ref={imageComponent} 
              onClick={onClick} 
              showPlayButton={false} 
              items={Util.setGalleryImages(images)}
            />
          </div>
        <div className="doubleContainer" onClick={onClick}>
          <p className="subArea1">
            {title}
          </p>
          <div className="subArea2">
            <Icon path={mdiArrowRightCircle} color={colors.primary} size={'30px'} />
          </div>
        </div>
    </div>
  )
}

export default MapCard
