import React from 'react'
import Modal from '@mui/material/Modal';
import LinearProgress from '@mui/material/LinearProgress';
import Box from '@mui/material/Box';
import uploadDoneJSON from '../assets/animations/done.json'


function UploadScreen ({ progress = 0, open = false, onComplete }) {

  const defaultOptions = {
    loop: false,
    autoplay: true, 
    animationData: uploadDoneJSON,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  const getProgressWidth = function () {
    if (window.innerWidth <= 991) {
      return '50%'
    } else {
      return '20%'
    }
  }

  if (progress === 1) {
    onComplete()
  }

  return (
    <Modal open={open}>
      <div className="uploadContainer">
        {progress < 1
          ? (
              <Box sx={{ width: getProgressWidth() }}>
                {/* <LinearProgress variant="determinate" value={progress * 100} /> */}
                <p className="uploadText">Uploading...</p>
              </Box>
            )
          : (
              <Box>
                <p>{progress}</p>
                <p className="uploadText">Done!</p>
              </Box>
            )
        }
      </div>
    </Modal>
  )
}

export default UploadScreen
