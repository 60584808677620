import * as React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import parse from 'autosuggest-highlight/parse';
import throttle from 'lodash/throttle';

function loadScript(src, position, id) {
  if (!position) {
    return;
  }

  const script = document.createElement('script');
  script.setAttribute('async', '');
  script.setAttribute('id', id);
  script.src = src;
  position.appendChild(script);
}

const autocompleteService = { current: null };
//
export default function GoogleMaps({onPlacesClick}) {
  const [label, setLabel] = React.useState("Search locations")
  const [value, setValue] = React.useState(null);
  const [inputValue, setInputValue] = React.useState('');
  const [options, setOptions] = React.useState([]);
  const loaded = React.useRef(false);
  const timeouts = React.useState([])
  const minSearchLength = 3

  if (typeof window !== 'undefined' && !loaded.current) {
    loaded.current = true;
  }

  const fetch = React.useMemo(
    () =>
      throttle((request, callback) => {
        autocompleteService.current.getPlacePredictions(request, callback);
      }, 200),
    [],
  );

  const getCharactersRemainingLabel = (characters, requiredCharacters) => {
    const remainingCharacters = requiredCharacters - characters
    return <>Enter <span className="remainingCharactersDigit">{remainingCharacters}</span> more characters</>
  }

  React.useEffect(() => {
    if (inputValue) {
      setLabel("Loading ...")
      if (inputValue.length >= minSearchLength) {
        clearAllTimeouts()
        const timeout = setTimeout(() => {
          let active = true;

          if (!autocompleteService.current && window.google.maps.places) {
            autocompleteService.current = new window.google.maps.places.AutocompleteService();
          }
          if (!autocompleteService.current) {
            return undefined;
          }
      
          if (inputValue === '') {
            setOptions(value ? [value] : []);
            return undefined;
          }
      
          fetch({ input: inputValue }, (results) => {
            if (active) {
              let newOptions = [];
      
              if (value) {
                newOptions = [value];
              }
      
              if (results) {
                newOptions = [...newOptions, ...results];
              }
      
              setOptions(newOptions);
              setLabel("Search locations")
            }
          });
      
          return () => {
            active = false;
          };
        }, 1000);
        timeouts.push(timeout)
      } else {
        setLabel(getCharactersRemainingLabel(inputValue.length, minSearchLength))
      }
    } else {
      setLabel("Search locations")
    }
  }, [value, inputValue, fetch]);

  const clearAllTimeouts = () => {
    for (let i=0; i < timeouts.length; i++) {
      clearTimeout(timeouts[i]);
    }
  }

  return (
    <Autocomplete
      freeSolo={true}
      noOptionsText="Nothing found"
      id="google-map-demo"
      sx={{ width: 300 }}
      getOptionLabel={(option) =>
        typeof option === 'string' ? option : option.description
      }
      filterOptions={(x) => x}
      options={options}
      autoComplete
      includeInputInList
      filterSelectedOptions
      value={value}
      onChange={(event, newValue) => {
        setOptions(newValue ? [newValue, ...options] : options);
        setValue(newValue);
        if (newValue?.place_id) {
          // eslint-disable-next-line no-undef
          const geocoder = new google.maps.Geocoder();
          geocoder
          .geocode({ placeId: newValue.place_id })
          .then(({ results }) => {
            onPlacesClick(results[0].geometry.location.toJSON())
          })
          .catch((e) => window.alert("Geocoder failed due to: " + e));
        }
      }}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      renderInput={(params) => (
        <TextField {...params} label={label} variant="standard" fullWidth />
      )}
      renderOption={(props, option) => {
        const matches = option.structured_formatting.main_text_matched_substrings;
        const parts = parse(
          option.structured_formatting.main_text,
          matches.map((match) => [match.offset, match.offset + match.length]),
        );
        
        return (
          <li {...props}>
            <Grid container alignItems="center">
              <Grid item>
                <Box
                  component={LocationOnIcon}
                  sx={{ color: 'text.secondary', mr: 2 }}
                />
              </Grid>
              <Grid item xs>
                {parts.map((part, index) => (
                  <span
                    key={index}
                    style={{
                      fontWeight: part.highlight ? 700 : 400,
                    }}
                  >
                    {part.text}
                  </span>
                ))}

                <Typography variant="body2" color="text.secondary">
                  {option.structured_formatting.secondary_text}
                </Typography>
              </Grid>
            </Grid>
          </li>
        );

      }}
    />
  );
}
