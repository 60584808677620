import React, { useState } from 'react';
import { Switch, Route, Redirect } from "react-router-dom";

import HippomatchScreen from '../screens/HippomatchScreen'
import MapScreen from '../screens/MapScreen'
import AccountScreen from '../screens/AccountScreen'
import ImagesScreen from '../screens/ImagesScreen';
import ScrollToTop from '../hooks/ScrollToTop'
import NotFoundScreen from '../screens/NotFoundScreen';
import HostDetailsScreen from '../screens/HostDetailsScreen';
import ConversationScreen from '../screens/ConversationScreen';
import ConversationsScreen from '../screens/ConversationsScreen';
import ShowReviewsScreen from '../screens/ShowReviewsScreen';
import WriteReviewScreen from '../screens/WriteReviewScreen';
import routes from './routes';
import FavouritesScreen from '../screens/FavouritesScreen';
import FriendsScreen from '../screens/FriendsScreen';
import MapComponent from '../components/other/MapComponent';
// import MapComponent from '../components/other/MapComponentForTesting';
import HippomatchComponent from '../components/other/HippomatchComponent';
import TravelUserDetailsScreen from '../screens/TravelUserDetailsScreen';
import UnsetUserDetailsSceen from '../screens/UnsetUserDetailsScreen';
import SingleImageScreen from '../screens/SingleImageScreen';
import SelectAccountTypeScreen from '../screens/SelectAccountTypeScreen'
import SetTravelMarkerScreen from '../screens/SetTravelMarkerScreen'
import SetTravelUserDetailsScreen from '../screens/SetTravelUserDetailsScreen';
import SetHostMarkerScreen from '../screens/SetHostMarkerScreen'
import SetHostMarkerDetailsScreen from '../screens/SetHostMarkerDetailsScreen'
import StartScreen from '../screens/StartScreen'
import MoreScreen from '../screens/MoreScreen'
import PrivacyPolicyScreen from '../screens/PrivacyPolicyScreen'
import TermsAndConditionsScreen from '../screens/TermsAndConditionsScreen'
import InformationForHippohostsScreen from '../screens/InformationForHippohostsScreen'
import InformationForHippohelpersScreen from '../screens/InformationForHippohelpersScreen'
import FaqScreen from '../screens/FaqScreen'
import { useAuth0 } from "@auth0/auth0-react";
import SafetyScreen from '../screens/SafetyScreen';
import HippomatchNoAuthScreen from '../screens/HippomatchNoAuthScreen';
import ErrorScreen from '../screens/ErrorScreen'
import PremiumScreen from '../screens/PremiumScreen'
import LicensesScren from '../screens/LicensesScreen';
import LoginScreen from '../screens/LoginScreen';
import LogoutScreen from '../screens/LogoutScreen';
import LogoutAuthErrorScreen from '../screens/LogoutAuthErrorScreen';
import ContactMessageScreen from '../screens/ContactMessageScreen';
import ReportScreen from '../screens/ReportScreen';
import HippohelpFeedbackScreen from '../screens/HippohelpFeedbackScreen';
import BlockUserScreen from '../screens/BlockUserScreen';
import HippochatScreen from '../screens/HippochatScreen';
import AiConversationScreen from '../screens/AiConversationScreen'
import ReportAIMessageScreen from '../screens/ReportAIMessageScreen'
import NotificationsScreen from '../screens/NotificationsScreen'
import ContactMessageScreenAccountDeletion from '../screens/ContactMessageScreenAccountDeletion';
import BlogScreen from '../screens/BlogScreen';
import BlogCategoryScreen from '../screens/BlogCategoryScreen';
import BlogSubCategoryScreen from '../screens/BlogSubCategoryScreen';
import BlogSubSubCategoryScreen from '../screens/BlogSubSubCategoryScreen';
import BlogPostScreen from '../screens/BlogPostScreen';
import PaymentSuccessfulScreen from '../screens/PaymentSuccessfulScreen';
import AttributionScreen from '../screens/Attribution';
import PaymentProcessingScreen from '../screens/PaymentProcessingScreen';
import AppRemovedScreen from '../screens/AppRemovedScreen';


function AppNavigator() {
  const { isAuthenticated } = useAuth0();
  const [mapVisible, setMapVisible] = useState('hidden')
  const [hippomatchVisible, setHippomatchVisible] = useState('hidden')
  const [hippomatchComponentActive, setHippomatchComponentActive] = useState(false)
  // const [hippomatchScroll, setHippomatchScroll] = useState(0)
  // const [previousScroll, setPreviousScroll] = useState(0)

  const showMap = () => {
    setMapVisible('d-flex w-100 h-100')
  }
  
  const hideMap = () => {
    setMapVisible('d-none')
  }

  const showHippomatch = () => {
    setHippomatchComponentActive(true)
    setHippomatchVisible('d-flex w-100 h-100')
  }

  const hideHippomatch = (scrollx) => {
    setHippomatchComponentActive(false)
    setHippomatchVisible('d-none')
  }

  return (
    <div className="contentContainer">
      <ScrollToTop />
      {isAuthenticated && ( // Protected content
        <>
          <div className={mapVisible}>
            <div className="mapContainer">
              <MapComponent />
            </div>
          </div>
          <div className={hippomatchVisible}>
            <div className="col-lg-10 mx-auto mt-lg-4">
              {/* <HippomatchComponent scroll={hippomatchScroll} /> */}
              <HippomatchComponent hippomatchComponentActive={hippomatchComponentActive} />
            </div>
          </div>
        </>
      )}
      <Switch>
      <Route path={'/bye'} render={props => <AppRemovedScreen {...props} />} />
        <Route path={routes.CONTACT} render={props => <ContactMessageScreen {...props} />} />
        <Route path={routes.ACCOUNT_DELETION} render={props => <ContactMessageScreenAccountDeletion {...props} />} />
        <Route path={routes.LOGIN} render={props => <LoginScreen {...props} />} />
        <Route path={routes.LOGOUT} render={props => <LogoutScreen {...props} />} />
        <Route path={routes.LOG_OUT_AUTH_ERROR} render={props => <LogoutAuthErrorScreen {...props} />} />
        <Route path={routes.HOST_MARKER_DETAILS + "/:slug"} render={props => <HostDetailsScreen {...props} />} />
        <Route path={routes.TRAVEL_USER_DETAILS + "/:slug"} render={props => <TravelUserDetailsScreen {...props} />} />
        <Route path={routes.UNSET_USER_DETAILS + "/:slug"} render={props => <UnsetUserDetailsSceen {...props} />} />
        <Route path={routes.ERROR} render={props => <ErrorScreen {...props} />} />
        <Route path={routes.FAQ} render={props => <FaqScreen {...props} />} />
        {isAuthenticated && ( // Protected routes
          <Switch>
            <Route path={routes.MAP} render={props =><MapScreen onMount={showMap} onUnmount={hideMap} {...props} />} />
            <Route path={routes.HIPPOMATCH} render={props =><HippomatchScreen onMount={showHippomatch} onUnmount={hideHippomatch} {...props} />} />
            <Route path={routes.ACCOUNT} render={props => <AccountScreen {...props} />} />
            <Route path={routes.IMAGES} render={props => <ImagesScreen {...props} />} />
            <Route path={routes.CONVERSATION + "/:id"} render={props => <ConversationScreen {...props} />} />
            <Route path={routes.AI_CONVERSATION + "/:id"} render={props => <AiConversationScreen {...props} />} />
            <Route path={routes.CONVERSATIONS} render={props => <ConversationsScreen {...props} />} />
            <Route path={routes.SHOW_REVIEWS + "/:id"} render={props => <ShowReviewsScreen {...props} />} />
            <Route path={routes.WRITE_REVIEW + "/:id"} render={props => <WriteReviewScreen {...props} />} />
            <Route path={routes.FAVOURITES} render={props => <FavouritesScreen {...props} />} />
            <Route path={routes.FRIENDS} render={props => <FriendsScreen {...props} />} />
            <Route path={routes.SINGLE_IMAGE_SCREEN} render={props => <SingleImageScreen {...props} />} />
            <Route path={routes.CHOOSE_ACCOUNT_TYPE} render={props => <SelectAccountTypeScreen {...props} />} />
            <Route path={routes.SET_TRAVEL_MARKER} render={props => <SetTravelMarkerScreen {...props} />} />
            <Route path={routes.SET_TRAVEL_USER_DETAILS} render={props => <SetTravelUserDetailsScreen {...props} />} />
            <Route path={routes.SET_HOST_MARKER} render={props => <SetHostMarkerScreen {...props} />} />
            <Route path={routes.SET_HOST_MARKER_DETAILS} render={props => <SetHostMarkerDetailsScreen {...props} />} />
            <Route path={routes.MORE} render={props => <MoreScreen {...props} />} />
            <Route path={routes.PREMIUM} render={props => <PremiumScreen {...props} />} />
            <Route path={routes.REPORT + "/:id"} render={props => <ReportScreen {...props} />} />
            <Route path={routes.REPORT_AI_MESSAGE + "/:id"} render={props => <ReportAIMessageScreen {...props} />} />
            <Route path={routes.HIPPOHELP_FEEDBACK} render={props => <HippohelpFeedbackScreen {...props} />} />
            <Route path={routes.BLOCK + "/:id"} render={props => <BlockUserScreen {...props} />} />
            <Route path={routes.HIPPOCHAT} render={props => <HippochatScreen {...props} />} />
            <Route path={routes.NOTIFICATIONS} render={props => <NotificationsScreen {...props} />} />
            <Route path={routes.PAYMENT_SUCCESSFUL} render={props => <PaymentSuccessfulScreen {...props} />} />
            <Route path={routes.PAYMENT_PROCESSING} render={props => <PaymentProcessingScreen {...props} />} />
            <Route path={'/'}><Redirect to="/map" /></Route>
          </Switch>
        )}
        {!isAuthenticated && (
          <Switch>
            <Route path={routes.LICENSES} render={props => <LicensesScren {...props} />} />
            <Route path={routes.PRIVACY_POLICY} render={props => <PrivacyPolicyScreen {...props} />} />
            <Route path={routes.TERMS_AND_CONDITIONS} render={props => <TermsAndConditionsScreen {...props} />} />
            <Route path={routes.ATTRIBUTION} render={props => <AttributionScreen {...props} />} />
            <Route path={routes.INFORMATION_FOR_HIPPOHOSTS} render={props => <InformationForHippohostsScreen {...props} />} />
            <Route path={routes.INFORMATION_FOR_HIPPOHELPERS} render={props => <InformationForHippohelpersScreen {...props} />} />
            <Route path={routes.SAFETY} render={props => <SafetyScreen {...props} />} />
            <Route path={routes.HOSTS} render={props => <HippomatchNoAuthScreen {...props} />} />
            <Route path={routes.TRAVELLERS} render={props => <HippomatchNoAuthScreen {...props} />} />
            <Route path={routes.BLOG + routes.CATEGORY + "/:category/:subCategory/:subSubCategory"} render={props => <BlogSubSubCategoryScreen {...props} />} />
            <Route path={routes.BLOG + routes.CATEGORY + "/:category/:subCategory"} render={props => <BlogSubCategoryScreen {...props} />} />
            <Route path={routes.BLOG + routes.CATEGORY + "/:category"} render={props => <BlogCategoryScreen {...props} />} />
            <Route path={routes.BLOG + routes.CATEGORY} render={props => <StartScreen {...props} />} />
            <Route path={routes.BLOG + "/:slug"} render={props => <BlogPostScreen {...props} />} />
            <Route path={routes.BLOG} render={props => <BlogScreen {...props} />} />
            <Route path={'/map'}><Redirect to={routes.LOG_OUT_AUTH_ERROR} /></Route>
            <Route path={'/'} render={props =><StartScreen {...props} />} />
            {/* <Route path={'/'} render={props =><MaintenanceScreen {...props} />} /> */}
          </Switch>
        )}
      </Switch>
      </div>
  );
}

export default AppNavigator;