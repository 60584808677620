import ApiClient from './ApiClient.js'

const getProfileDetails = async function (...args) { 
  const apiClient = new ApiClient(...args)
  return apiClient.get('/user/profile-details')
}

const getUnsetUserDetailsBySlug = async function (...args) { 
  const apiClient = new ApiClient(...args)
  return apiClient.get(`/user/unset-user-details-by-slug/${args[0]}`)
}

const getUnsetUserDetailsBySlugPublic = async function (...args) { 
  const apiClient = new ApiClient(...args)
  return apiClient.get(`/user/unset-user-details-by-slug-public/${args[0]}`)
}

const getTravelUserDetailsBySlug = async function (...args) { 
  const apiClient = new ApiClient(...args)
  return apiClient.get(`/user/travel-user-details-by-slug/${args[0]}`)
}

const getTravelUserDetailsBySlugPublic = async function (...args) { 
  const apiClient = new ApiClient(...args)
  return apiClient.get(`/user/travel-user-details-by-slug-public/${args[0]}`)
}

const getUserFriendAndFavouriteStatus = async function (...args) {
  const apiClient = new ApiClient(...args)
  return apiClient.get(`/user/friend-and-favourite-status/${args[0]}`)
}

const getFavourites = async function (...args) {
  const apiClient = new ApiClient(...args)
  return apiClient.get('/user/favourites')
}

const getRelations = async function (...args) {
  const apiClient = new ApiClient(...args)
  return apiClient.get('/user/relations')
}

const getConversation = async function (...args) {
  const apiClient = new ApiClient(...args)
  return apiClient.get(`/user/conversation/${args[0]}`)
}

const getConversations = async function (...args) {
  const apiClient = new ApiClient(...args)
  return apiClient.get('/user/conversations')
}

const getReviews = async function (...args) {
  const apiClient = new ApiClient(...args)
  return apiClient.get(`/user/references/${args[0]}`)
}

const getReview = async function (...args) {
  const apiClient = new ApiClient(...args)
  return apiClient.get(`/user/reference/${args[0]}`)
}

const getUserListings = async function (...args) {
  if (typeof args[0].selectedWorkTypeItems[0] === 'undefined') { args[0].selectedWorkTypeItems = 0 }
  const apiClient = new ApiClient(...args)
  return apiClient.get(`/user/listings/${args[0].selectedCountryItem}&${args[0].selectedWorkTypeItems}&${args[0].offset}`)
}

const search = async function (...args) {
  const apiClient = new ApiClient(...args)
  return apiClient.get(`/user/search?name=${args[0]}`)
}

const sendMessage = async function (...args) {
  const apiClient = new ApiClient(...args)
  const data = { id: args[0],  message: args[1] }

  return apiClient.post('/user/message', data)
}

const sendFriendRequest = async function (...args) {
  const apiClient = new ApiClient(...args)
  const data = { id: args[0] }

  return apiClient.post('/user/send-friend-request', data)
}

const confirmFriendRequest = async function (...args) {
  const apiClient = new ApiClient(...args)
  const data = { id: args[0] }

  return apiClient.post('/user/confirm-friend-request', data)
}

const hideSocialImage = async function (...args) {
  const apiClient = new ApiClient(...args)
  return apiClient.post('/user/hide-social-image')
}

const showSocialImage = async function (...args) {
  const apiClient = new ApiClient(...args)
  return apiClient.post('/user/show-social-image')
}

const addUserToFavourites = async function (...args) {
  const apiClient = new ApiClient(...args)
  const data = { id: args[0] }

  return apiClient.post('/user/favourite', data)
}

const cancelFriendRequest = async function (...args) {
  const apiClient = new ApiClient(...args)
  return apiClient.delete(`/user/cancel-friend-request/${args[0]}`)
}

const removeFriend = async function (...args) {
  const apiClient = new ApiClient(...args)
  return apiClient.delete(`/user/remove-friend/${args[0]}`)
}

const rejectFriendRequest = async function (...args) {
  const apiClient = new ApiClient(...args)
  return apiClient.delete(`/user/reject-friend-request/${args[0]}`)
}

const removeUserFromFavourites = async function (...args) {
  const apiClient = new ApiClient(...args)
  return apiClient.delete(`/user/favourite/${args[0]}`)
}

const saveTravelUserDetails = async function (...args) {
  const apiClient = new ApiClient(...args)

  const data = {
    profileDescription : args[0].profileDescription,
    accountTypeID : args[0].accountTypeID,
    age : args[0].age,
    nationality : args[0].nationality,
    driversLicense : args[0].driversLicense,
    additionalInfo : args[0].additionalInfo,
    dietRequirements : args[0].dietRequirements,
    positionID : args[0].positionID,
    location : args[0].location,
    helpType : args[0].helpType,
    skill : args[0].skill,
    allergy : args[0].allergy,
    coordinates : args[0].coordinates,
    language: args[0].language,
    links: args[0].links,
    whatsapp: args[0].whatsapp,
    realName: args[0].realName
  }

  return apiClient.post(`/user/travel-user-details`, data)
}

const saveHostUserDetails = async function (...args) {
  const apiClient = new ApiClient(...args)

  const data = {
    language: args[0].languages,
    links: args[0].links,
    whatsapp: args[0].whatsapp,
    realName: args[0].realName
  }

  return apiClient.post(`/user/host-user-details`, data)
}

const saveReview = async function (...args) {
  const apiClient = new ApiClient(...args)

  const data = {
    referenceText: args[0].review,
    positiveReference: args[0].reviewType,
    toHost: args[0].toHost,
    id: args[0].userID
  }

  if (args[0].userApiMethod === 'post') {
    return apiClient.post(`/user/reference`, data)
  } else {
    return apiClient.put(`/user/reference`, data)
  }
}

const resetUserAndMarkerData = async function (...args) {
  const apiClient = new ApiClient(...args)
  return apiClient.put(`/user/reset-user-and-marker-data`)
}

const deleteUser = async function (...args) {
  const apiClient = new ApiClient(...args)
  return apiClient.delete(`/user/user`)
}

const getOrCreateRevenuecatSubscriber = async function (...args) {
  const apiClient = new ApiClient(...args)
  return apiClient.get(`/user/get-or-create-revenuecat-subscriber`)
}

const getOfferings = async function (...args) {
  const apiClient = new ApiClient(...args)
  return apiClient.get(`/user/offerings`)
}

const createStripeCustomerAndSubscription = async function (...args) {
  const apiClient = new ApiClient(...args)
  return apiClient.post(`/user/create-stripe-customer-and-subscription`)
}

const cancelStripeSubscription = async function (...args) {
  const apiClient = new ApiClient(...args)
  return apiClient.delete(`/user/cancel-stripe-subscription`)
}

const sendContactMessage = async function (...args) {
  const apiClient = new ApiClient(...args)
  const data = { message: args[0].message, subject: args[0].subject }

  return apiClient.post('/user/contact-message', data)
}

const sendContactMessagePublic = async function (...args) {
  const apiClient = new ApiClient(...args)
  const data = { message: args[0].message, subject: args[0].subject, email: args[0].email }

  return apiClient.post('/user/contact-message-public', data)
}

const getUserReport = async function (...args) {
  const apiClient = new ApiClient(...args)
  return apiClient.get(`/user/user-report/${args[0]}`)
}

const saveUserReport = async function (...args) {
  const apiClient = new ApiClient(...args)
  const data = {
    reportText: args[0].report,
    id: args[0].userID
  }

  if (args[0].userApiMethod === 'post') {
    return apiClient.post(`/user/user-report`, data)
  } else {
    return apiClient.put(`/user/user-report`, data)
  }
}

const sendHippohelpFeeback = async function (...args) {
  const apiClient = new ApiClient(...args)
  const data = { 
    hippohelpFeedback: args[0].hippohelpFeedback,
    platform: 'Web'
  }

  return apiClient.post('/user/hippohelp-feedback', data)
}

const getBlockStatus = async function (...args) {
  const apiClient = new ApiClient(...args)
  return apiClient.get(`/user/block-status/${args[0]}`)
}

const blockUser = async function (...args) {
  const apiClient = new ApiClient(...args)
  const data = { id: args[0] }
  return apiClient.post(`/user/block`, data)
}

const unblockUser = async function (...args) {
  const apiClient = new ApiClient(...args)
  return apiClient.delete(`/user/block/${args[0]}`)
}

const setNotificationLevel = async function (...args) {
  const apiClient = new ApiClient(...args)
  const data = { notificationLevel: args[0] }
  return apiClient.post(`/user/set-notification-level`, data)
}

const markConversationeAsRead = async function (...args) {
  const apiClient = new ApiClient(...args)
  const data = { id: args[0] }
  return apiClient.put(`/user/mark-conversation-as-read`, data)
}

const checkForUnreadMessages = async function (...args) {
  const apiClient = new ApiClient(...args)
  return apiClient.get(`/user/check-for-unread-messages`)
}

const updateLoginCount = async function (...args) {
  const apiClient = new ApiClient(...args)
  return apiClient.post(`/user/update-login-count`)
}

const sendPurchaseFeeback = async function (...args) {
  const apiClient = new ApiClient(...args)
  const data =  args[0]
  data.platform = "web"

  return apiClient.post('/user/hippohelp-purchase-feedback', data)
}

export default {
  getProfileDetails,
  getUnsetUserDetailsBySlug,
  getUnsetUserDetailsBySlugPublic,
  getTravelUserDetailsBySlug,
  getTravelUserDetailsBySlugPublic,
  sendFriendRequest,
  cancelFriendRequest,
  removeFriend,
  confirmFriendRequest,
  rejectFriendRequest,
  hideSocialImage,
  showSocialImage,
  getUserFriendAndFavouriteStatus,
  addUserToFavourites,
  removeUserFromFavourites,
  getFavourites,
  getRelations,
  getConversation,
  sendMessage,
  getConversations,
  saveTravelUserDetails,
  saveHostUserDetails,
  getUserListings,
  getReviews,
  getReview,
  saveReview,
  search,
  resetUserAndMarkerData,
  deleteUser,
  getOrCreateRevenuecatSubscriber,
  getOfferings,
  createStripeCustomerAndSubscription,
  cancelStripeSubscription,
  sendContactMessage,
  sendContactMessagePublic,
  getUserReport,
  saveUserReport,
  sendHippohelpFeeback,
  getBlockStatus,
  blockUser,
  unblockUser,
  setNotificationLevel,
  markConversationeAsRead,
  checkForUnreadMessages,
  updateLoginCount,
  sendPurchaseFeeback
}
