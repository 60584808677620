import * as React from 'react';
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, .05)'
      : 'rgba(0, 0, 0, .03)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

export default function CustomizedAccordions() {
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const topics = [
    {
      title: "Do you screen other users?",
      content:
      <div>
          <p>
            While we work to keep the platform free from any malicious users we don't screen each and every user. It's up to you to ensure that you're fine meeting up or living with another user.
          </p>
      </div>
    },
    {
      title: "How do you protect my privacy?",
      content:
      <div>
          <p>
            We value your privacy and do not sell your information to any third parties, but we might use some of your information for our marketing campaigns or other activities. Please see our privacy policy for more details.
          </p>
      </div>
    },
    {
      title: "Any tips on safety?",
      content:
      <div>
          <p>
            Yes! There's a lot to keep in mind when it comes to safety while travelling. Please see our safety guide for more information. You'll find it in the header-menu.
          </p>
      </div>
    },
    {
      title: "What information can be seen about me?",
      content:
      <div>
          <ul>
            <li>All the information entered in your profile or marker details will be public to everyone, logged in or not. This is also the case for photos uploaded, as well as for references.</li>
            <li>Messages between you and another user is only visible between you two.</li>
          </ul>
          <p>For more detailed information, please contact us.</p>
      </div>
    },
    {
      title: "What if things don't work out?",
      content:
      <div>
          <p>
            In the rare case something doesn't work out between you and another member it's usually due to lack of communications between you two. Take a step back and listen to what the other person is trying to get through. Also keep in mind that you and the other party might be used to different kind of norms, especially if you come from very different parts of the world.
          </p>
          <p>If you feel like the other person is putting you in danger then please let us know, we will then listen to both sides of the story and take any actions we feel are necessary.</p>
          <p>However, keep in mind that Hippohelp is only a platform enabling travellers and hosts finding each other, and we don't take any part or responsibility in any dealings or affairs between our members.</p>
      </div>
    }
  ]

  return (
    <div>
      {topics.map((element, i) => {
        return (
          <Accordion expanded={expanded === 'panel' + i} onChange={handleChange('panel' + i)} key={i}>
            <AccordionSummary aria-controls={"panel" + i + "d-content"} id={"panel" + i + "d-header"}>
              <Typography>{element.title}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              {element.content}
            </AccordionDetails>
          </Accordion>
        )
      })}
    </div>
  );
}
