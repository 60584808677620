import React, {useState, useEffect, useRef} from 'react'

import sources from '../config/sources';
import useApi from '../hooks/useApi'
import userApi from '../api/userApi';
import "react-image-gallery/styles/css/image-gallery.css";
import ImageGallery from 'react-image-gallery';
import { Util } from '../utils/util';
import FavouriteIconButton from '../components/buttons/FavouriteIconButton';
import FriendIconButton from '../components/buttons/FriendIconButton';
import colors from '../config/colors';
import Icon from '@mdi/react'
import { mdiChatOutline, mdiFlagOutline, mdiBlockHelper } from '@mdi/js'
import { useHistory } from "react-router-dom"
import routes from '../navigation/routes';
import ReviewsComponent from '../components/other/ReviewsComponent';
import { useAuth0 } from "@auth0/auth0-react";
import UnsetDetailsContent from '../components/other/UnsetDetailsContent';
import Footer from '../components/formatting/Footer';
import Alert from '@mui/material/Alert';

function UnsetUserDetailsScreen(props) {
  const history = useHistory();
  const imageComponent = useRef(null);
  const [images, setImages] = useState([sources.DUMMY_IMAGE])
  const [isFullScreen, setIsFullScreen] = useState(false)
  const [error, setError] = useState(false)
  const getUnsetUserDetailsBySlugApi = useApi(userApi.getUnsetUserDetailsBySlug)
  const getUnsetUserDetailsBySlugPublicApi = useApi(userApi.getUnsetUserDetailsBySlugPublic)
  const getProfileDetailsApi = useApi(userApi.getProfileDetails)
  const [listingData, setListingData] = useState(false)
  const [authStatus, setAuthStatus] = useState(false)
  const { isAuthenticated } = useAuth0();

  const fetchMyApi = async () => {
    let fetch1
    if (isAuthenticated) {
      fetch1 = await getUnsetUserDetailsBySlugApi.request(props.match.params.slug)
    } else {
      fetch1 = await getUnsetUserDetailsBySlugPublicApi.request(props.match.params.slug)
    }
    if (!fetch1) { return }

    if (fetch1.data.image.length > 0) { setImages(fetch1.data.image) }
    setListingData(fetch1.data)
    setAuthStatus(await Util.getAuthStatus(fetch1.data.id, isAuthenticated, getProfileDetailsApi))
  }

  const toggleFullScreen = function () {
    if (!isFullScreen) {
      setIsFullScreen(true)
      imageComponent.current.fullScreen()
    } else {
      setIsFullScreen(false)
      imageComponent.current.exitFullScreen()
    }
  }

  useEffect(() => {
    fetchMyApi()
  }, [])

  let screenClass
  if (authStatus === 'noAuth') { screenClass = "col-lg-10 mx-auto mt-lg-4 pb-4 detailsContainer" }
  else { screenClass = "col-lg-10 mx-auto mt-lg-4 detailsContainer screen" }
  
  return (
    <>
      {error && ( <p>{error}</p> )}
      {authStatus && (
        <>
          <div className={screenClass}>
            {listingData && (
              <>
                <div className="row">
                  <div className="col-lg-6">
                    <ImageGallery ref={imageComponent} onClick={() => toggleFullScreen()} showPlayButton={false} items={Util.setGalleryImages(images)} />
                  </div>
                  <div className="col-lg-6 detailsContent">
                    {authStatus === 'authOthersListing' && (
                      <>
                        <div className="col-4 d-flex flex-wrap w-100 mb-2" style={{justifyContent: 'right'}}>
                          <div style={{marginRight: '10px'}} onClick={() => history.push(routes.BLOCK + '/' + listingData.id)} className="iconWrapper iconButton">
                            <Icon path={mdiBlockHelper} size={1} color={colors.white} />
                          </div>
                          <div onClick={() => history.push(routes.REPORT + '/' + listingData.id)} className="iconWrapper iconButton">
                            <Icon path={mdiFlagOutline} size={1} color={colors.white} />
                          </div>
                        </div>
                        {new Date().getFullYear()  - Number(listingData.updated_at.slice(0,4)) > 1 && (
                          <Alert sx={{ mt: 2, mb: 2, justifyContent: 'center' }} severity="warning" className='detailsAlertBox'>
                            Last login {listingData.updated_at.slice(0, 10)}. Response rate may be low.
                          </Alert>
                        )} 
                      </>
                    )}
                    <h1 className="centeredText">{listingData.username}</h1>
                    {authStatus === 'authOthersListing' && (<p className="centeredText">(last login: {listingData.updated_at.slice(0, 10)})</p>)}
                    <div className="sectionSeperator" />
                    {(authStatus === 'authOthersListing' || authStatus === 'noAuth') && (
                      <div className="row">
                        <div className="col-4 d-flex flex-wrap align-items-center justify-content-center">
                          <FavouriteIconButton isFavourite={listingData.favouriteStatus} isHostMarker={false} userID={listingData.id} />
                        </div>
                        <div className="col-4 d-flex flex-wrap align-items-center justify-content-center">
                          <div onClick={() => history.push(routes.CONVERSATION + '/' + listingData.id)} className="iconWrapper iconButton">
                            <Icon path={mdiChatOutline} size={1} color={colors.white} />
                          </div>
                        </div>
                        <div className="col-4 d-flex flex-wrap align-items-center justify-content-center">
                          <FriendIconButton friendStatus={listingData.friendStatus} userID={listingData.id} />
                        </div>
                        <div className="sectionSeperator-mini" />
                      </div>
                    )}
                    <ReviewsComponent userID={listingData.id} authStatus={authStatus} />
                  </div>
                </div>
                {authStatus === "authOwnListing" && (
                  <>
                    {listingData.host_event_count === 1 && ( <UnsetDetailsContent ownHostMarkerIsUnderReview={true} />  )}
                    {listingData.host_event_count !== 1 && ( <UnsetDetailsContent ownHostMarkerIsUnderReview={false} />  )}
                  </>
                )}
                {authStatus !== "authOwnListing" && ( <UnsetDetailsContent /> )}
              </>
            )}
          </div>
          {authStatus === 'noAuth' && ( <Footer /> )}
        </>
      )}
    </>
  );
}

export default UnsetUserDetailsScreen;