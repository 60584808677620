import React, { useState } from 'react'
import Box from '@mui/material/Box';
import { Typography } from '@material-ui/core';
import Divider from '../components/formatting/Divider';
import Footer from '../components/formatting/Footer';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import Button from '@material-ui/core/Button';
import { useAuth0 } from "@auth0/auth0-react";
import HTMLDialog from '../components/dialogs/HTMLDialog'
import CategorySection from "../components/blog/CategorySection.js"
import CategoryPath from "../components/blog/CategoryPath.js"
import PostsSection from '../components/blog/PostSection';
import BlogPagination from '../components/blog/BlogPagination';

function BlogScreen() {
  const { loginWithRedirect } = useAuth0();
  const [getAppDialogOpen, setGetAppDialogOpen] = useState(false)
  const [categories, setCategories] = useState([])
  const [posts, setPosts] = useState([])

  const handleLogin = function (nextScreen) {
    if (window.innerWidth < 1199) {
      setGetAppDialogOpen(true)
    } else {
      if (nextScreen === "signup") {
        loginWithRedirect({ screen_hint: 'signup' })
      } else {
        loginWithRedirect()
      }
    }
  }

  const handleBlogPaginationOnFetch = function (fetch) {
    setCategories(fetch.data.categories);
    setPosts(fetch.data.allPosts);
  }

  return (
    <>   
      <HTMLDialog 
        title="Get the app!"
        content="getApp"
        passedOpen={getAppDialogOpen} 
        onClose={() => {setGetAppDialogOpen(false)}}
        data={{
          authActions: true
        }}
      />
      <HelmetProvider>
        <Helmet>
          <title>Hippohelp | Blog</title>
          <meta name="description" content="The Hipphelp blog!" />
        </Helmet>
      </HelmetProvider>
      <Box className='contentBox' minHeight={"400px"}>
        {
          categories.length > 0 && (
            <Box>
              <Box className="pathAndCategory">
                <Box>
                  <CategoryPath currentCategory={{}} />
                </Box>
                <Box>
                  <CategorySection categories={categories} showTopLevelCategories />
                </Box>
              </Box>
              <Box flex={1} p={4}>
                <Typography component="h1" variant="h4">The Hipphelp blog!</Typography>
                <Divider />
                <PostsSection posts={posts} />
              </Box>
            </Box>
          )
        }
        <BlogPagination onFetch={handleBlogPaginationOnFetch} />
      </Box>
      <Footer />
    </>
  )
}

export default BlogScreen