import ApiClient from './ApiClient.js'

const sendAIMessage = async function (...args) {
  const apiClient = new ApiClient(...args)
  const data = { 
    message: args[0].message,
    conversationID: args[0].conversationID
  }

  return apiClient.post('/chat/ai-message', data)
}

const getUserAIConversations = async function (...args) {
  const apiClient = new ApiClient(...args)

  return apiClient.get('/chat/user-ai-conversations')
}

const getOtherAIConversations = async function (...args) {
  const apiClient = new ApiClient(...args)

  return apiClient.get('/chat/other-ai-conversations')
}

const getAIConversation = async function (...args) {
  const apiClient = new ApiClient(...args)

  return apiClient.get(`/chat/ai-conversation/${args[0]}`)
}

const getAIMessageReport = async function (...args) {
  const apiClient = new ApiClient(...args)
  return apiClient.get(`/chat/ai-message-report/${args[0]}`)
}

const saveAIMessageReport = async function (...args) {
  const apiClient = new ApiClient(...args)
  const data = {
    reportText: args[0].report,
    messageID: args[0].messageID
  }

  if (args[0].chatApiMethod === 'post') {
    return apiClient.post(`/chat/ai-message-report`, data)
  } else {
    return apiClient.put(`/chat/ai-message-report`, data)
  }
}

const deleteAIConversation = async function (...args) {
  const apiClient = new ApiClient(...args)
  return apiClient.delete(`/chat/ai-conversation/${args[0]}`)
}

const deleteAIMessage = async function (...args) {
  const apiClient = new ApiClient(...args)
  return apiClient.delete(`/chat/ai-message/${args[0]}`)
}

export default {
  sendAIMessage,
  getUserAIConversations,
  getOtherAIConversations,
  getAIConversation,
  getAIMessageReport,
  saveAIMessageReport,
  deleteAIConversation,
  deleteAIMessage
}
