import * as React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import useApi from '../../hooks/useApi'
import userApi from '../../api/userApi'

export default function AsyncSearch({onSearchDone}) {
  const [loading, setLoading] = React.useState(false)
  const [label, setLabel] = React.useState("Search by username or title of host")
  const [value, setValue] = React.useState(false)
  const [timeouts, setTimeouts] = React.useState([])
  const searchApi = useApi(userApi.search)
  const minSearchLength = 3

  const fetchData = async (value) => {
    setLoading(true)
    const fetch = await searchApi.request(value)
    if (!fetch) { return }
    onSearchDone(fetch.data.allSearchMatches)
    setLoading(false)
    setLabel("Search by username or title of host")
    setValue("")
  }

  const getCharactersRemainingLabel = (characters, requiredCharacters) => {
    const remainingCharacters = requiredCharacters - characters
    return <>Enter <span className="remainingCharactersDigit">{remainingCharacters}</span> more characters</>
  }

  React.useEffect(() => {
    if (value) {
      setLabel("Loading ...")
      if (value.length >= minSearchLength) {
        clearAllTimeouts()
        const timeout = setTimeout(() => {
          fetchData(value)
        }, 1000);
        timeouts.push(timeout)
      } else {
        setLabel(getCharactersRemainingLabel(value.length, minSearchLength))
      }
    } else {
      setLabel("Search by username or title of host")
    }
  }, [value])

  const clearAllTimeouts = () => {
    for (let i=0; i < timeouts.length; i++) {
      clearTimeout(timeouts[i]);
    }
  }

  return (
    <Autocomplete
      fullWidth
      freeSolo={true}
      id="asynchronous-demo"
      // onInputChange={(event, value) => { handleInputChange(value) }}
      onInputChange={(event, value) => { setValue(value) }}
      getOptionLabel={(option) => option.slug}
      options={[]}
      renderInput={(params) => (
        <TextField
          fullWidth
          variant={"standard"}
          {...params}
          label={label}
          InputProps={{
            ...params.InputProps,
            onKeyDown: (e) => {
              if (e.key === 'Enter') {
                e.stopPropagation();
              }
            },
            endAdornment: (
              <React.Fragment>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );
}
