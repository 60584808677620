import React from 'react'
import useApi from '../hooks/useApi'
import userApi from '../api/userApi';
import InputSquare from '../components/other/InputSquare'
import routes from '../navigation/routes'
import { mdiEarth, mdiHome } from '@mdi/js'
import { useHistory } from "react-router-dom"
import colors from '../config/colors'
import { Util } from '../utils/util';

function ChooseAccountTypeScreen () {
  const history = useHistory();
  const data = history.location.state
  const getProfileDetailsApi = useApi(userApi.getProfileDetails)
  const getOrCreateRevenuecatCustomerInfoApi = useApi(userApi.getOrCreateRevenuecatSubscriber)
  const createStripeCustomerAndSubscriptionApi = useApi(userApi.createStripeCustomerAndSubscription)

  const handleHostSelection = async function () {
    if (data.user_account_type_id === 1 || data.user_account_type_id === 2) {
      const profileFetch = await getProfileDetailsApi.request()
      if (!profileFetch) { return }
      const access = await Util.getAccessType(profileFetch.data, getOrCreateRevenuecatCustomerInfoApi, createStripeCustomerAndSubscriptionApi)

      if (access.type === 'paidAccess') {
        alert('Please cancel your premium subscription before creating a hostmarker.')
        history.push({
          pathname: routes.PREMIUM,
          state: data
        })
      } else {
        const selection = confirm('Please note: You already have travel related details saved. When you submit your hostmarker your old details will be lost.')
        if (selection === true) {
          history.push({
            pathname: routes.SET_HOST_MARKER,
            state: data
          })
        }
      }
    } else {
      history.push({
        pathname: routes.SET_HOST_MARKER,
        state: data
      })
    }
  }

  const handleTravelSelection = function () {
    if (data.host_event_count === 1) {
      const selection = confirm('Please note: You already have a hostmarker saved. When you submit your new details your hostmarker details will be lost.')
      if (selection === true) { 
        history.push({
          pathname: routes.SET_TRAVEL_MARKER,
          state: data
        })
      }
    } else {
      history.push({
        pathname: routes.SET_TRAVEL_MARKER,
        state: data
      })
    }
  }
    
  return (
    <div className="col-10 col-lg-6 mx-auto mt-4 screen">
      <div className="text-center">
        <p>Do you plan on travelling,</p>
        <p>or will you host other travellers?</p>
      </div>
      <div className="inputSquaresContainer">
        <div className="inputSquare" onClick={() => handleTravelSelection()} >
          <InputSquare 
            color={colors.primary} 
            icon={mdiEarth}
            title='I am a traveller' 
          />
        </div>
        <div className="inputSquare" onClick={() => handleHostSelection()} >
          <InputSquare 
            color={colors.primary} 
            icon={mdiHome}
            title='I am a host' 
          />
        </div>
      </div>
    </div>
  )
}

export default ChooseAccountTypeScreen
