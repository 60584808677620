import React, {useState, useEffect, useRef} from 'react'
import useApi from '../hooks/useApi'
import userApi from '../api/userApi';
import Button from '@material-ui/core/Button';
import { Util } from '../utils/util';
import TextInputField from '../forms/TextInputField'
import Form from '../forms/Form'
import SubmitIcon from '../forms/SubmitIcon'
import ConversationItem from '../components/lists/ConversationItem';
import ErrorMessage from '../forms/ErrorMessage'
import { useHistory } from "react-router-dom"
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import Paywall from '../components/premium/Paywall';
import markerApi from "../api/markerApi";
import InputSpinner from '../components/other/InputSpinner'
import sources from '../config/sources';
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY)

function ConversationScreen(props) {
  const history = useHistory();
  const [data, setData] = useState(false)
  const [blockStatus, setBlockStatus] = useState(false)
  const [imageStatus, setImageStatus] = useState(false)
  const [title, setTitle] = useState(false)
  const [error, setError] = useState(false)
  const getConversationApi = useApi(userApi.getConversation)
  const markConversationeAsReadFetchApi = useApi(userApi.markConversationeAsRead)
  const sendMessageApi = useApi(userApi.sendMessage)
  const scrollViewRef = useRef()
  const [nextPage, setNextPage] = useState(false)
  const [access, setAccess] = useState(false)
  const getOrCreateRevenuecatCustomerInfoApi = useApi(userApi.getOrCreateRevenuecatSubscriber)
  const createStripeCustomerAndSubscriptionApi = useApi(userApi.createStripeCustomerAndSubscription)
  const getHostMarkerDetailsAnyApprovalApi = useApi(markerApi.getHostMarkerDetailsAnyApproval)
  const getProfileDetailsApi = useApi(userApi.getProfileDetails)
  const getBlockStatusApi = useApi(userApi.getBlockStatus)
  const [saveMessageLoading, setSaveMessageLoading] = React.useState(false);

  const fetchMyAPI = async () => {
    const fetch = await getConversationApi.request(props.match.params.id)
    if (!fetch) { return }

    const blockStatusFetch = await getBlockStatusApi.request(props.match.params.id)
    if (!blockStatusFetch) { return }
    // Status can be "Not blocked" or "Blocked"
    setBlockStatus(blockStatusFetch.data.blockedByUser)

    const nextPage = await Util.getDetailsRoute(fetch.data.userData, getHostMarkerDetailsAnyApprovalApi)
    if (!nextPage) { return }

    const profileFetch = await getProfileDetailsApi.request()
    if (!profileFetch) { return }
    const imageSource = Util.getFirstImageSource(profileFetch.data.image)
    if (Util.getImageName(imageSource) === Util.getImageName(sources.DUMMY_IMAGE.uri)) {
      setImageStatus('no-image')
    } else {
      setImageStatus('has-image')
    }
    
    const fetchedAccess = await Util.getAccessType(profileFetch.data, getOrCreateRevenuecatCustomerInfoApi, createStripeCustomerAndSubscriptionApi)
    setAccess(fetchedAccess)

    if (fetchedAccess.hasAccess === true) {
      markConversationeAsReadFetchApi.request(props.match.params.id)
    }

    setNextPage(nextPage)
    const dataToBeSet = fetch.data
    dataToBeSet.authData = profileFetch.data
    setData(dataToBeSet)
    if (fetch.data.userData.user_account_type_id === 3) {
      setTitle(`${Util.generateTitle(fetch.data.userData)} (${fetch.data.userData.marker.name})`)
    } else {
      setTitle(Util.generateTitle(fetch.data.userData))
    }
    
  }

  const handleSubmit = async ({message}, {resetForm} ) => {
    if (message) {
      setSaveMessageLoading(true)
      resetForm({ values: '' })
      const result = await sendMessageApi.request(data.userData.id, message)
      if (!result) { return }
      if (result?.publicType === "MessageLimitError") {
        alert('You can only send 10 messages per day.')
        return setSaveMessageLoading(false)
      }
      
      await fetchMyAPI()
      setSaveMessageLoading(false)
      setError(false)
    
    } else {
      setError('Please enter a message')
    }
  }

  useEffect(() => {
    fetchMyAPI()
  }, [])

  useEffect(() => {
    if (scrollViewRef.current) {
      scrollViewRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [access, saveMessageLoading, data])



  return (
    <>
      {access.hasAccess === false && (
        <>
          {access.type === 'hostmarkerAwaitingApproval' && (
            <div style={{textAlign: 'center'}}>
              <p>Once your hostmarker is approved you will have premium access for free, come back here then!</p>
              <a href='/'>Home</a>
            </div>
          )}
          {access.type !== 'hostmarkerAwaitingApproval' && (
            <Elements options={{ clientSecret: access.clientSecret, appearance: { theme: 'stripe' } }} stripe={stripePromise}>
              <Paywall title="Premium Feature Detected!" />
            </Elements>
          )}
        </>
      )}
      {access.hasAccess === true && (
        <div className="col-10 col-lg-6 mx-auto mt-4 screen">
          {!data && error && ( <ErrorMessage error={error} /> )}
          {data && (
            <>
              <div className="row mb-4">
                <div className="contentHeader">
                <h1>{title}</h1>
                  <Button className={"genericButton"} onClick={() => history.push(nextPage)}>Profile</Button>
                </div>
              </div>
              <div className="row">
                {
                  data.conversation.map(function (conversationItem, index) {
                    return (
                    <ConversationItem
                      key={index}
                      title={conversationItem.sender ? conversationItem.sender.username : data.authData.username}
                      message={conversationItem.message}
                      imageSrc={ Util.getFirstImageSource(conversationItem.sender ? conversationItem.sender.image : data.authData.image) }
                    />)
                  })
                }
              </div>
              {error && ( <ErrorMessage error={error} /> )}
              {blockStatus === "Not blocked" && imageStatus === "has-image" && (
                <div style={ {height: "78px"} }>
                  <InputSpinner visible={saveMessageLoading} withMessage={false} size={2} />
                  {!saveMessageLoading && (
                    <Form onSubmit={handleSubmit} initialValues={{ message: '' }}>
                      <div ref={scrollViewRef} className="textBox messageInput">
                        <TextInputField name='message' label='Message' multiline inputProps={{ maxLength: 5000 }} />
                        <SubmitIcon icon={'send'} size={1} />
                      </div>
                    </Form>
                  )}
                </div>
              )}
              {blockStatus === "Blocked" && (
                <p>You have been blocked by this user so you cannot send messages to him / her.</p>
              )}
              {imageStatus === "no-image" && (
                <p>You need to have at least one photo before messaging other users.</p>
              )}
            </>
          )}
        </div>
      )}
    </>
  );
}

export default ConversationScreen;