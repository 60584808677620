import ApiClient from './ApiClient.js'

const getAllMarkers = async function (...args) { 
  const apiClient = new ApiClient(...args)
  return apiClient.get('/marker/all-markers')
}

const getHostMarkerDetailsBySlug = async function (...args) { 
  const apiClient = new ApiClient(...args)
  return apiClient.get(`/marker/host-marker-details-by-slug/${args[0]}`)
}

const getHostMarkerDetailsBySlugPublic = async function (...args) { 
  const apiClient = new ApiClient(...args)
  return apiClient.get(`/marker/host-marker-details-by-slug-public/${args[0]}`)
}

const getHostMarkerDetailsAnyApproval = async function (...args) { 
  const apiClient = new ApiClient(...args)
  return apiClient.get(`/marker/host-marker-details-any-approval/${args[0]}`)
}

const getHostMarkerDetailsById = async function (...args) { 
  const apiClient = new ApiClient(...args)
  return apiClient.get(`/marker/host-marker-details-by-id/${args[0]}`)
}

const getMarkerFriendAndFavouriteStatus = async function (...args) { 
  const apiClient = new ApiClient(...args)
  return apiClient.get(`/marker/friend-and-favourite-status/${args[0]}&${args[1]}`)
}

const getHostMarkerListings = async function (...args) { 
  const apiClient = new ApiClient(...args)
  if (typeof args[0].selectedWorkTypeItems[0] === 'undefined') { args[0].selectedWorkTypeItems = 0 }

  return apiClient.get(`/marker/listings/${args[0].selectedCountryItem}&${args[0].selectedWorkTypeItems}&${args[0].offset}`)
}

const getHostMarkerListingsLength = async function (...args) { 
  const apiClient = new ApiClient(...args)
  if (typeof args[0].selectedWorkTypeItems[0] === 'undefined') { args[0].selectedWorkTypeItems = 0 }

  return apiClient.get(`/marker/listings-length/${args[0].selectedCountryItem}&${args[0].selectedWorkTypeItems}`)
}

const addMarkerToFavourites = async function (...args) {
  const apiClient = new ApiClient(...args)
  const data = { id: args[0] }

  return apiClient.post('/marker/favourite', data)
}

const removeMarkerFromFavourites = async function (...args) {
  const apiClient = new ApiClient(...args)
  return apiClient.delete(`/marker/favourite/${args[0]}`)
}

const deleteTravelMarker = async function (...args) {
  const apiClient = new ApiClient(...args)
  return apiClient.delete('/marker/travel-marker/')
}

const deleteHostMarker = async function (...args) {
  const apiClient = new ApiClient(...args)
  return apiClient.delete('/marker/host-marker/')
}

const saveTravelMarkerDetails = async function (...args) {
  const apiClient = new ApiClient(...args)

  const data = {
    latitude: args[0].lat,
    longitude: args[0].lng
  }

  return apiClient.post(`/marker/travel-marker-details`, data)
}

const saveHostMarkerDetails = async function (...args) {
  const apiClient = new ApiClient(...args)

  const data = {
    title: args[0].title,
    latitude: args[0].coordinates.lat,
    longitude: args[0].coordinates.lng,
    stayDurationPreferences: args[0].durationOfStay,
    guestMaxNumberPreferences: args[0].maximumNumberOfHelpers,
    lastMinutePreferences: args[0].lastMinutePreferences,
    petsHome: args[0].pets,
    smokingHome: args[0].smokingHome,
    sleepingHome: args[0].accommodation,
    workingHours: args[0].workingHours,
    workRequirements: args[0].requirements,
    additionalInfo: args[0].additionalInfo,
    eventDescription: args[0].description,
    workType: args[0].workType,
  }

  return apiClient.post(`/marker/host-marker-details`, data)
}

export default {
  getAllMarkers,
  getHostMarkerDetailsBySlug,
  getHostMarkerDetailsBySlugPublic,
  getHostMarkerDetailsById,
  getMarkerFriendAndFavouriteStatus,
  addMarkerToFavourites,
  removeMarkerFromFavourites,
  saveTravelMarkerDetails,
  deleteTravelMarker,
  saveHostMarkerDetails,
  deleteHostMarker,
  getHostMarkerListings,
  getHostMarkerListingsLength,
  getHostMarkerDetailsAnyApproval
}
