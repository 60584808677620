import React, { useEffect, useState } from 'react'
import useApi from '../../hooks/useApi';
import markerApi from '../../api/markerApi';
import userApi from '../../api/userApi';
import adminApi from '../../api/adminApi';
import AdminButtons from './AdminButtons'

function AdminDashboardBox ({onMarkerClick, toggleAdminSearch}) {
  const [hostMarkers, setHostMarkers] = useState([])
  const [travelMarkers, setTravelMarkers] = useState([])
  const getHostMarkerDetailsBySlugPublicApi = useApi(markerApi.getHostMarkerDetailsBySlugPublic)
  const getTravelUserDetailsBySlugPublicApi = useApi(userApi.getTravelUserDetailsBySlugPublic)
  const getHostMarkersUnderReviewApi = useApi(adminApi.getHostMarkersUnderReview)
  const getTravelMarkersUnderReviewApi = useApi(adminApi.getTravelMarkersUnderReview)


  const handleHostMarkerItemClick = async function (slug) {
    const fetch = await getHostMarkerDetailsBySlugPublicApi.request(slug)
    if (!fetch) { return }

    onMarkerClick({latLng: {lat: parseFloat(fetch.data.latitude), lng: parseFloat(fetch.data.longitude)}}, fetch.data)
  }

  const handleTravelMarkerItemClick = async function (slug) {
    const fetch = await getTravelUserDetailsBySlugPublicApi.request(slug)
    if (!fetch) { return }

    onMarkerClick({latLng: {lat: parseFloat(fetch.data.marker.latitude), lng: parseFloat(fetch.data.marker.longitude)}}, fetch.data)
  }

  useEffect(() => {
    async function fetchMyApi () {
      const hostMarkerFetch = await getHostMarkersUnderReviewApi.request()
      if (!hostMarkerFetch) { return }
  
      const travelMarkerFetch = await getTravelMarkersUnderReviewApi.request()
      if (!travelMarkerFetch) { return }

      setHostMarkers(hostMarkerFetch.data)
      setTravelMarkers(travelMarkerFetch.data)
    }

    fetchMyApi()
  }, [])

  return (
    <div className='adminDashboardSection'>
      <AdminButtons toggleAdminSearch={toggleAdminSearch} onMarkerClick={onMarkerClick}/>
      <h3 className="mediumColor">Review markers</h3>
      {/* <div onClick={() => handleItemClick()}>Clcik med</div> */}
      <h4 className="mediumColor">Hostmarkers:</h4>
      <div className='adminReviewItemContainer'>
        {hostMarkers.map((element, index) => {
          return (
            <div className='adminReviewItem' key={index} onClick={() => handleHostMarkerItemClick(element.slug)}>
              <p>{element.name}</p>
            </div>
          )
        })}
      </div>
      <h4 className="mediumColor">Travelmarkers:</h4>
      <div className='adminReviewItemContainer'>
        {travelMarkers.map((element, index) => {
          return (
            <div className='adminReviewItem' key={index} onClick={() => handleTravelMarkerItemClick(element.owner.slug)}>
              <p>{element.owner.username}</p>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default AdminDashboardBox
